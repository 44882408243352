import * as Constants from "../../constants";
import axios from "axios";

let useMule = true;
// export async function getOkta(){
//   const response = await fetch(Constants.GETOKTA, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer my-token",
//       "My-Custom-Header": "foobar",
//     },
//   });
//   return await response.json();
// }
export async function getUserDetails1() {
  try {
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/posts?_limit=1"
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getUserDetails(userid) {
  const response = await fetch(Constants.GETUSERDETAILS + userid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function getWorkflows1() {
  try {
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/posts?_limit=1"
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getPartners() {
  try {
    //const response = await fetch(Constants.PARTNERURL, headers=headers);
    //console.log(response.status)
    const response = await fetch(Constants.PARTNERURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    // return [];
    return error;
  }
}

export async function fetchStatusView(userid, tabname,partner_name) {
  var body = {
    action: Constants.TABLES,
    data: {
      //id: tabname === "Actions" ? userid : "pbaner05",
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: tabname,
      email: userid+"@amgen.com",
      partner_name: partner_name,
    },
  };

  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    // body: JSON.stringify({ user_name: userid, role_name: localStorage.getItem("role") })

    body: JSON.stringify(body),
  });
  return await response.json();
}
export async function fetchTemplateStatusView(userid) {
  // const response = await fetch(Constants.TEMPLATESTATUSVIEW, {
  //     method: 'GET',
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer my-token',
  //         'My-Custom-Header': 'foobar'
  //     },
  // })

  const response = await fetch(Constants.TABLES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    // body: JSON.stringify({ user_name: userid, role_name: localStorage.getItem("role") })

    body: JSON.stringify({
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: "Template Status",
      email: userid + "@amgen.com",
      role_id: "",
      partner_name: "AMGEN",
      partner_id: "string",
    }),
  });
  return await response.json();
}
export async function fetchTemplateStatusView1(userid) {
  // console.log(partnername)
  const response = await fetch(Constants.TEMPLATESTATUSVIEW, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      userid: userid,
      role: localStorage.getItem("role"),
    }),
  });
  return await response.json();
}

export async function fetchPartnerStatusView(userid) {
  const response = await fetch(Constants.TABLES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: "Partner Status",
      email: userid + "@amgen.com",
      role_id: "",
      partner_name: "AMGEN",
      partner_id: "string",
    }),
  });
  return await response.json();
}

export async function fetchPartnerAction(userid) {
  const response = await fetch(Constants.TABLES, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      id: userid,
      role_name: localStorage.getItem("role"),
      tab: "Partner Actions",
      email: userid + "@amgen.com",
      role_id: "",
      partner_name: "AMGEN",
      partner_id: "string",
    }),
  });
  return await response.json();
}

export async function partnerStatusUpdate(payload) {
  //console.log(JSON.stringify( {request_list:payload}))
  const response = await fetch(Constants.PARTNERSTATUSUPDATE, {
    //credentials: 'include',
    method: "POST",
    // dataType: 'JSON',
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ request_list: payload }),
  });
  return await response.json();
}

export async function templateStatusUpdate(payload) {
  // console.log(
  //   JSON.stringify({
  //     request_list: payload,
  //     role: localStorage.getItem("role"),
  //   })
  // );
  const response = await fetch(Constants.TEMPLATESTATUSUPDATE, {
    //credentials: 'include',
    method: "POST",
    // dataType: 'JSON',
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      request_list: payload,
      role: localStorage.getItem("role"),
    }),
  });
  return await response.json();
}

export async function statusUpdate(payload, approvalStatus) {
  var body = {
    action:
      approvalStatus === "APPROVED" || approvalStatus === "REJECTED"
        ? Constants.STATUSUPDATE
        : Constants.ARCHIVEUPDATE,
    data: payload,
  };
  const response = await fetch(Constants.MULEURL, {
    //credentials: 'include',
    method: "POST",
    // dataType: 'JSON',
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  });
  return await response.json();
}
export async function fetchPartnerLinkTemplate(reqid) {
  //console.log(reqid)
  //reqid = 'REQ1003'
  const response = await fetch(Constants.PARTNERLINKTEMPLATE + reqid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function fetchDQMDetails(reqid) {
  //console.log(reqid)
  //reqid = 'REQ1003'
  const response = await fetch(Constants.FETCHDQMDETAILS + reqid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function templatesForPartners(partnerid, partnername) {
  // console.log(partnername)
  const response = await fetch(Constants.TEMPLTESFORPARTNERS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({ partner_id: partnerid, partner_name: partnername }),
  });
  return await response.json();
}

export async function getUserRoles() {
  // console.log(partnername)
  const response = await fetch(`https://reqres.in/api/posts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    //body: JSON.stringify({username: username})
  });
  return await response.json();
}

export async function getTemplatestatus(templatename) {
  // try{
  //     const response = await fetch(Constants.GETTEMPLATESTATUS+templatename);
  //     return await response.json();

  // }catch(error) {
  //     console.error(error);
  //    // return [];
  //    return error;
  // }
  const response = await fetch(Constants.GETTEMPLATESTATUS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
    },
    body: JSON.stringify({ name: templatename }),
  });
  return await response.json();
}

export async function checkTemplateApproval(templatename) {
  // try{
  //     const response = await fetch(Constants.GETTEMPLATEAPPROVAL+templatename);
  //     return await response.json();

  // }catch(error) {
  //     console.error(error);
  //    // return [];
  //    return error;
  // }

  const response = await fetch(Constants.GETTEMPLATEAPPROVAL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
    },
    body: JSON.stringify({ name: templatename }),
  });
  return await response.json();
}

export async function submitTemplate(formdata) {
  //console.log(JSON.stringify( formdata))
  // console.log(JSON.stringify({ x: 5, y: 6 }));
  // let payloadlatest  =  JSON.stringify( payload)

  const response = await fetch(Constants.SUBMITTEMPLATEAPI, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*",
      //  'Content-Type': 'application/json',
      //  'charset': 'utf-8',
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    //body: JSON.stringify( payload)
    body: formdata,
  });
  //console.log(response)
  return await response.json();
}

export async function submitPartner(payload) {
  //console.log(payload)
  const response = await fetch(Constants.SUBMITPARTNERAPI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(payload),
    // body: JSON.stringify({
    //     title: title,
    //     body: body,
    //     userId: Math.random().toString(36).slice(2),
    //  }),
  });
  return await response.json();
}

export async function getTemplate(templatename) {
  //console.log(templatename)
  //  const response = await fetch(Constants.GETTEMPLATEDETAILS+templatename, {
  //      method: 'GET',
  //      headers: {
  //          'Content-Type': 'application/json',
  //          }

  //    })
  //  return await response.json();
  const response = await fetch(Constants.GETTEMPLATEDETAILS, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
    },
    body: JSON.stringify({ name: templatename }),
  });
  return await response.json();
}
export async function getTemplateAll() {
  //console.log(templatename)
  const response = await fetch(Constants.GETTEMPLATEALL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function fetchTemplateMatchingColumnnameApi(templatename) {
  //console.log(templatename)
  const response = await fetch(Constants.GETTEMPLATETABLE + templatename, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function downloadtemplare(templatename) {
  //console.log(templatename)
  const response = await fetch(Constants.DOWNLOADTEMPLATE + templatename, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response;
}

export async function fetchTemplateAction1(userid) {
  const response = await fetch(Constants.FETCHTEMPLATEACTION + userid, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  return await response.json();
}

export async function fetchTemplateAction(userid) {
  // console.log(partnername)
  const response = await fetch(Constants.FETCHTEMPLATEACTION, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify({
      user_name: userid,
      role_name: localStorage.getItem("role"),
    }),
  });
  return await response.json();
}
// export async function fetchUserDetails(userid) {
//   // console.log('action hello',userid)
//   const response = await fetch(Constants.GETUSERDETAILSAPI, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer my-token",
//       "My-Custom-Header": "foobar",
//     },
//     body: JSON.stringify({ id: userid }),
//   });
//   return await response.json();
// }
export async function fetchUserDetails(userid) {
  var data = {
    action: Constants.GETUSERDETAILSAPI,
    data: {
      id: userid,
    },
  };
  if (useMule) {
    const response = await axios({
      method: "POST",
      data: data,
      url: Constants.MULEURL,
    });
    return response;
  } else {
    const response = await fetch(
      Constants.dummyURL + "/" + Constants.GETUSERDETAILSAPI,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
          "My-Custom-Header": "foobar",
        },
        body: JSON.stringify({ id: userid }),
      }
    );
    return await response.json();
  }
  // console.log('action hello',userid)
  // const response = await fetch(Constants.MULEURL, {
  //   method: "POST",

  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer my-token",
  //   "My-Custom-Header": "foobar",
  // },
  // body: JSON.stringify({ id: userid }),

  // });
  // return await response.json();
}
export async function fetchUserRoles(userRole) {
  // console.log('action hello',userRole)
  var body = {
    action: Constants.GETUSERROLESAPI,
    data: {
      id: userRole.user_id,
      role: userRole.role_id,
      partner: userRole.partner_id,
    },
  };

  if (useMule) {
    const response = await fetch(Constants.MULEURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer my-token",
        "My-Custom-Header": "foobar",
      },
      body: JSON.stringify(body),
    });
    return await response.json();
  } else {
    const response = await fetch(
      Constants.dummyURL + "/" + Constants.GETUSERROLESAPI,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
          "My-Custom-Header": "foobar",
        },
        body: JSON.stringify({
          id: userRole.user_id,
          role: userRole.role_id,
          partner: userRole.partner_id,
        }),
      }
    );
    return await response.json();
  }
}
export async function fetchUploadTemplate(userRole) {
  // console.log('action hello',userRole)
  let bodyObj = {
    action: Constants.GETUPLOADTEMPLATE,
    data: {
      id: userRole.user_id,
      role: userRole.role_id,
      partner: userRole.partner_id,
    },
  };
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}
export async function fetchUploadWorkflow(uploadTemplate) {
  // console.log('action hello',userRole)
  let bodyObj = {
    action: Constants.GETUPLOADWORKFLOW,
    data: {
      name: uploadTemplate,
    },
  };
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}
export async function uploadExcelFile(formData) {
  const response = await fetch(Constants.UPLOADEXCELFILE, {
    method: "POST",
    body: formData,
  });
  return await response.json();
}
export async function fetchPartnerRoleList() {
  let bodyObj = {
    action: Constants.GETPARTNERROLELIST,
    data: {},
  };
  const response = await fetch(Constants.MULEURL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    method: "POST",
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}
export async function inviteNewUser(invitedUserObj) {
  //console.log("invitedUserObjStringify", JSON.stringify(invitedUserObj));
  //console.log("invitedUserObj", invitedUserObj);
  let bodyObj = {
    action: Constants.INVITENEWUSER,
    data: invitedUserObj,
  };
  const response = await fetch(Constants.MULEURL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    method: "POST",
    body: JSON.stringify(bodyObj),
  });
  return await response.json();
}

// export async function downloadTemplate(downloadTemplateName) {

//     const response = await fetch(Constants.DOWNLOADTEMPLATERESOURCES+'?file='+downloadTemplateName, {
//     //   headers: {
//     //     "Content-Type": "application/json",
//     //     Authorization: "Bearer my-token",
//     //     "My-Custom-Header": "foobar",
//     //   },
//       responseType: 'blob',
//     // responseType: 'arraybuffer',
//       method: "GET",
//     //   body: JSON.stringify(downloadTemplateObj),
//     });
//     return await response.json;
//   }

export const downloadTemplate = async (downloadTemplateName) => {
  // let oktaToken = sessionStorage.getItem("dib_token");

  let payloadObj = {
    action: Constants.DOWNLOADRESOURCE,
    data: {
      fileName: downloadTemplateName,
      fileType: "xlsx",
    },
  };
  const response = await axios({
    method: "POST",
    responseType: "blob",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    url: Constants.DOWNLOADPDFRESOURCES,
    data: JSON.stringify(payloadObj),
  });
  return response;
};

export const downloadResourcesTemplate = async (pdfName) => {
  // let oktaToken = sessionStorage.getItem("dib_token");
  //console.log('pdfName',pdfName);
  let payloadObj = {
    action: Constants.DOWNLOADRESOURCE,
    data: {
      fileName: pdfName,
      fileType: "pdf",
    },
  };
  const response = await axios({
    method: "POST",
    responseType: "blob",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    url: Constants.DOWNLOADPDFRESOURCES,
    data: JSON.stringify(payloadObj),
  });
  return response;
};

export const getResourceVideoLink = async (videoName) => {
  // let oktaToken = sessionStorage.getItem("dib_token"); baseMuleURL
  let payloadObj = {
    action: Constants.DOWNLOADRESOURCE,
    data: {
      fileName: videoName,
      fileType: "mp4",
    },
  };
  const response = await axios({
    method: "POST",
    // responseType: 'blob',
    headers: {
      //  accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },

    url: Constants.DOWNLOADPDFRESOURCES,
    data: JSON.stringify(payloadObj),
  });
  //console.log('response',response);
  return response;
};

//   export async function downloadFile118(downloadfilepayload) {

// var body ={"action":"downloadFiles","data":downloadfilepayload}
//     const response = await fetch(Constants.DOWNLOADFILEACTION, {
//         method: 'POST',
//         responseType: 'blob',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer my-token',
//             'My-Custom-Header': 'foobar'
//         },
//         body: JSON.stringify(body),

//     })
//     return  response;

// }

export const downloadFile11 = async (downloadfilepayload) => {
  const response = await axios({
    method: "POST",
    responseType: "blob", // to indicate that the server will send a Blob
    data: downloadfilepayload,
    url: Constants.DOWNLOADFILEACTION,
  });
  return response;
};
export const downloadFile = async (downloadfilepayload) => {
  var data = {
    action: Constants.DOWNLOADFILE,
    //"data": JSON.stringify(downloadfilepayload)
    data: downloadfilepayload,
  };
  const response = await axios({
    method: "POST",
    responseType: "blob", // to indicate that the server will send a Blob
    data: data,
    url: Constants.DOWNLOADFILEACTION,
  });
  //console.log(response)
  if(response.status===200){
    return response;
  }
  else
  {
    return 400;
  }
  
};

export async function fetchFileDetails(actionid, type) {
  // console.log('action hello',userRole)

  var body = {
    action: Constants.FETCHFILEDETAILS,
    data: {
      actionId: actionid,
      actionType: type,
    },
  };
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(body),
  });
  return await response.json();
}

export async function fetchBasicFilter(tempid, tempname) {
  // console.log('action hello',userRole)
  const user = JSON.parse(localStorage.getItem("userDetails"));
  
  var body = {
    action: Constants.FETCHBASICFILTER,
    data: {
      callFor: "initial",
      partnerName: user.partner_name,
      tabType: "filterPage",
      templateID: tempid.toString(),
      templateName: tempname,
    },
  };
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(body),
  });
  return await response.json();
}

export async function submitBasicFilter(payload) {
  // console.log('action hello',userRole)

  var body = {
    action: Constants.FETCHBASICFILTER,
    data: payload,
  };
  //console.log("payload", body);
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(body),
  });
  //console.log(response);
  return await response.json();
}
export async function submitAdvanceFilter(payload) {
  // console.log('action hello',userRole)

  var body = {
    action: Constants.FETCHADVANCEFILTER,
    data: payload,
  };
  //console.log("payload", body);
  const response = await fetch(Constants.MULEURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
    body: JSON.stringify(body),
  });
  //console.log(response);
  return await response.json();
}

export async function setSOPFLag(userid) {
  var data = {
    action: Constants.SETSOPFLAG,
    data: {
      id: userid,
    },
  };
  if (useMule) {
    const response = await axios({
      method: "POST",
      data: data,
      url: Constants.MULEURL,
    });
    return response;
  } else {
    const response = await fetch(
      Constants.dummyURL + "/" + Constants.SETSOPFLAG,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer my-token",
          "My-Custom-Header": "foobar",
        },
        body: JSON.stringify({ id: userid }),
      }
    );
    return await response.json();
  }
}

export async function fetchEsignURL() {
  // console.log('action hello',userRole)

  // console.log("payload", body);
  const response = await fetch(Constants.FETCHESIGNURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer my-token",
      "My-Custom-Header": "foobar",
    },
  });
  // console.log(response)
  return await response.json();
}


export function postChatBot(paramsData) {
  //console.log("chatbotparam", paramsData);
  //   let hardCodedResp={
  //     "choices": [
  //         {
  //             "index": 0,
  //             "message": {
  //                 "role": "assistant",
  //                 "content": "Hello! I'm happy to help you with any questions you may have. Please go ahead and ask your question, and I'll do my best to provide a helpful response."
  //             },

  //         }
  //     ]
  // }
  return new Promise(async (resolve, reject) => {
    try {
      let response = await postData(Constants.MULEURL, paramsData.message);
      // let response=hardCodedResp;
      if (response.hasOwnProperty("error_code")) {
        resolve({
          status: false,
          message: response.error_message,
          data: response.error_message,
        });
      } else {
        if (response ) {
          resolve({ status: true, message: "success", data: [
            {
                "index": 0,
                "message": {
                    "role": "assistant",
                    "content": response
                },
               
            }
        ]
     });
        }
      }
    } catch (error) {
      resolve({ status: false, message: "error: api call", data: error });
    }
  });
}
function postData(url, data) {
  let bodyObj = {
    action: Constants.POSTCHATBOT,
    data: {
      question: data,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyObj),
      });
      if (!response.ok) {
        // toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
        throw new Error("Failed to post data to API");
      }
      const responseData = await response.json();
      resolve(responseData);
    } catch (error) {
      console.error("Error posting data to API:", error);
      reject(error);
    }
  });
}
