import React, {
    useState,
    useEffect,
    useRef
} from "react";
import {
    fetchBasicFilter, fetchUploadTemplate, submitBasicFilter
} from "../Services/UserServices";
import Loader from '../Loader';
import toast, { Toaster } from "react-hot-toast";
import { CgTemplate } from "react-icons/cg";
import Select, { components } from "react-select";
//import DateRangePicker from 'react-bootstrap-daterangepicker';
// import { DateRangePicker, DateRange } from 'react-date-range';
import { DatePicker } from "antd";
import dayjs from 'dayjs';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import moment from "moment/moment";
import Accounttemplatestyle from '../Template/Addtemplate.module.css';
import data from '../../data.json'
import DOMPurify from 'dompurify';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
//import 'bootstrap-daterangepicker/daterangepicker.css';
const Basicfilter = (props) => {
    const refone = useRef(null);
    const { RangePicker } = DatePicker;
    const [templateList, setTemplateList] = useState([]);
    const [basicfilter, setBasicFilterData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateRawList, setTemplateRawList] = useState([]);
    const user = JSON.parse(localStorage.getItem("userDetails"));
    const [loading, setLoading] = useState(false);
    const [showfilter, setshowfilter] = useState(false);
    const [sideDrop, setSideDrop] = useState(false);
    const options = [
        { name: 'chocolate', id: 'Chocolate' },
        { name: 'strawberry', id: 'Strawberry' },
        { name: 'vanilla', id: 'Vanilla' },
    ];
    const [partnerlist, setPartnerList] = useState([]);
    const [partnerfilterlist, setPartnerfilterList] = useState([]);
    const [selectedpartner, setSelectedPartner] = useState([]);
    const [workflowlist, setWorkflowList] = useState([]);
    const [workflowfilterlist, setWorkflowfilterList] = useState([]);
    const [selectedworkflow, setSelectedWorkflow] = useState([]);
    const [batchlist, setBatchList] = useState([]);
    const [batchfilterlist, setBatchfilterList] = useState([]);
    const [selectedbatch, setSelectedBatch] = useState([]);
    const [materiallist, setMaterialList] = useState([]);
    const [materialfilterlist, setMaterialfilterList] = useState([]);
    const [selectedmaterial, setSelectedMaterial] = useState([]);
    const [datasetlist, setDatasetlist] = useState([]);
    const [datasetfilterlist, setDatasetfilterList] = useState([]);
    const [selecteddataset, setSelectedDataset] = useState([]);
    const [statuslist, setStatuslist] = useState([]);
    const [statusfilterlist, setStatusfilterList] = useState([]);
    const [selectedstatus, setSelectedStatus] = useState([]);
    const [filelist, setFilelist] = useState([]);
    const [filefilterlist, setFilefilterList] = useState([]);
    const [selectedfile, setSelectedFile] = useState([]);
    const [show, setShowApprovalModal] = useState(false)
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [value, setValue] = useState([
        dayjs('2022-04-17'),
        dayjs('2022-04-21'),
    ]);
    const [startDate, setStartTempDate] = useState("");
    const [endDate, setEndTempDate] = useState("");
    const [maxdate, setMaxDate] = useState("");
    const [mindate, setMinDate] = useState("");
    const [selecteduploadedtime, setSelectedUploadedtime] = useState("");
    const [PartnerAllCheck, setPartnerAllCheck] = useState(localStorage.getItem('selectedcheckdpartner') ? JSON.parse(localStorage.getItem('selectedcheckdpartner')) : false);
    const [WorkflowAllCheck, setWorkflowAllCheck] = useState(localStorage.getItem('selectedcheckdworkflow') ? JSON.parse(localStorage.getItem('selectedcheckdworkflow')) : false);
    const [BatchAllCheck, setBatchAllCheck] = useState(localStorage.getItem('selectedcheckdstudy') ? JSON.parse(localStorage.getItem('selectedcheckdstudy')) : false);

    const [MaterialAllCheck, setMaterialAllCheck] = useState(localStorage.getItem('selectedcheckdmaterial') ? JSON.parse(localStorage.getItem('selectedcheckdmaterial')) : false);
    const [DatasetAllCheck, setDatasetAllCheck] = useState(localStorage.getItem('selectedcheckddataset') ? JSON.parse(localStorage.getItem('selectedcheckddataset')) : false);
    const [FilenameAllCheck, setFilenameAllCheck] = useState(localStorage.getItem('selectedcheckdfile') ? JSON.parse(localStorage.getItem('selectedcheckdfile')) : false);
    const [StatusAllCheck, setStatusAllCheck] = useState(localStorage.getItem('selectedcheckdstatus') ? JSON.parse(localStorage.getItem('selectedcheckdstatus')) : false);
    const [filter, setFilter] = useState([]);
    const [filtermodifiedarray, setfiltermodifiedarray] = useState([]);
    const [filterLoopConditions, setFilterLoopConditions] = useState([]);
    const [errormessage, setErrormessage] = useState("");


    const handleonclickoutside = (e) => {
        if (refone.current && !refone.current.contains(e.target)) {
            setSideDrop(false);
        }
    }

    function onApply(event, picker) {
        //console.log( picker.startDate, picker.endDate);
        // console.log(moment(picker.startDate).format('DD-MM-YYYY'))
        // console.log(moment(picker.endDate).format('DD-MM-YYYY'))

    }

    const handleSelectTemplate = (e) => {
        setErrormessage('')
        //resetSession();
        setshowfilter(true)

        // localStorage.setItem("showbasicfilter", true)
        localStorage.setItem('selectedtemplate', JSON.stringify({ value: e.value, label: e.label }))

        //localStorage.setItem("type", '');

        localStorage.removeItem('type');
        //localStorage.removeItem('selectedtemplate');
        localStorage.removeItem('restorebasicfilter');

        localStorage.removeItem('selectedcheckdpartner');
        localStorage.removeItem('selectedcheckdworkflow');
        localStorage.removeItem('selectedcheckdstudy');
        localStorage.removeItem('selectedcheckdmaterial');
        localStorage.removeItem('selectedcheckdfile');
        localStorage.removeItem('selectedcheckddataset');
        localStorage.removeItem('selectedcheckdstatus');
        localStorage.removeItem('partnerlistsession');
        localStorage.removeItem('workflowlistsession');
        localStorage.removeItem('studylistsession');
        localStorage.removeItem('materiallistsession');
        localStorage.removeItem('filelistsession');
        localStorage.removeItem('datasetlistsession');
        localStorage.removeItem('statuslistsession');
        localStorage.removeItem('startdate');
        localStorage.removeItem('enddate');
        /**Advance */
        localStorage.removeItem("lastselectedheader");
        localStorage.removeItem("lastselectedheaderdata");
        localStorage.removeItem("restoreadvancefilter");
        localStorage.removeItem("lastselectedchart");
        setSelectedTemplate(e);
        getAllBasicFilter(e);

    }
    // Function to remove the 'checked' attribute from all elements with a specific class name
    function removeCheckedByClassName(className) {
        // Select all elements with the specified class name
        const elements = document.querySelectorAll(`.${className}`);
        //console.log(elements)
        // Iterate over the selected elements
        elements.forEach(element => {
            // Remove the 'checked' attribute
            element.checked = false;
        });
    }

    // Example usage

    const getAllBasicFilter = (e) => {
        setLoading(true);
        //console.log(e)
        // console.log(data)
        // if ('result' in data && data['status'] === 'success') {
        //     setBasicFilterData(data['result']['filterValues']);
        //     setFilter(data['result']['filterValues'])
        //     var headers = data?.['result']['filterList'];
        //     var body = data?.['result']['filterValues'];
        //     var headerlist = headers.map((item) => item.label);
        //     dataGeneration(body, '', '')
        //     setLoading(false);


        // }

        fetchBasicFilter(e.value, e.label).then((data) => {
            //console.log(data)
            setLoading(false)
            if ('result' in data && data['status'] === 'success') {
                setBasicFilterData(data['result']['filterValues']);
                setFilter(data['result']['filterValues'])
                var headers = data?.['result']['filterList'];
                var body = data?.['result']['filterValues'];
                /**new add */
                setfiltermodifiedarray(body)
                /**new add */
                //console.log(body)
                var headerlist = headers.map((item) => item.label);
                resetSession(body);
                /*Date populate */
                var uploaded_timestamps = [...new Set(body.map((item) => item.uploaded_timestamp))];
                // Convert each timestamp to a Date object
                var allformateddates = uploaded_timestamps.map(timestamp => moment(timestamp.split(' ')[0]).format('MM-DD-YYYY'));
                const moments = allformateddates.map(date => moment(date, 'MM/DD/YYYY'));

                const maxDate = moment.max(moments);
                const minDate = moment.min(moments);
                // console.log(maxDate.format('YYYY-MM-DD')); // Output: Maximum date
                // console.log(minDate.format('YYYY-MM-DD')); // Output: Minimum date
                // localStorage.setItem("startdate", minDate.format('YYYY-MM-DD'));
                // localStorage.setItem("enddate", maxDate.format('YYYY-MM-DD'));
                setMaxDate(maxDate.format('YYYY-MM-DD'));
                setMinDate(minDate.format('YYYY-MM-DD'));

                setValue([dayjs(minDate), dayjs(maxDate)]);
                /*date population end */
                dataGeneration(body, '', '');

                removeCheckedByClassName('form-check-input');
                setLoading(false);


            }
            if ('result' in data && data['status'] === 'failed') {
                setBasicFilterData([]);
                setFilter([])
                setshowfilter(false)
                setErrormessage(data['result'])

                //toast.error(data['result'])

            }

        })
            .catch(error => {
                setLoading(false);
                //toast.error(error.toString())
                setErrormessage(error.toString())
                //console.error('There was an error!', error);
                setLoading(false)

            });
    }

    function dataGeneration(body, type, lastcheckoruncheck) {
        // console.log(type)
        // console.log(body)
       // console.log('1ssssssssssssssssssssssssssssssssssssssssssssss')

        var partnernames = [...new Set(body.map((item) => item.partner_name))].sort((a, b) => a.localeCompare(b))
        var workflow_types = [...new Set(body.map((item) => item.workflow_type))].sort((a, b) => a.localeCompare(b))
        var study_ids_raw = [...new Set(body.map((item) => item.study_id))]
        var study_ids = study_ids_raw.map(item => item === null ? 'Empty' : item).sort((a, b) => a.localeCompare(b));

        var program_ids = [...new Set(body.map((item) => item.program_id))].sort((a, b) => a.localeCompare(b))
        var datasets_raw = [...new Set(body.map((item) => item.dataset))]
        var datasets = datasets_raw.map(item => item === null ? 'Empty' : item).sort((a, b) => a.localeCompare(b));
        var file_names = [...new Set(body.map((item) => item.file_name))].sort((a, b) => a.localeCompare(b))
        var latest_statuss = [...new Set(body.map((item) => item.latest_status))].sort((a, b) => a.localeCompare(b));
        // setPartnerList(partnernames)
        // setWorkflowList(workflow_types)
        // setBatchList(study_ids)
        // setMaterialList(program_ids)
        // setDatasetlist(datasets)
        // setFilelist(file_names)
        // setStatuslist(latest_statuss)

        // var partnernames = (type === 'partner') ? [...new Set(basicfilter.map((item) => item.partner_name))] : [...new Set(body.map((item) => item.partner_name))];
        // var workflow_types = (type === 'workflow') ? [...new Set(basicfilter.map((item) => item.workflow_type))] : [...new Set(body.map((item) => item.workflow_type))];
        // var study_ids = (type === 'study') ? [...new Set(basicfilter.map((item) => item.study_id))] : [...new Set(body.map((item) => item.study_id))];
        // var program_ids = (type === 'material') ? [...new Set(basicfilter.map((item) => item.program_id))] : [...new Set(body.map((item) => item.program_id))];
        // //var datasets = [...new Set(body.map((item) => item.dataset))];
        // var datasets = (type === 'dataset') ? [...new Set(basicfilter.map((item) => item.dataset))] : [...new Set(body.map((item) => item.dataset))];
        // //var file_names = [...new Set(body.map((item) => item.file_name))];
        // var file_names = (type === 'filename') ? [...new Set(basicfilter.map((item) => item.file_name))] : [...new Set(body.map((item) => item.file_name))];
        // //var latest_statuss = [...new Set(body.map((item) => item.latest_status))];
        // var latest_statuss = (type === 'status') ? [...new Set(basicfilter.map((item) => item.latest_status))] : [...new Set(body.map((item) => item.latest_status))];

        var uploaded_timestamps = [...new Set(body.map((item) => item.uploaded_timestamp))];
        // Convert each timestamp to a Date object
        var allformateddates = uploaded_timestamps.map(timestamp => moment(timestamp.split(' ')[0]).format('MM-DD-YYYY'));
        // const moments = allformateddates.map(date => moment(date, 'MM/DD/YYYY'));
        // const maxDate = moment.max(moments);
        // const minDate = moment.min(moments);
        // setSelectionRange({

        //     startDate: minDate.format('MM/DD/YYYY'),
        //     endDate: maxDate.format('MM/DD/YYYY'),
        //     // startDate: new Date(),
        //     // endDate: addDays(new Date(), 7),
        //     key: 'selection'

        // })
        // console.log(maxDate.format('MM/DD/YYYY')); // Output: Maximum date
        // console.log(minDate.format('MM/DD/YYYY')); // Output: Minimum date

        // setMaxDate(maxDate.format('MM/DD/YYYY'));
        // setMinDate(minDate.format('MM/DD/YYYY'));
        // setStartTempDate(minDate.format('MM/DD/YYYY'));
        // setEndTempDate(maxDate.format('MM/DD/YYYY'));

        const moments = allformateddates.map(date => moment(date, 'MM/DD/YYYY'));
        //console.log(moments)
        const maxDate = moment.max(moments);
        const minDate = moment.min(moments);
        // console.log(maxDate.format('YYYY-MM-DD')); // Output: Maximum date
        // console.log(minDate.format('YYYY-MM-DD')); // Output: Minimum date
        // localStorage.setItem("startdate", minDate.format('YYYY-MM-DD'));
        // localStorage.setItem("enddate", maxDate.format('YYYY-MM-DD'));
        // setMaxDate(maxDate.format('YYYY-MM-DD'));
        // setMinDate(minDate.format('YYYY-MM-DD'));
        if (type === 'uploadedtime') {

            const maxDatesession = localStorage.getItem('enddate');
            const minDatesession = localStorage.getItem('startdate');
            // setMaxDate(maxDatesession);
            // setMinDate(minDatesession);
            // console.log(maxDatesession)
            // console.log(minDatesession)
            setValue([dayjs(minDatesession), dayjs(maxDatesession)]);

        }
        else {
            // localStorage.setItem("startdate", minDate.format('YYYY-MM-DD'));
            // localStorage.setItem("enddate", maxDate.format('YYYY-MM-DD'));
            setMaxDate(maxDate.format('YYYY-MM-DD'));
            setMinDate(minDate.format('YYYY-MM-DD'));
            setValue([dayjs(minDate), dayjs(maxDate)]);
        }


        // console.log(uploaded_timestamps)
        // console.log(allformateddates)

        //console.log('jjjjjj')
        //console.log(lastcheckoruncheck)
        if (type !== 'partner') {
            setPartnerList(partnernames)
            setPartnerfilterList(partnernames)
        }
        if (type === 'partner' && lastcheckoruncheck === false) {
            setPartnerList(partnernames)
            setPartnerfilterList(partnernames)
        }

        if (type !== 'workflow') {
            setWorkflowList(workflow_types)
            setWorkflowfilterList(workflow_types)
        }
        if (type === 'workflow' && lastcheckoruncheck === false) {
            setWorkflowList(workflow_types)
            setWorkflowfilterList(workflow_types)
        }

        if (type !== 'study') {
            setBatchList(study_ids)
            setBatchfilterList(study_ids)
        }
        if (type === 'study' && lastcheckoruncheck === false) {
            setBatchList(study_ids)
            setBatchfilterList(study_ids)
        }


        if (type !== 'material') {
            setMaterialList(program_ids)
            setMaterialfilterList(program_ids)
        }
        if (type === 'material' && lastcheckoruncheck === false) {
            setMaterialList(program_ids)
            setMaterialfilterList(program_ids)
        }
        if (type !== 'dataset') {
            setDatasetlist(datasets)
            setDatasetfilterList(datasets)

        }
        if (type === 'dataset' && lastcheckoruncheck === false) {
            setDatasetlist(datasets)
            setDatasetfilterList(datasets)
        }

        if (type !== 'filename') {
            setFilelist(file_names)
            setFilefilterList(file_names)
        }
        if (type === 'filename' && lastcheckoruncheck === false) {
            setFilelist(file_names)
            setFilefilterList(file_names)
        }

        if (type !== 'status') {
            setStatuslist(latest_statuss)
            setStatusfilterList(latest_statuss)
        }
        if (type === 'status' && lastcheckoruncheck === false) {
            setStatuslist(latest_statuss)
            setStatusfilterList(latest_statuss)
        }

        //setUploadedtime(file_names)


    }



    const selectAll = (type) => {
        let filterLoopConditions = [];
        //console.log(filterLoopConditions)
        if (type === 'partner') {
            //var selectedpartner1 = partnerlist.map((item) => item);
            setSelectedPartner(partnerlist.map((item) => item));
            const initialState = partnerlist.reduce((o, key) => ({ ...o, [key]: true }), {})
            localStorage.setItem("selectedcheckdpartner", JSON.stringify(initialState))
            setPartnerAllCheck(initialState)
            var partnernamesall = [...new Set(partnerlist.map((item) => item))]
            filterLoopConditions.push(obj => partnernamesall.includes(obj.partner_name));
            //console.log(partnernamesall)
            localStorage.setItem("partnerlistsession", JSON.stringify(partnernamesall))


        }
        if (type === 'workflow') {

            setSelectedWorkflow(workflowlist.map((item) => item));
            const initialState = workflowlist.reduce((o, key) => ({ ...o, [key]: true }), {})
            //console.log(initialState)
            setWorkflowAllCheck(initialState)
            localStorage.setItem("selectedcheckdworkflow", JSON.stringify(initialState))
            var workflowall = [...new Set(workflowlist.map((item) => item))]
            filterLoopConditions.push(obj => workflowall.includes(obj.workflow_type));
            localStorage.setItem("workflowlistsession", JSON.stringify(workflowall))
            //setWorkflowAllCheck(true)

        }
        if (type === 'study') {
            setSelectedBatch(batchlist.map((item) => item));
            const initialState = batchlist.reduce((o, key) => ({ ...o, [key]: true }), {})
            setBatchAllCheck(initialState)
            localStorage.setItem("selectedcheckdstudy", JSON.stringify(initialState))
            var studyall = [...new Set(batchlist.map((item) => item))]
            filterLoopConditions.push(obj => studyall.includes(obj.study_id));
            localStorage.setItem("studylistsession", JSON.stringify(studyall))

        }
        if (type === 'material') {

            setSelectedMaterial(materiallist.map((item) => item));
            const initialState = materiallist.reduce((o, key) => ({ ...o, [key]: true }), {})
            setMaterialAllCheck(initialState)
            localStorage.setItem("selectedcheckdmaterial", JSON.stringify(initialState))
            var materialall = [...new Set(materiallist.map((item) => item))]
            filterLoopConditions.push(obj => materialall.includes(obj.program_id));
            localStorage.setItem("materiallistsession", JSON.stringify(materialall))

        }
        if (type === 'dataset') {

            setSelectedDataset(datasetlist.map((item) => item));
            const initialState = datasetlist.reduce((o, key) => ({ ...o, [key]: true }), {})
            setDatasetAllCheck(initialState)
            localStorage.setItem("selectedcheckddataset", JSON.stringify(initialState))
            var datasetall = [...new Set(datasetlist.map((item) => item))]
            filterLoopConditions.push(obj => datasetall.includes(obj.dataset));
            localStorage.setItem("datasetlistsession", JSON.stringify(datasetall))

        }
        if (type === 'filename') {
            setSelectedFile(filelist.map((item) => item));
            const initialState = filelist.reduce((o, key) => ({ ...o, [key]: true }), {})
            setFilenameAllCheck(initialState)
            localStorage.setItem("selectedcheckdfile", JSON.stringify(initialState))
            var fileall = [...new Set(filelist.map((item) => item))]
            filterLoopConditions.push(obj => fileall.includes(obj.file_name));
            localStorage.setItem("filelistsession", JSON.stringify(fileall))

        }
        if (type === 'status') {

            setSelectedStatus(statuslist.map((item) => item));
            const initialState = statuslist.reduce((o, key) => ({ ...o, [key]: true }), {})
            setStatusAllCheck(initialState)
            localStorage.setItem("selectedcheckdstatus", JSON.stringify(initialState))
            var statusall = [...new Set(statuslist.map((item) => item))]
            filterLoopConditions.push(obj => statusall.includes(obj.latest_status));
            localStorage.setItem("statuslistsession", JSON.stringify(statusall))

        }



        //console.log(filterLoopConditions)
        var partnersession = JSON.parse(localStorage.getItem('partnerlistsession'))
        // console.log(partnersession)
        var workflowsession = JSON.parse(localStorage.getItem('workflowlistsession'))
        var studylistsession = JSON.parse(localStorage.getItem('studylistsession'))
        //console.log(studylistsession)
        var materialsession = JSON.parse(localStorage.getItem('materiallistsession'))
        var datasetlistsession = JSON.parse(localStorage.getItem('datasetlistsession'))
        var filelistsession = JSON.parse(localStorage.getItem('filelistsession'))
        var statuslistsession = JSON.parse(localStorage.getItem('statuslistsession'))
        if (partnersession !== null) {
            if (partnersession.length > 0) {
                filterLoopConditions.push(obj => partnersession.includes(obj.partner_name));
            }

        }
        if (workflowsession !== null) {
            if (workflowsession.length > 0) {
                filterLoopConditions.push(obj => workflowsession.includes(obj.workflow_type));
            }

        }
        if (studylistsession !== null) {
            if (studylistsession.length > 0) {

                filterLoopConditions.push(obj => studylistsession.includes(obj.study_id));
            }
        }
        if (materialsession !== null) {
            if (materialsession.length > 0) {
                filterLoopConditions.push(obj => materialsession.includes(obj.program_id));
            }

        }
        if (datasetlistsession !== null) {
            if (datasetlistsession.length > 0) {
                filterLoopConditions.push(obj => datasetlistsession.includes(obj.dataset));
            }

        }
        if (filelistsession !== null) {
            if (filelistsession.length > 0) {
                filterLoopConditions.push(obj => filelistsession.includes(obj.file_name));
            }

        }
        if (statuslistsession !== null) {
            if (statuslistsession.length > 0) {
                filterLoopConditions.push(obj => statuslistsession.includes(obj.latest_status));
            }

        }
        //console.log(filterLoopConditions)
        setFilterLoopConditions(filterLoopConditions)
        let result = basicfilter;
        filterLoopConditions.forEach(condition => {
            result = result.filter(condition);
        });
        var startdate = localStorage.getItem('startdate')
        var enddate = localStorage.getItem('enddate')
        if (startdate !== null && enddate !== null) {
            const startdate1 = new Date(startdate);
            const enddate1 = new Date(enddate);
            // console.log(startdate1, startdate)
            // console.log(enddate1, enddate)
            result = result.filter(item => {
                const uploadedDate = new Date(item.uploaded_timestamp);
                return uploadedDate >= startdate1 && uploadedDate <= enddate1;
            });
        }
        //console.log(result)

        setfiltermodifiedarray(result)
        //console.log(result)
        // localStorage.setItem("type", type);
        dataGeneration(result, type, true)

    }

    const approveModal = () => {
        // if(localStorage.getItem('type') !== null)
        // {
        //var sessiontype = localStorage.getItem('type')
        var partnersession = JSON.parse(localStorage.getItem('selectedcheckdpartner'))
        // console.log(partnersession)
        var workflowsession = JSON.parse(localStorage.getItem('selectedcheckdworkflow'))
        var studylistsession = JSON.parse(localStorage.getItem('selectedcheckdstudy'))
        var materialsession = JSON.parse(localStorage.getItem('selectedcheckdmaterial'))
        var datasetlistsession = JSON.parse(localStorage.getItem('selectedcheckddataset'))
        var filelistsession = JSON.parse(localStorage.getItem('selectedcheckdfile'))
        var statuslistsession = JSON.parse(localStorage.getItem('selectedcheckdstatus'))

        var partnersessionlist = JSON.parse(localStorage.getItem('partnerlistsession'))
        var workflowsessionlist = JSON.parse(localStorage.getItem('workflowlistsession'))
        var studylistsessionlist = JSON.parse(localStorage.getItem('studylistsession'))
        var materialsessionlist = JSON.parse(localStorage.getItem('materiallistsession'))
        var datasetlistsessionlist = JSON.parse(localStorage.getItem('datasetlistsession'))
        var filelistsessionlist = JSON.parse(localStorage.getItem('filelistsession'))
        var statuslistsessionlist = JSON.parse(localStorage.getItem('statuslistsession'))
        if (partnersession !== null) {
            if (partnersessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()
            }
            else {
                setShowApprovalModal(true)
            }
        }
        else if (workflowsession !== null) {
            if (workflowsessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()
            }
            else {
                setShowApprovalModal(true)
            }
        }
        else if (studylistsession !== null) {
            if (studylistsessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()

            }
            else {
                setShowApprovalModal(true)
            }
        }
        else if (materialsession !== null) {
            if (materialsessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()
            }
            else {
                setShowApprovalModal(true)
            }
        }
        else if (datasetlistsession !== null) {
            if (datasetlistsessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()
            }
            else {
                setShowApprovalModal(true)
            }
        }
        else if (filelistsession !== null) {
            if (filelistsessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()
            }
            else {
                setShowApprovalModal(true)
            }
        }
        else if (statuslistsession !== null) {
            if (statuslistsessionlist.length > 0) {
                setShowApprovalModal(false)
                approve()
            }
            else {
                setShowApprovalModal(true)
            }
        }
        else {
            setShowApprovalModal(true)
        }


    }
    let handleCloseApproval = () => {
        setShowApprovalModal(false)
        // setShow(false);
    };

    const approve = () => {
        //console.log(selectedTemplate)
        // localStorage.setItem('selectedtemplate', JSON.stringify(selectedTemplate))
        localStorage.removeItem("lastselectedheader");
        localStorage.removeItem("lastselectedheaderdata");
        localStorage.removeItem("restoreadvancefilter");
        localStorage.removeItem("xaxis");
        localStorage.removeItem("yaxis");
        localStorage.removeItem("shape");
        localStorage.removeItem("color");
        if (localStorage.getItem('basicfiltertype') !== null) {
            localStorage.removeItem("basicfiltertype");
            // var basicfiltertypeparse = JSON.parse(localStorage.getItem('basicfiltertype'))
            // console.log(basicfiltertypeparse)

        }



        setShowApprovalModal(false)
        //localStorage.setItem("showbasicfilter", true)
        if (filtermodifiedarray.length > 0) {
            localStorage.setItem("restorebasicfilter", JSON.stringify(filtermodifiedarray));

        }

        var bodysession = JSON.parse(localStorage.getItem('restorebasicfilter'))
       // console.log(bodysession)
            ;
        var finalfile_id = {}
        //var file_array = []
        var fileObject = {};
        if (selectedstatus.length > 0) {
           // console.log('1111111111111111111111')
            // selectedstatus.map((item) => {
            //     //file_array.push(item)
            //     var obj = { [item]: getFileIds(item,bodysession)};
            //     file_array.push(obj); 
            //     // Create an object with the item as key and an array containing the item as value
            //     //file_array.push({ [item]: getFileIds(item,bodysession) });
            // })
            selectedstatus.forEach(status => {
                fileObject[status] = getFileIds(status, bodysession);
            });

        }
        else {
           // console.log('222222222222222222')
            // statuslist.map((item) => {
            //     // var obj = { [item]: getFileIds(item,basicfilter)};
            //     // file_array.push(obj);
            //    file_array.push({ [item]: getFileIds(item,basicfilter) });
            // })
            if (bodysession !== null) {
                statuslist.forEach(status => {
                    fileObject[status] = getFileIds(status, bodysession);
                });

            }
            else {

                statuslist.forEach(status => {
                    fileObject[status] = getFileIds(status, basicfilter);
                });
            }

        }
        //console.log(file_array)
        //finalfile_id = {'file_id':file_array }
        //finalfile_id = {'file_id':[file_array]}
        finalfile_id = { 'file_id': [fileObject] }
        //console.log(finalfile_id)
        localStorage.setItem("basicfiltertype", JSON.stringify(finalfile_id));
        var tem = JSON.parse(localStorage.getItem('selectedtemplate'))
        const user = JSON.parse(localStorage.getItem("userDetails"));
        //console.log(tem)
        var payload = {

            "callFor": "initial",
            "partnerName": user.partner_name,
            "tabType": "vizPage",
            "templateID": tem.value.toString(),
            "templateName": tem.label,
            "basicFiltersType": finalfile_id,
            "vizFilters": {}
        }
        //console.log(payload)
        setLoading(true)
        submitBasicFilter(payload).then((data) => {
            //console.log(data)
            setLoading(false)

            if ('result' in data && data['status'] === 'success') {
                if(data?.['result']['resultSetLength']>0)
                {
                    props.parentCallback(localStorage.getItem('showbasicfilter'), data)
                    localStorage.setItem("showbasicfilter", true)

                }
                else
                {
                    setErrormessage('Empty data received. Please select proper file')
                }
               
            }
            if ('result' in data && data['status'] === 'failed') {

                setErrormessage(data['result'])
                localStorage.setItem("showbasicfilter", true)
            }


        })
            .catch(error => {
                setLoading(false);
                //toast.error(error.toString())
                setErrormessage(error.toString())
                //console.error('There was an error!', error);
                setLoading(false)

            });

        // console.log(selectedpartner)
        // console.log(selectedworkflow, WorkflowAllCheck)
        // console.log(selectedbatch)
        // console.log(selectedmaterial)
        // console.log(selectedfile)
        // console.log(selecteddataset)
        // console.log(statuslist)
        // console.log(selectedstatus)
        // console.log(partnerlist)
        // console.log(batchlist)
        //localStorage.setItem("showbasicfilter", true)

    }

    function getFileIds(item, body) {

        //var file_ids = []
        var file_id = body.filter(obj => obj.latest_status === item).map((item) => item.file_id.toString())
        //console.log(file_id)

        //file_ids.push(file_id)
        return file_id
    }
    const resetAll = (type) => {
        //console.log('kkkk', type)
        // console.log(filterLoopConditions)
        // console.log(selectedpartner)
        // console.log(selectedworkflow)
        // console.log(selectedbatch)
        // console.log(selectedmaterial)
        // console.log(selectedfile)
        // console.log(selecteddataset)
        // console.log(selectedstatus)
        let filterConditions = [];
        // if (selectedpartner.length > 0 && type !== 'partner') {
        //     filterConditions.push(obj => selectedpartner.includes(obj.partner_name));
        //     // obj => trueKeys.includes(obj.study_id)
        // }
        // if (selectedworkflow.length > 0 && type !== 'workflow') {
        //     filterConditions.push(obj => selectedworkflow.includes(obj.workflow_type));
        // }
        // if (selectedbatch.length > 0 && type !== 'study') {
        //     filterConditions.push(obj => selectedbatch.includes(obj.study_id));
        // }
        // if (selectedmaterial.length > 0 && type !== 'material') {
        //     filterConditions.push(obj => selectedmaterial.includes(obj.program_id));
        // }
        // if (selecteddataset.length > 0 && type !== 'dataset') {
        //     filterConditions.push(obj => selecteddataset.includes(obj.dataset));
        // }
        // if (selectedfile.length > 0 && type !== 'filename') {
        //     filterConditions.push(obj => selectedfile.includes(obj.file_name));
        // }
        // if (selectedstatus.length > 0 && type !== 'status') {
        //     filterConditions.push(obj => selectedstatus.includes(obj.latest_status));
        // }


        if (type === 'partner') {
            setSelectedPartner([]);
            localStorage.removeItem('partnerlistsession');
            //setPartnerAllCheck(false)
            const initialState = partnerlist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setPartnerAllCheck(initialState)
            localStorage.setItem("selectedcheckdpartner", JSON.stringify(initialState))
        }
        if (type === 'workflow') {
            localStorage.removeItem('workflowlistsession');
            setSelectedWorkflow([]);
            //setWorkflowAllCheck(false)
            const initialState = workflowlist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setWorkflowAllCheck(initialState)
            localStorage.setItem("selectedcheckdworkflow", JSON.stringify(initialState))

        }
        if (type === 'study') {
            setSelectedBatch([]);
            localStorage.removeItem('studylistsession');
            //setBatchAllCheck(false)
            const initialState = batchlist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setBatchAllCheck(initialState)
            localStorage.setItem("selectedcheckdstudy", JSON.stringify(initialState))

        }
        if (type === 'material') {
            localStorage.removeItem('materiallistsession');
            setSelectedMaterial([]);
            //setMaterialAllCheck(false)
            const initialState = materiallist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setMaterialAllCheck(initialState)
            localStorage.setItem("selectedcheckdmaterial", JSON.stringify(initialState))

        }
        if (type === 'dataset') {
            localStorage.removeItem('datasetlistsession');
            setSelectedDataset([]);
            //setDatasetAllCheck(false)
            const initialState = datasetlist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setDatasetAllCheck(initialState)
            localStorage.setItem("selectedcheckddataset", JSON.stringify(initialState))

        }
        if (type === 'filename') {
            setSelectedFile([]);
            localStorage.removeItem('filelistsession');
            //setFilenameAllCheck(false)
            const initialState = filelist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setFilenameAllCheck(initialState)
            localStorage.setItem("selectedcheckdfile", JSON.stringify(initialState))

        }
        if (type === 'status') {
            localStorage.removeItem('statuslistsession');
            setSelectedStatus([]);
            //setStatusAllCheck(false)
            const initialState = statuslist.reduce((o, key) => ({ ...o, [key]: false }), {})
            setStatusAllCheck(initialState)
            localStorage.setItem("selectedcheckdstatus", JSON.stringify(initialState))

        }

        var partnersession = JSON.parse(localStorage.getItem('partnerlistsession'))
        // console.log(partnersession)
        var workflowsession = JSON.parse(localStorage.getItem('workflowlistsession'))
        var studylistsession = JSON.parse(localStorage.getItem('studylistsession'))
        var materialsession = JSON.parse(localStorage.getItem('materiallistsession'))
        var datasetlistsession = JSON.parse(localStorage.getItem('datasetlistsession'))
        var filelistsession = JSON.parse(localStorage.getItem('filelistsession'))
        var statuslistsession = JSON.parse(localStorage.getItem('statuslistsession'))


        if (JSON.parse(localStorage.getItem('partnerlistsession')) && type !== 'partner') {
            if (partnersession.length > 0) {
                filterConditions.push(obj => partnersession.includes(obj.partner_name));
            }

            // obj => trueKeys.includes(obj.study_id)
        }
        if (JSON.parse(localStorage.getItem('workflowlistsession')) && type !== 'workflow') {
            if (workflowsession.length > 0) {
                filterConditions.push(obj => workflowsession.includes(obj.workflow_type));
            }

        }
        if (JSON.parse(localStorage.getItem('studylistsession')) && type !== 'study') {
            if (studylistsession.length > 0) {
                filterConditions.push(obj => studylistsession.includes(obj.study_id));
            }

        }
        if (JSON.parse(localStorage.getItem('materiallistsession')) && type !== 'material') {
            if (materialsession.length > 0) {
                filterConditions.push(obj => materialsession.includes(obj.program_id));
            }

        }
        if (JSON.parse(localStorage.getItem('datasetlistsession')) && type !== 'dataset') {
            if (datasetlistsession.length > 0) {
                filterConditions.push(obj => datasetlistsession.includes(obj.dataset));
            }

        }
        if (JSON.parse(localStorage.getItem('filelistsession')) && type !== 'filename') {
            if (filelistsession.length > 0) {
                filterConditions.push(obj => filelistsession.includes(obj.file_name));
            }

        }
        if (JSON.parse(localStorage.getItem('statuslistsession')) && type !== 'status') {
            if (statuslistsession.length > 0) {
                filterConditions.push(obj => statuslistsession.includes(obj.latest_status));
            }

        }


        //console.log(filterConditions)

        setFilterLoopConditions(filterConditions)
        let result = basicfilter;
        filterConditions.forEach(condition => {
            result = result.filter(condition);
        });
        // console.log('klklkkklkklklkl')
        // console.log(result)
        var startdate = localStorage.getItem('startdate')
        var enddate = localStorage.getItem('enddate')
        if (startdate !== null && enddate !== null) {
            const startdate1 = new Date(startdate);
            const enddate1 = new Date(enddate);
            // console.log(startdate1, startdate)
            // console.log(enddate1, enddate)
            result = result.filter(item => {
                const uploadedDate = new Date(item.uploaded_timestamp);
                return uploadedDate >= startdate1 && uploadedDate <= enddate1;
            });
        }

        setfiltermodifiedarray(result)
        //console.log(result)
        dataGeneration(result, type, false)


        // setfiltermodifiedarray(result)
        // console.log(result)
        // dataGeneration(result, type,false)



    }

    const resetSession = () => {
        localStorage.setItem("showbasicfilter", false)
        // localStorage.removeItem('showbasicfilter');
        localStorage.removeItem('type');
        //localStorage.removeItem('selectedtemplate');
        localStorage.removeItem('restorebasicfilter');

        localStorage.removeItem('selectedcheckdpartner');
        localStorage.removeItem('selectedcheckdworkflow');
        localStorage.removeItem('selectedcheckdstudy');
        localStorage.removeItem('selectedcheckdmaterial');
        localStorage.removeItem('selectedcheckdfile');
        localStorage.removeItem('selectedcheckddataset');
        localStorage.removeItem('selectedcheckdstatus');
        localStorage.removeItem('partnerlistsession');
        localStorage.removeItem('workflowlistsession');
        localStorage.removeItem('studylistsession');
        localStorage.removeItem('materiallistsession');
        localStorage.removeItem('filelistsession');
        localStorage.removeItem('datasetlistsession');
        localStorage.removeItem('statuslistsession');

        //console.log(basicfilter)
        //console.log('1ssssssssssssssssssssssssssssssssssssssssssssss')
        var partnernames = [...new Set(basicfilter.map((item) => item.partner_name))].sort((a, b) => a.localeCompare(b))
        setPartnerList(partnernames)
        setPartnerfilterList(partnernames)
        var workflow_types = [...new Set(basicfilter.map((item) => item.workflow_type))].sort((a, b) => a.localeCompare(b))
        setWorkflowList(workflow_types)
        setWorkflowfilterList(workflow_types)
        var study_ids_raw = [...new Set(basicfilter.map((item) => item.study_id))]
        var study_ids = study_ids_raw.map(item => item === null ? 'Empty' : item).sort((a, b) => a.localeCompare(b));
        setBatchList(study_ids)
        setBatchfilterList(study_ids)

        var program_ids = [...new Set(basicfilter.map((item) => item.program_id))].sort((a, b) => a.localeCompare(b))
        setMaterialList(program_ids)
        setMaterialfilterList(program_ids)
        var datasets_raw = [...new Set(basicfilter.map((item) => item.dataset))]
        var datasets = datasets_raw.map(item => item === null ? 'Empty' : item).sort((a, b) => a.localeCompare(b));
        setDatasetlist(datasets)
        setDatasetfilterList(datasets)
        var file_names = [...new Set(basicfilter.map((item) => item.file_name))].sort((a, b) => a.localeCompare(b))
        setFilelist(file_names)
        setFilefilterList(file_names)
        var latest_statuss = [...new Set(basicfilter.map((item) => item.latest_status))].sort((a, b) => a.localeCompare(b));
        setStatuslist(latest_statuss)
        setStatusfilterList(latest_statuss)



        setSelectedPartner([]);
        // setPartnerAllCheck(false)
        const initialState = partnernames.reduce((o, key) => ({ ...o, [key]: false }), {})
        setPartnerAllCheck(initialState)

        setSelectedWorkflow([]);
        //setWorkflowAllCheck(false)
        const initialState1 = workflow_types.reduce((o, key) => ({ ...o, [key]: false }), {})
        setWorkflowAllCheck(initialState1)


        setSelectedBatch([]);
        //setBatchAllCheck(false)
        const initialState2 = study_ids.reduce((o, key) => ({ ...o, [key]: false }), {})
        //console.log(initialState2)
        setBatchAllCheck(initialState2)


        setSelectedMaterial([]);
        //setMaterialAllCheck(false)
        const initialState3 = program_ids.reduce((o, key) => ({ ...o, [key]: false }), {})
        setMaterialAllCheck(initialState3)

        setSelectedDataset([]);
        //setDatasetAllCheck(false)
        const initialState4 = datasets.reduce((o, key) => ({ ...o, [key]: false }), {})
        setDatasetAllCheck(initialState4)

        setSelectedFile([]);
        //setFilenameAllCheck(false)
        const initialState5 = file_names.reduce((o, key) => ({ ...o, [key]: false }), {})
        setFilenameAllCheck(initialState5)

        setSelectedStatus([]);
        //setStatusAllCheck(false)
        const initialState6 = latest_statuss.reduce((o, key) => ({ ...o, [key]: false }), {})
        setStatusAllCheck(initialState6)


        var uploaded_timestamps = [...new Set(basicfilter.map((item) => item.uploaded_timestamp))];
        // Convert each timestamp to a Date object
        var allformateddates = uploaded_timestamps.map(timestamp => moment(timestamp.split(' ')[0]).format('MM-DD-YYYY'));
        const moments = allformateddates.map(date => moment(date, 'MM/DD/YYYY'));
        //console.log(moments)
        const maxDate = moment.max(moments);
        const minDate = moment.min(moments);
        // console.log(maxDate.format('YYYY-MM-DD')); // Output: Maximum date
        // console.log(minDate.format('YYYY-MM-DD')); // Output: Minimum date

        setMaxDate(maxDate.format('YYYY-MM-DD'));
        setMinDate(minDate.format('YYYY-MM-DD'));
        // localStorage.setItem("startdate", minDate.format('YYYY-MM-DD'));
        // localStorage.setItem("enddate", maxDate.format('YYYY-MM-DD'));
        localStorage.removeItem("startdate");
        localStorage.removeItem("enddate");

        setValue([dayjs(minDate), dayjs(maxDate)]);




        //console.log(BatchAllCheck)


    }
    const reset = () => {
        // console.log(batchlist)

        //dataGeneration(basicfilter, '', '')
        document.getElementById("filter-text-box-partner").value = "";
        document.getElementById("filter-text-box-workflow").value = "";
        document.getElementById("filter-text-box-study").value = "";
        document.getElementById("filter-text-box-material").value = "";
        document.getElementById("filter-text-box-dataset").value = "";
        document.getElementById("filter-text-box-filename").value = "";
        document.getElementById("filter-text-box-status").value = "";
        resetSession(basicfilter)

    }
    var final_filterarr = []
    const filterData = (trueKeyspartner, trueKeysworkflow, type) => {
        // console.log(trueKeyspartner)
        // console.log(trueKeysworkflow)
        // console.log(type)


        if (type === 'partner_name') {
            var filterpartner = []
            filterpartner.push({ partner_name: trueKeyspartner })
        }
        if (type === 'workflow_type') {
            var filterworkflow = []
            filterworkflow.push({ workflow_type: trueKeysworkflow })
        }
        final_filterarr = filterpartner.concat(filterworkflow)
        //console.log(final_filterarr)
        // var basicfilterarr = []
        // if (trueKeyspartner.length === 0) {
        //     trueKeyspartner = selectedpartner


        // }
        // if (trueKeysworkflow.length === 0) {
        //     trueKeysworkflow = selectedworkflow
        // }
        // if (trueKeysworkflow.length > 0) {
        //     basicfilterarr.push({ workflow_type: trueKeysworkflow })
        // }
        // if (trueKeyspartner.length > 0) {
        //     basicfilterarr.push({ partner_name: trueKeyspartner })
        // }


        // console.log(basicfilterarr)
        // setfiltermodifiedarray(basicfilterarr)
        // console.log(trueKeyspartner)
        // console.log(trueKeysworkflow)


        //if(trueKeyspartner.length > 0 && trueKeysworkflow.length > 0){
        //console.log('both')
        // const result = basicfilter.filter(obj => trueKeyspartner.includes(obj.partner_name) || trueKeysworkflow.includes(obj.workflow_type));
        // console.log(result)
        // dataGeneration(result, type)
        //}
        // console.log(selectedbatch)
        // console.log(selectedmaterial)
        // console.log(selectedfile)
        // console.log(selecteddataset)
        // console.log(selectedstatus)
    }

    // const toggleCheck = (data, type) => {
    //     console.log(data)
    //     console.log(basicfilter)
    //     var trueKeyspartner = []
    //     var trueKeysworkflow = []
    //     if (type === 'partner') {

    //         if (type === 'partner') {

    //             setPartnerAllCheck((prevState) => {
    //                 //console.log(prevState)
    //                 const newState = { ...prevState };
    //                 newState[data] = !prevState[data];
    //                 console.log(newState)

    //                 const trueKeyspartner = Object.keys(newState).filter(key => newState[key]);

    //                 console.log(trueKeyspartner);
    //                 setSelectedPartner(trueKeyspartner)
    //                 filterData(trueKeyspartner, '', 'partner_name')
    //                 return newState;
    //             });
    //             // const result = basicfilter.find(obj => obj.partner_name === data);
    //             // const result = basicfilter.filter(obj => obj.partner_name === data);


    //         }
    //         // const result = basicfilter.find(obj => obj.partner_name === data);
    //         // const result = basicfilter.filter(obj => obj.partner_name === data);


    //     }
    //     if (type === 'workflow') {

    //         setWorkflowAllCheck((prevState) => {
    //             //console.log(prevState)
    //             const newState = { ...prevState };
    //             newState[data] = !prevState[data];

    //             const trueKeysworkflow = Object.keys(newState).filter(key => newState[key]);
    //             //console.log(trueKeys);
    //             setSelectedWorkflow(trueKeysworkflow)
    //             filterData('', trueKeysworkflow, 'workflow_type')


    //             return newState;
    //         });
    //         // trueKeys.length > 0 ? setStatusAllCheck(true) : setStatusAllCheck(false)

    //     }
    //     // if (type === 'study') {
    //     //     setBatchAllCheck((prevState) => {
    //     //         //console.log(prevState)
    //     //         const newState = { ...prevState };
    //     //         newState[data] = !prevState[data];

    //     //         const trueKeys = Object.keys(newState).filter(key => newState[key]);
    //     //         //console.log(trueKeys);
    //     //         setSelectedBatch(trueKeys)
    //     //         // const result = basicfilter.filter(obj => trueKeys.includes(obj.study_id));
    //     //         // console.log(result)
    //     //         // dataGeneration(result, 'study')
    //     //         return newState;
    //     //     });


    //     // }
    //     // if (type === 'material') {
    //     //     setMaterialAllCheck((prevState) => {
    //     //         //console.log(prevState)
    //     //         const newState = { ...prevState };
    //     //         newState[data] = !prevState[data];

    //     //         const trueKeys = Object.keys(newState).filter(key => newState[key]);

    //     //         setSelectedMaterial(trueKeys)
    //     //         // const result = basicfilter.filter(obj => trueKeys.includes(obj.program_id));
    //     //         // console.log(result)
    //     //         // dataGeneration(result, 'material')
    //     //         return newState;
    //     //     });

    //     // }
    //     // if (type === 'dataset') {
    //     //     setDatasetAllCheck((prevState) => {
    //     //         //console.log(prevState)
    //     //         const newState = { ...prevState };
    //     //         newState[data] = !prevState[data];

    //     //         const trueKeys = Object.keys(newState).filter(key => newState[key]);

    //     //         setSelectedDataset(trueKeys)
    //     //         return newState;
    //     //     });

    //     // }
    //     // if (type === 'filename') {
    //     //     setFilenameAllCheck((prevState) => {
    //     //         //console.log(prevState)
    //     //         const newState = { ...prevState };
    //     //         newState[data] = !prevState[data];

    //     //         const trueKeys = Object.keys(newState).filter(key => newState[key]);

    //     //         setSelectedFile(trueKeys)
    //     //         return newState;
    //     //     });

    //     // }
    //     // if (type === 'status') {
    //     //     setStatusAllCheck((prevState) => {
    //     //         //console.log(prevState)
    //     //         const newState = { ...prevState };
    //     //         newState[data] = !prevState[data];

    //     //         const trueKeys = Object.keys(newState).filter(key => newState[key]);

    //     //         setSelectedStatus(trueKeys)
    //     //         return newState;
    //     //     });

    //     // }
    //     // console.log(trueKeyspartner)
    //     // console.log(trueKeysworkflow)

    // }

    const onFilterTextBoxChanged = (e, type) => {
        const value = e.target.value;
        // console.log(value)
        // console.log(partnerlist)
        // console.log(selectedpartner)
        //var result = partnerlist.filter(obj => value.includes(obj.partner_name));
        if (type === 'partner') {
            ///ekta kichu temporary banate hbe seacrch er jnno
            let result = partnerfilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setPartnerList(result)
            // if (value === '') { 
            //     setPartnerList( [...new Set(basicfilter.map((item) => item.partner_name))])
            // }
            // else {
            //     let result = partnerlist.filter(item => {
            //         return item.toLowerCase().includes(value.toLowerCase());
            //     });
            //     setPartnerList(result)


            // }

        }
        if (type === 'workflow') {
            let result1 = workflowfilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setWorkflowList(result1)
        }
        if (type === 'study') {
            let result2 = batchfilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setBatchList(result2)
        }
        if (type === 'material') {
            let result3 = materialfilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setMaterialList(result3)
        }
        if (type === 'dataset') {
            let result4 = datasetfilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setDatasetlist(result4)
        }
        if (type === 'filename') {
            let result5 = filefilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setFilelist(result5)
        }
        if (type === 'status') {

            let result6 = statusfilterlist.filter(item => {
                return item.toLowerCase().includes(value.toLowerCase());
            });
            setStatuslist(result6)
        }


        //selectedpartner.length > 0 ? setPartnerList(selectedpartner.filter(item => item.toLowerCase().includes(value.toLowerCase()))) :
        // setPartnerList(prevState => prevState.filter(item => item.toLowerCase().includes(value.toLowerCase())));

    }


    const toggleCheckPartner = (data, type) => {
        //  alert('hi')
        // // console.log(partnerlist)
        //  console.log(data)
        //  console.log(type)
        localStorage.setItem("editullilist", JSON.stringify(partnerlist))
        if (data) {
            setPartnerAllCheck((prevState) => {
               // console.log(prevState)
                var newState = { ...prevState };
                newState[data] = !prevState[data];
                //console.log(newState)
                // if(JSON.parse(localStorage.getItem('selectedcheckdpartner')))
                //     {
                //         newState = JSON.parse(localStorage.getItem('selectedcheckdpartner'))
                //     }

                const trueKeyspartner = Object.keys(newState).filter(key => newState[key]);
                if (trueKeyspartner.length === 0) {
                    var lastcheckoruncheck = newState[data]
                }
                // console.log(trueKeyspartner);
                // console.log(lastcheckoruncheck)
                setSelectedPartner(trueKeyspartner)
                localStorage.setItem("selectedcheckdpartner", JSON.stringify(newState))
                localStorage.setItem("partnerlistsession", JSON.stringify(trueKeyspartner))
                //localStorage.setItem("partnerlistsession",  trueKeyspartner)
                filterData1(trueKeyspartner, type, lastcheckoruncheck)
                //filterData(trueKeyspartner, '', 'partner_name')
                return newState;
            });

        }


    }
    const toggleCheckWorkflow = (data, type) => {
        localStorage.setItem("editullilist", JSON.stringify(workflowlist))
        setWorkflowAllCheck((prevState) => {
            //console.log(prevState)
            const newState = { ...prevState };
            newState[data] = !prevState[data];

            const trueKeysworkflow = Object.keys(newState).filter(key => newState[key]);
            if (trueKeysworkflow.length === 0) {
                var lastcheckoruncheck = newState[data]
            }
            //console.log(trueKeys);
            //console.log(trueKeysworkflow);
            //var lastcheckoruncheck = newState[data]

            setSelectedWorkflow(trueKeysworkflow)
            localStorage.setItem("selectedcheckdworkflow", JSON.stringify(newState))
            localStorage.setItem("workflowlistsession", JSON.stringify(trueKeysworkflow))
            //filterData('', trueKeysworkflow, 'workflow_type')
            filterData1(trueKeysworkflow, type, lastcheckoruncheck)

            return newState;
        });
    }

    const toggleCheckStudy = (data, type) => {
        localStorage.setItem("editullilist", JSON.stringify(batchlist))
        setBatchAllCheck((prevState) => {
            //console.log(prevState)
            const newState = { ...prevState };
            newState[data] = !prevState[data];

            const trueKeysstudy = Object.keys(newState).filter(key => newState[key]);
            if (trueKeysstudy.length === 0) {
                var lastcheckoruncheck = newState[data]

            }

            // console.log(trueKeysstudy);
            setSelectedBatch(trueKeysstudy)
            localStorage.setItem("selectedcheckdstudy", JSON.stringify(newState))
            localStorage.setItem("studylistsession", JSON.stringify(trueKeysstudy))
            filterData1(trueKeysstudy, type, lastcheckoruncheck)

            return newState;
        });
    }

    const toggleCheckMaterial = (data, type) => {
        localStorage.setItem("editullilist", JSON.stringify(materiallist))
        setMaterialAllCheck((prevState) => {
            //console.log(prevState)    
            const newState = { ...prevState };
            newState[data] = !prevState[data];

            const trueKeysmaterial = Object.keys(newState).filter(key => newState[key]);
            if (trueKeysmaterial.length === 0) {
                var lastcheckoruncheck = newState[data]

            }
            // var lastcheckoruncheck = newState[data]
            //console.log(trueKeysmaterial);
            setSelectedMaterial(trueKeysmaterial)
            localStorage.setItem("selectedcheckdmaterial", JSON.stringify(newState))
            localStorage.setItem("materiallistsession", JSON.stringify(trueKeysmaterial))
            filterData1(trueKeysmaterial, type, lastcheckoruncheck)

            return newState;
        });
    }
    const toggleCheckDataset = (data, type) => {
        localStorage.setItem("editullilist", JSON.stringify(datasetlist))
        // console.log(data)
        setDatasetAllCheck((prevState) => {

            const newState = { ...prevState };
            newState[data] = !prevState[data];
            //console.log(newState)

            // var lastcheckoruncheck = newState[data]
            // console.log(lastcheckoruncheck)
            const trueKeysdataset = Object.keys(newState).filter(key => newState[key]);
            if (trueKeysdataset.length === 0) {
                var lastcheckoruncheck = newState[data]

            }
            //console.log(trueKeysdataset);
            setSelectedDataset(trueKeysdataset)
            localStorage.setItem("selectedcheckddataset", JSON.stringify(newState))
            localStorage.setItem("datasetlistsession", JSON.stringify(trueKeysdataset))
            filterData1(trueKeysdataset, type, lastcheckoruncheck)

            return newState;
        });
    }
    const toggleCheckFile = (data, type) => {
        localStorage.setItem("editullilist", JSON.stringify(filelist))
        setFilenameAllCheck((prevState) => {
            //console.log(prevState)
            const newState = { ...prevState };
            newState[data] = !prevState[data];

            const trueKeysfilename = Object.keys(newState).filter(key => newState[key]);
            //console.log(trueKeysfilename);
            setSelectedFile(trueKeysfilename)
            if (trueKeysfilename.length === 0) {
                var lastcheckoruncheck = newState[data]
            }
            localStorage.setItem("selectedcheckdfile", JSON.stringify(newState))
            localStorage.setItem("filelistsession", JSON.stringify(trueKeysfilename))
            //var lastcheckoruncheck = newState[data]
            filterData1(trueKeysfilename, type, lastcheckoruncheck)

            return newState;
        });
    }
    const toggleCheckStatus = (data, type) => {
        localStorage.setItem("editullilist", JSON.stringify(statuslist))
        setStatusAllCheck((prevState) => {
            //console.log(prevState)
            const newState = { ...prevState };
            newState[data] = !prevState[data];

            const trueKeysstatus = Object.keys(newState).filter(key => newState[key]);
            if (trueKeysstatus.length === 0) {
                var lastcheckoruncheck = newState[data]
            }
            //console.log(trueKeysstatus);
            //var lastcheckoruncheck = newState[data]
            setSelectedStatus(trueKeysstatus)
            localStorage.setItem("selectedcheckdstatus", JSON.stringify(newState))
            localStorage.setItem("statuslistsession", JSON.stringify(trueKeysstatus))
            filterData1(trueKeysstatus, type, lastcheckoruncheck)

            return newState;
        });
    }

    const filterData1 = (trueKeyspartner, type, lastcheckoruncheck) => {
        //  console.log(trueKeyspartner)
        //  console.log(type)

        if (type === 'partner') {
            setSelectedPartner((prevState) => {
                //console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState)
                newState = trueKeyspartner;
                // console.log(newState)
                generateFilter(newState, selectedworkflow, selectedbatch, selectedmaterial, selecteddataset, selectedfile, selectedstatus, type, lastcheckoruncheck)
                return newState;


            })
        }
        if (type === 'workflow') {
            setSelectedWorkflow((prevState) => {
                //console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState)
                newState = trueKeyspartner;
                //console.log(newState)
                generateFilter(selectedpartner, newState, selectedbatch, selectedmaterial, selecteddataset, selectedfile, selectedstatus, type, lastcheckoruncheck)
                return newState;
            })
        }
        if (type === 'study') {
            setSelectedBatch((prevState) => {
                // console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState)
                newState = trueKeyspartner;
                // console.log(newState)
                generateFilter(selectedpartner, selectedworkflow, newState, selectedmaterial, selecteddataset, selectedfile, selectedstatus, type, lastcheckoruncheck)
                return newState;
            })
        }
        if (type === 'material') {
            setSelectedMaterial((prevState) => {
                // console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState)
                newState = trueKeyspartner;
                // console.log(newState)
                generateFilter(selectedpartner, selectedworkflow, selectedbatch, newState, selecteddataset, selectedfile, selectedstatus, type, lastcheckoruncheck)
                return newState;
            })
        }
        if (type === 'dataset') {
            setSelectedDataset((prevState) => {
                // console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState)
                newState = trueKeyspartner;
                // console.log(newState)
                generateFilter(selectedpartner, selectedworkflow, selectedbatch, selectedmaterial, newState, selectedfile, selectedstatus, type, lastcheckoruncheck)
                return newState;
            })
        }
        if (type === 'filename') {
            setSelectedFile((prevState) => {
                // console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState) 
                newState = trueKeyspartner;
                // console.log(newState)
                generateFilter(selectedpartner, selectedworkflow, selectedbatch, selectedmaterial, selecteddataset, newState, selectedstatus, type, lastcheckoruncheck)
                return newState;
            })
        }
        if (type === 'status') {
            setSelectedStatus((prevState) => {
                //// console.log(prevState)
                var newState = { ...prevState };
                //console.log(newState) 
                newState = trueKeyspartner;
                // console.log(newState)
                generateFilter(selectedpartner, selectedworkflow, selectedbatch, selectedmaterial, selecteddataset, selectedfile, newState, type, lastcheckoruncheck)
                return newState;
            })
        }


        // console.log(selectedpartner)
        // console.log(selectedworkflow)
    }

    const generateFilter = (partner, workflow, study, material, dataset, file, status, type, lastcheckoruncheck) => {
        // console.log(partner)
        // console.log(workflow)
        // console.log(study)
        // console.log(material)
        // console.log(type)

        var partnersession = JSON.parse(localStorage.getItem('partnerlistsession'))
        // console.log(partnersession)
        var workflowsession = JSON.parse(localStorage.getItem('workflowlistsession'))
        var studylistsession = JSON.parse(localStorage.getItem('studylistsession'))
        var materialsession = JSON.parse(localStorage.getItem('materiallistsession'))
        var datasetlistsession = JSON.parse(localStorage.getItem('datasetlistsession'))
        var filelistsession = JSON.parse(localStorage.getItem('filelistsession'))
        var statuslistsession = JSON.parse(localStorage.getItem('statuslistsession'))

        // console.log(partnersession)
        // console.log(workflowsession)
        // console.log(studylistsession)
        // console.log(materialsession)
        if (partnersession !== null) {
            partner = partnersession
        }
        if (workflowsession !== null) {
            workflow = workflowsession
        }
        if (studylistsession !== null) {
            study = studylistsession
        }
        if (materialsession !== null) {
            material = materialsession
        }
        if (datasetlistsession !== null) {
            dataset = datasetlistsession
        }
        if (filelistsession !== null) {
            file = filelistsession
        }
        if (statuslistsession !== null) {
            status = statuslistsession
        }
        // console.log(partner)
        // console.log(workflow)
        // console.log(study)
        // console.log(material)

        if (partner.length === 0 && workflow.length === 0 && study.length === 0 && material.length === 0
            && dataset.length === 0 && file.length === 0 && status.length === 0 && localStorage.getItem('startdate') === null && localStorage.getItem('enddate') === null) {
            setfiltermodifiedarray(basicfilter)
            dataGeneration(basicfilter, '')
        }
        // if(partnersession  === null && workflowsession=== null && studylistsession=== null && materialsession=== null
        //      && datasetlistsession=== null && filelistsession=== null && statuslistsession=== null){
        //         console.log('klklklklklklkklklklklklklklkl')
        //     setfiltermodifiedarray(basicfilter)
        //     dataGeneration(basicfilter, '')
        // }    
        else {
            let filterConditions = [];
            if (partner.length > 0) {
                filterConditions.push(obj => partner.includes(obj.partner_name));
            }
            if (workflow.length > 0) {
                filterConditions.push(obj => workflow.includes(obj.workflow_type));
            }
            if (study.length > 0) {
                filterConditions.push(obj => study.includes(obj.study_id));
            }
            if (material.length > 0) {
                filterConditions.push(obj => material.includes(obj.program_id));
            }
            if (dataset.length > 0) {
                filterConditions.push(obj => dataset.includes(obj.dataset));
            }
            if (file.length > 0) {
                filterConditions.push(obj => file.includes(obj.file_name));
            }
            if (status.length > 0) {
                filterConditions.push(obj => status.includes(obj.latest_status));
            }
            // if (partnersession !== null) {
            //     filterConditions.push(obj => partnersession.includes(obj.partner_name));
            // }
            // if (workflowsession !== null) {
            //     filterConditions.push(obj => workflowsession.includes(obj.workflow_type));
            // }
            // if (studylistsession!== null)  {
            //     filterConditions.push(obj => studylistsession.includes(obj.study_id));
            // }
            // if (materialsession !== null) {
            //     filterConditions.push(obj => materialsession.includes(obj.program_id));
            // }
            // if (datasetlistsession !== null) {
            //     filterConditions.push(obj => dataset.includes(obj.dataset));
            // }
            // if (filelistsession !== null) {
            //     filterConditions.push(obj => file.includes(obj.file_name));
            // }
            // if (statuslistsession !== null) {
            //     filterConditions.push(obj => status.includes(obj.latest_status));
            // }


            //console.log(filterConditions)
            setFilterLoopConditions(filterConditions)
            let result = basicfilter;
            filterConditions.forEach(condition => {
                result = result.filter(condition);
            });
            // console.log('klklkkklkklklkl')
            // console.log(result)

            var startdate = localStorage.getItem('startdate')
            var enddate = localStorage.getItem('enddate')
            if (startdate !== null && enddate !== null) {
                const startdate1 = new Date(startdate);
                const enddate1 = new Date(enddate);
                //console.log(startdate1, enddate1)
                result = result.filter(item => {
                    const uploadedDate = new Date(item.uploaded_timestamp);
                    return uploadedDate >= startdate1 && uploadedDate <= enddate1;
                });
                //console.log(result)
            }
            //console.log(result)
            setfiltermodifiedarray(result)
            //console.log(result)
            localStorage.setItem("type", type);
            dataGeneration(result, type, lastcheckoruncheck)

        }






    }
    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            // console.log('From: ', dates[0], ', to: ', dates[1]);
            // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            localStorage.setItem("startdate", dateStrings[0]);
            localStorage.setItem("enddate", dateStrings[1]);
            filterDatauploadedtime(dateStrings[0], dateStrings[1], 'uploadedtime')
        } else {
            console.log('Clear');
        }
    };



    useEffect(() => {



        // console.log(localStorage.getItem('showbasicfilter'))
        // console.log(localStorage.getItem('type'))
        // console.log(localStorage.getItem('selectedtemplate'))
        // var body  = JSON.parse(localStorage.getItem('restorebasicfilter'))

        // var partnernames = [...new Set(body.map((item) => item.partner_name))]
        // console.log(partnernames)
        // console.log(localStorage.getItem('editullilist'))
        //alert(localStorage.getItem('type'))


        if (localStorage.getItem('showbasicfilter') === 'true') {
            var tem = JSON.parse(localStorage.getItem('selectedtemplate'))
            //console.log(tem)
            // console.log(selectedTemplate)
            fetchBasicFilter(tem.value, tem.label).then((data) => {
                //console.log(data)
                setLoading(false)
                if ('result' in data && data['status'] === 'success') {
                    setBasicFilterData(data['result']['filterValues']);
                    setFilter(data['result']['filterValues'])
                    var headers = data?.['result']['filterList'];
                    var body = data?.['result']['filterValues'];
                    //console.log(body)
                    var headerlist = headers.map((item) => item.label);
                    // localStorage.setItem("restorebasicfilter",  JSON.stringify(body));
                    // localStorage.setItem("type", '');
                    // localStorage.setItem('selectedtemplate',JSON.stringify({value:e.value,label:e.label}))
                    // dataGeneration(body, '', '');
                    setLoading(false);
                    // if (localStorage.getItem('type') !== '') {
                    if (localStorage.getItem('type')) {

                        //console.log('lllllediiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
                        // console.log(localStorage.getItem("type"))
                        // console.log(localStorage.getItem('showbasicfilter'))
                        // console.log(JSON.parse(localStorage.getItem('restorebasicfilter')))
                        // console.log(JSON.parse(localStorage.getItem('selectedtemplate')))
                        var body = JSON.parse(localStorage.getItem('restorebasicfilter'))

                        setshowfilter(true)
                        dataGenerationEdit(body, localStorage.getItem("type"), localStorage.getItem('editullilist'))


                    }
                    else {
                        //console.log(localStorage.getItem('type') + 'jjjjj')
                        setshowfilter(true)
                        dataGeneration(data['result']['filterValues'], '', '')


                    }


                }
                if ('result' in data && data['status'] === 'failed') {
                    setBasicFilterData([]);
                    setFilter([])
                    setshowfilter(false)
                    setErrormessage(data['result'])

                    //toast.error(data['result'])

                }

            })
                .catch(error => {
                    setLoading(false);
                    //toast.error(error.toString())
                    setErrormessage(error.toString())
                    //console.error('There was an error!', error);
                    setLoading(false)

                });



            // if (localStorage.getItem("showbasicfilter")) {
            //     console.log('inside')
            //     let storedArray = JSON.parse(localStorage.getItem('restorebasicfilter'));
            //     console.log(storedArray)
            // }
            // getAllBasicFilter(JSON.parse(localStorage.getItem('selectedtemplate')));




        }


    }, [localStorage.getItem('showbasicfilter')])


    function dataGenerationEdit(body, type, editullilist) {
        // console.log(type)
        // console.log(body)
        // console.log(editullilist)
        var editullilist = JSON.parse(localStorage.getItem('editullilist'))
        var partnernames = (type === 'partner') ? editullilist : [...new Set(body.map((item) => item.partner_name))].sort((a, b) => a.localeCompare(b))
        var workflow_types = (type === 'workflow') ? editullilist : [...new Set(body.map((item) => item.workflow_type))].sort((a, b) => a.localeCompare(b))
        var study_ids_raw = (type === 'study') ? editullilist : [...new Set(body.map((item) => item.study_id))]
        var study_ids = study_ids_raw.map(item => item === null ? 'Empty' : item).sort((a, b) => a.localeCompare(b));
        var program_ids = (type === 'material') ? editullilist : [...new Set(body.map((item) => item.program_id))].sort((a, b) => a.localeCompare(b))
        var datasets_raw = (type === 'dataset') ? editullilist : [...new Set(body.map((item) => item.dataset))]
        var datasets = datasets_raw.map(item => item === null ? 'Empty' : item).sort((a, b) => a.localeCompare(b));
        var file_names = (type === 'filename') ? editullilist : [...new Set(body.map((item) => item.file_name))].sort((a, b) => a.localeCompare(b))
        var latest_statuss = (type === 'status') ? editullilist : [...new Set(body.map((item) => item.latest_status))].sort((a, b) => a.localeCompare(b));


        setPartnerList(partnernames)
        setWorkflowList(workflow_types)
        setBatchList(study_ids)
        setMaterialList(program_ids)
        setDatasetlist(datasets)
        setFilelist(file_names)
        setStatuslist(latest_statuss)

        // var partnernames = (type === 'partner') ? [...new Set(basicfilter.map((item) => item.partner_name))] : [...new Set(body.map((item) => item.partner_name))];
        // var workflow_types = (type === 'workflow') ? [...new Set(basicfilter.map((item) => item.workflow_type))] : [...new Set(body.map((item) => item.workflow_type))];
        // var study_ids = (type === 'study') ? [...new Set(basicfilter.map((item) => item.study_id))] : [...new Set(body.map((item) => item.study_id))];
        // var program_ids = (type === 'material') ? [...new Set(basicfilter.map((item) => item.program_id))] : [...new Set(body.map((item) => item.program_id))];
        // //var datasets = [...new Set(body.map((item) => item.dataset))];
        // var datasets = (type === 'dataset') ? [...new Set(basicfilter.map((item) => item.dataset))] : [...new Set(body.map((item) => item.dataset))];
        // //var file_names = [...new Set(body.map((item) => item.file_name))];
        // var file_names = (type === 'filename') ? [...new Set(basicfilter.map((item) => item.file_name))] : [...new Set(body.map((item) => item.file_name))];
        // //var latest_statuss = [...new Set(body.map((item) => item.latest_status))];
        // var latest_statuss = (type === 'status') ? [...new Set(basicfilter.map((item) => item.latest_status))] : [...new Set(body.map((item) => item.latest_status))];

        var uploaded_timestamps = [...new Set(body.map((item) => item.uploaded_timestamp))];
        // Convert each timestamp to a Date object
        var allformateddates = uploaded_timestamps.map(timestamp => moment(timestamp.split(' ')[0]).format('MM-DD-YYYY'));
        const moments = allformateddates.map(date => moment(date, 'MM/DD/YYYY'));
        const maxDate = moment.max(moments);
        const minDate = moment.min(moments);
        // setMaxDate(maxDate.format('YYYY-MM-DD'));
        // setMinDate(minDate.format('YYYY-MM-DD'));
        setValue([dayjs(minDate), dayjs(maxDate)]);

        // if(localStorage.getItem('startdate') === null && localStorage.getItem('enddate') === null){
        //     var uploaded_timestamps = [...new Set(body.map((item) => item.uploaded_timestamp))];
        //     // Convert each timestamp to a Date object
        //     var allformateddates = uploaded_timestamps.map(timestamp => moment(timestamp.split(' ')[0]).format('MM-DD-YYYY'));
        //     const moments = allformateddates.map(date => moment(date, 'MM/DD/YYYY'));
        //     const maxDate = moment.max(moments);
        //     const minDate = moment.min(moments);
        //     setMaxDate(maxDate.format('YYYY-MM-DD'));
        //     setMinDate(minDate.format('YYYY-MM-DD'));
        //     setValue([dayjs(minDate), dayjs(maxDate)]);
        // }
        // else{

        //     const maxDatesession = localStorage.getItem('enddate');
        //     const minDatesession = localStorage.getItem('startdate');
        //     setMaxDate(maxDatesession);
        //     setMinDate(minDatesession);
        //     console.log(maxDatesession)
        //     console.log(minDatesession)
        //     setValue([dayjs(minDatesession), dayjs(maxDatesession)]);
        // }

        // console.log(uploaded_timestamps)
        // console.log(allformateddates)



    }

    function filterDatauploadedtime(startdate, enddate, type) {


        // console.log(startdate)
        // console.log(enddate)


        if (type === 'uploadedtime') {
            // console.log('inside')
            // console.log(startdate)
            // console.log(enddate)
            // console.log(basicfilter)
            const startdate1 = new Date(startdate);
            const enddate1 = new Date(enddate);
            //console.log(startdate1, enddate1)
            if (filtermodifiedarray.length > 0) {
                const filteredData = filtermodifiedarray.filter(item => {
                    const uploadedDate = new Date(item.uploaded_timestamp);
                    return uploadedDate >= startdate1 && uploadedDate <= enddate1;
                });
                // console.log(filteredData)
                dataGeneration(filteredData, type, '')
            }
            else {
                const filteredData = basicfilter.filter(item => {
                    const uploadedDate = new Date(item.uploaded_timestamp);
                    return uploadedDate >= startdate1 && uploadedDate <= enddate1;
                });
                // console.log(filteredData)
                dataGeneration(filteredData, type, '')
            }

            //dataGeneration(filtermodifiedarray, type, '')
        }

    }



    useEffect(() => {
        const fetchUploadTemplateList = () =>
            fetchUploadTemplate({
                user_id: user.user_id,
                role_id: user.roles[0].role_name,
                partner_id: user.partner_name,
            })
                .then((data) => {
                    setTemplateRawList(data);
                    let templateList = data.map((item) => ({
                        value: item.template_id,
                        label: item.template_name,
                    }));
                    templateList[2].className = "borderBottomTemplateList";

                    setTemplateList(templateList);
                })
                .catch((error) => {
                    toast.error(
                        "There was an error, Template names are not populated"
                    );
                });
        fetchUploadTemplateList();
        document.addEventListener("click", handleonclickoutside, true);
        document.addEventListener("keydown", hideOnEscape, true);
    }, []);

    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setSideDrop(false)
        }
    }
    return (

        <>
            <div className="row" >
                <div className="col-12 mt-0 ml-2">
                    <label className="customLabelMargin font500">
                        Template Name <span className={`mandetoryfield `}>*</span>
                    </label>
                    <br />
                    <div className="row">
                        <div className="input-group-text ml-4">
                            <CgTemplate color="white" />
                        </div>
                        <div className="col-6 px-0">
                            <Select
                                options={templateList}
                                className={` select-template  `}
                                isSearchable={true}
                                // menuIsOpen={true}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderRadius: "0em 0.25em   0.25em 0em",

                                    }),

                                    option: (
                                        styles,
                                        { data, isDisabled, isFocused, isSelected }
                                    ) => ({
                                        ...styles,
                                        cursor: "pointer",
                                        display: "block",
                                        fontSize: "0.9rem",
                                        width: "100%",
                                        padding: "5px 12px",
                                        boxSizing: "border-box",
                                        borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                                    }),
                                }}
                                classNamePrefix="selectDropdown"
                                aria-label="Default select example"
                                name="templateName"
                                onChange={handleSelectTemplate}
                                value={selectedTemplate || JSON.parse(localStorage.getItem('selectedtemplate'))}

                                //value={selectedTemplate || ""}
                                placeholder="Select Template Name"
                            />
                        </div>
                        {
                            showfilter &&
                            <div className="col-5">
                                <div className="form- text-right">



                                    <button type="button" onClick={approveModal} className={`btn btn-primary text-wrap fw-normal submit_btnapp`}>
                                        <i className="fa fa-check"></i> Submit
                                    </button>
                                    <button type="button" onClick={reset} className="btn   text-wrap fw-normal submit-reset" id="submit-reset" style={{ margin: '0 10px', backgroundColor: '#ba6868', color: 'white' }}>
                                        <i className="fa fa-refresh"></i>   Reset All
                                    </button>


                                </div>
                            </div>
                        }
                    </div>

                </div>

                <Toaster
                    position="top-right"
                    reverseOrder={false}
                    toastOptions={{
                        className: "",

                        error: {
                            icon: "❗",
                        },
                    }}
                />
                {loading && <Loader />}
                {/* {errormessage &&

                    <div className={`col-12 ml-2 ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</div>
                } */}

                {errormessage && (
                    <div className=" col-10 mt-2 ml-4 alert alert-danger  "  role="alert">
                        <i className="fa fa-exclamation-circle mr-2" style={{ color: 'red', fontSize: '19px' }}></i>{errormessage}
                    </div>
                )}

            </div>
            {
                showfilter &&
                <div>
                    <div className="row mt-2 mx-3">

                        <div className="col-3 " >
                            <div className="form-group1  font500">
                                <label for="exampleInputEmail1 ">PARTNER</label>
                                <div style={{ float: 'right' }}>

                                    <button onClick={() => selectAll('partner')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                    {
                                        // selectedpartner.length > 0 ?

                                        JSON.parse(localStorage.getItem('partnerlistsession')) !== null ?
                                            <>
                                                <button onClick={() => resetAll('partner')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset"><i className="fa fa-refresh"></i> </button>
                                                <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">
                                                    {/* {selectedpartner?.length} */}
                                                    {JSON.parse(localStorage.getItem('partnerlistsession')).length}
                                                </button>
                                            </> : null
                                    }

                                </div>

                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-partner"
                                                type="search"
                                                placeholder="Filter..."
                                                onInput={(event) => onFilterTextBoxChanged(event, 'partner')}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                        {partnerlist.map((partner, index) => (

                                            <div className="form-check pb-1" key={index} >
                                                <input className="form-check-input" type="checkbox"
                                                    value={partner} name={partner}
                                                    onClick={() => toggleCheckPartner(partner, 'partner')}
                                                    checked={PartnerAllCheck[partner]}
                                                />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckPartner(partner, 'partner')}
                                                >
                                                    {partner}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3 " >
                            <div className="form-group1 font500 ">
                                <label for="exampleInputEmail1 ">WORKFLOW TYPE</label>
                                <button style={{ float: 'right' }} onClick={() => selectAll('workflow')} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                {
                                    //selectedworkflow.length > 0 ?
                                    JSON.parse(localStorage.getItem('workflowlistsession')) !== null ?
                                        <>
                                            <button onClick={() => resetAll('workflow')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset "><i className="fa fa-refresh"></i> </button>
                                            <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{JSON.parse(localStorage.getItem('workflowlistsession')).length}</button>
                                        </> : null
                                }

                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-workflow"
                                                type="search"
                                                placeholder="Filter..."
                                                onInput={(event) => onFilterTextBoxChanged(event, 'workflow')}
                                            //onInput={onFilterTextBoxChanged}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                        {workflowlist.map((workflow, index) => (

                                            <div className="form-check pb-1" key={index}>
                                                <input className="form-check-input" type="checkbox" value={workflow} name={workflow}
                                                    onClick={() => toggleCheckWorkflow(workflow, 'workflow')}
                                                    checked={WorkflowAllCheck[workflow]} />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckWorkflow(workflow, 'workflow')}>
                                                    {workflow}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3 " >
                            <div className="form-group1 font500 ">
                                <label for="exampleInputEmail1 ">BATCH/STUDY ID</label>
                                <button style={{ float: 'right' }} onClick={() => selectAll('study')} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                {
                                    //selectedbatch.length > 0 ?
                                    JSON.parse(localStorage.getItem('studylistsession')) !== null ?
                                        <>
                                            <button onClick={() => resetAll('study')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset"><i className="fa fa-refresh"></i> </button>
                                            <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{JSON.parse(localStorage.getItem('studylistsession')).length}</button>
                                        </> : null
                                }

                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-study"
                                                type="search"
                                                placeholder="Filter..."
                                                onInput={(event) => onFilterTextBoxChanged(event, 'study')}

                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                        {batchlist.map((batch, index) => (

                                            <div className="form-check pb-1" key={index}>
                                                <input className="form-check-input" name={batch}
                                                    onClick={() => toggleCheckStudy(batch, 'study')}
                                                    checked={BatchAllCheck[batch]} type="checkbox" value={batch} />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckStudy(batch, 'study')}>

                                                    {(batch === '' ? 'Empty' : batch)}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3 " >
                            <div className="form-group1 font500  ">
                                <label for="exampleInputEmail1 ">PROGRAM/MATERIAL ID</label>
                                <button style={{ float: 'right' }} onClick={() => selectAll('material')} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                {
                                    //selectedmaterial.length > 0 ?
                                    JSON.parse(localStorage.getItem('materiallistsession')) !== null ?
                                        <>
                                            <button onClick={() => resetAll('material')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset"><i className="fa fa-refresh"></i> </button>
                                            <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{JSON.parse(localStorage.getItem('materiallistsession')).length}</button>
                                        </> : null
                                }

                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-material"
                                                type="search"
                                                placeholder="Filter..."
                                                onInput={(event) => onFilterTextBoxChanged(event, 'material')}

                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                        {materiallist.map((progid, index) => (

                                            <div className="form-check pb-1" key={index}>
                                                <input className="form-check-input"
                                                    type="checkbox" value={progid}
                                                    name={progid}
                                                    onClick={() => toggleCheckMaterial(progid, 'material')}
                                                    checked={MaterialAllCheck[progid]} />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckMaterial(progid, 'material')}>
                                                    {progid}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 mx-3">

                        <div className="col-3 " >
                            <div className="form-group1  font500">
                                <label for="exampleInputEmail1 ">DATASET</label>
                                <button style={{ float: 'right' }} onClick={() => selectAll('dataset')} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                {
                                    //selecteddataset.length > 0 ?
                                    JSON.parse(localStorage.getItem('datasetlistsession')) !== null ?
                                        <>
                                            <button onClick={() => resetAll('dataset')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset "><i className="fa fa-refresh"></i> </button>
                                            <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{JSON.parse(localStorage.getItem('datasetlistsession'))?.length}</button>
                                        </> : null
                                }


                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-dataset"
                                                type="search"
                                                placeholder="Filter..."

                                                onInput={(event) => onFilterTextBoxChanged(event, 'dataset')}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                        {datasetlist.map((dataset, index) => (

                                            <div className="form-check pb-1" key={index}>
                                                <input className="form-check-input"
                                                    type="checkbox" value={dataset}
                                                    name={dataset}
                                                    onClick={() => toggleCheckDataset(dataset, 'dataset')}
                                                    checked={DatasetAllCheck[dataset]} />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckDataset(dataset, 'dataset')}>
                                                    {(dataset === '' ? 'Empty' : dataset)}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3 " >
                            <div className="form-group1 font500 ">
                                <label for="exampleInputEmail1 ">FILE NAME</label>
                                <button style={{ float: 'right' }} onClick={() => selectAll('filename')} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                {
                                    //selectedfile.length > 0 ?
                                    JSON.parse(localStorage.getItem('filelistsession')) !== null ?
                                        <>
                                            <button onClick={() => resetAll('filename')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset "><i className="fa fa-refresh"></i> </button>
                                            <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{JSON.parse(localStorage.getItem('filelistsession'))?.length}</button>
                                        </> : null
                                }

                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-filename"
                                                type="search"
                                                placeholder="Filter..."

                                                onInput={(event) => onFilterTextBoxChanged(event, 'filename')}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul " id="style-3">

                                        {filelist.map((file, index) => (

                                            <div className="form-check pb-1" key={index}>
                                                <input className="form-check-input" type="checkbox" value={file}

                                                    name={file}
                                                    onClick={() => toggleCheckFile(file, 'filename')}
                                                    checked={FilenameAllCheck[file]} />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckFile(file, 'filename')}>
                                                    {file}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3 " >
                            <div className="form-group1 font500 ">
                                <label for="exampleInputEmail1 ">STATUS</label>
                                <button style={{ float: 'right' }} onClick={() => selectAll('status')} className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All"><i className="fa fa-check"></i></button>
                                {
                                    //selectedstatus.length > 0 ?
                                    JSON.parse(localStorage.getItem('statuslistsession')) !== null ?
                                        <>
                                            <button onClick={() => resetAll('status')} className="ml-1 btn btn-white btn-mini btn-primary select-all-filter" title="Reset"><i className="fa fa-refresh"></i> </button>
                                            <button className="btn btn-white btn-mini btn-primary select-all-filter" title="Select All">{JSON.parse(localStorage.getItem('statuslistsession'))?.length}</button>
                                        </> : null
                                }
                            </div>
                            <div className="card landboxbodyviz" >

                                <div className="card-body py-2">

                                    <div className="col-12 pb-1 px-0 text-center" style={{ minHeight: 30 }}>


                                        <div className="input-group searchBoxvisualisation" >
                                            <div className="input-group-prepend border-right-0">
                                                <div
                                                    className="input-group-text bg-transparent "
                                                    id="btnGroupAddon2"
                                                >
                                                    <i className="fa fa-search font-size-14"></i>
                                                </div>
                                            </div>
                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box-status"
                                                type="search"
                                                placeholder="Filter..."

                                                onInput={(event) => onFilterTextBoxChanged(event, 'status')}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-12 pb-1 pt-1 px-0 bodyvisul" id="style-3">

                                        {statuslist.map((status, index) => (

                                            <div className="form-check pb-1" key={index}>
                                                <input className="form-check-input"
                                                    type="checkbox" value={status}
                                                    name={status}
                                                    onClick={() => toggleCheckStatus(status, 'status')}
                                                    checked={StatusAllCheck[status]} />
                                                <label className="form-check-label" for="defaultCheck1"
                                                    onClick={() => toggleCheckStatus(status, 'status')}>
                                                    {status}
                                                </label>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-3 " >
                            <div className="form-group1 font500  ">
                                <label for="exampleInputEmail1 ">UPLOADED TIME RANGE</label>

                            </div>
                            <div className="card" ref={refone}>

                                <div className="card-body py-2">


                                    <div className="col-12 pb-1 px-0 " >
                                        {/* <DateRangePicker
                                            // onEvent={handleEvent} 
                                            initialSettings={{
                                                startDate: mindate, endDate: maxdate, maxDate: maxdate,
                                                minDate: mindate, drops: 'up'
                                            }}
                                            
                                            onApply={onApply}

                                        >
                                            <input type="text" className="form-control"/>
                                        </DateRangePicker> */}

                                        {/* <div className="input-group searchBoxvisualisation calenderwrap1"

                                            style={{ width: "auto", border: "transparent" }}>

                                            <input
                                                className="form-control font-size-14 "
                                                id="filter-text-box"
                                                type="search"
                                                placeholder="Range..."

                                                value={`${startDate} to ${endDate}`}
                                                // value={`${moment(selectionRange?.[0].startDate).format('MM/DD/YYYY')} to
                                                //  ${moment(selectionRange?.[0].endDate).format('MM/DD/YYYY')}`}
                                                // style={{ position: 'absolute', left: -20, top: 10 }}
                                                onClick={() =>
                                                    setSideDrop(!sideDrop)}


                                            />

                                            <button
                                                type="button"

                                                className="btn bg-transparent"
                                                style={{ marginLeft: "-40px", zIndex: 100 }}
                                                onClick={() =>
                                                    setSideDrop(!sideDrop)}
                                            >
                                                <i class="fa fa-calendar"></i>
                                            </button>

                                            {
                                                sideDrop &&
                                                <DateRangemaxdate
                                                    ranges={[selectionRange]}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    startDatePlaceholder={startDate}
                                                    endDatePlaceholder={endDate}
                                                    months={1}
                                                    direction="horizontal"
                                                    // minDate={mindate}
                                                    minDate={new Date(mindate)}
                                                    maxDate={new Date()}
                                                    className="calenderelement"
                                                    onChange={(item) => {
                                                        console.log(item)
                                                        console.log(moment(item.selection['startDate']).format('MM/DD/YYYY'))
                                                        console.log(moment(item.selection['endDate']).format('MM/DD/YYYY'))
                                                        /// setSelectionRange([item.selection])}
                                                        var enddate = moment(item.selection['endDate']).format('MM/DD/YYYY')
                                                        var startdate = moment(item.selection['startDate']).format('MM/DD/YYYY')
                                                        if (startdate !== enddate) {

                                                            setStartTempDate(moment(item.selection['startDate']).format('MM/DD/YYYY'));
                                                            setEndTempDate(moment(item.selection['endDate']).format('MM/DD/YYYY'));
                                                            filterDatauploadedtime(item, 'uploadedtime')
                                                        }
                                                        // setMaxDate(moment(item.selection['endDate']).format('MM/DD/YYYY'));
                                                        // setMinDate(moment(item.selection['startDate']).format('MM/DD/YYYY'));
                                                        setSelectionRange({
                                                            // startDate: startTempDate,
                                                            // endDate: endTempDate,
                                                            startDate: moment(item.selection['startDate']).format('MM/DD/YYYY'),
                                                            endDate: moment(item.selection['endDate']).format('MM/DD/YYYY'),
                                                            key: 'selection'

                                                        })

                                                    }

                                                    }


                                                />


                                            }



                                        </div> */}

                                        <RangePicker

                                            onChange={onRangeChange}
                                            //defaultValue={[dayjs('2022-04-17'), dayjs('2022-04-21')]}
                                            value={value}
                                            minDate={dayjs(mindate, "YYYY/MM/DD")}
                                            maxDate={dayjs(maxdate, "YYYY/MM/DD")}
                                            format="YYYY/MM/DD"
                                        />


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mt-2 mb-4">
                        <div className="col-12">
                            <div className="form-group text-center pt-3 ">



                                <button type="button" onClick={approve} className={`btn btn-primary text-wrap fw-normal submit_btnapp`}>
                                    <i className="fa fa-check"></i> Apply
                                </button>
                                <button type="button" onClick={reset} className="btn   text-wrap fw-normal submit-reset" id="submit-reset" style={{ margin: '0 10px', backgroundColor: '#ba6868', color: 'white' }}>
                                    <i className="fa fa-refresh"></i>Reset All
                                </button>


                            </div>
                        </div>
                    </div> */}
                </div>
            }

            {show &&

                <div className={"modal fade" + (show ? " show d-block" : " d-none")} tabIndex="-1" role="dialog" style={{ 'position': 'fixed', 'top': '0', 'bottom': '0', 'left': '0', 'right': '0', 'background': 'rgb(182 184 202)', 'opacity': '1.0', 'paddingTop': '12%' }}  >
                    <div className="modal-dialog " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Proceed Without Selection?</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseApproval}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <p>No values are selected. Do you want to proceed Without Selection?</p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={approve}>Ok</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseApproval}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            }

        </>


    )
}
export default Basicfilter;
