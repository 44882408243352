import React from 'react';
import Nodata_found from "../assets/images/nodata_found.png";
export default (props) => {
    //console.log(props);
    return (
        <div
            role="presentation"
            className="ag-overlay-loading-center"
            // style={{ backgroundColor: '#b4bebe', height: '9%' }}
        >
            
            <img src={Nodata_found} alt="amgen_logo" style={{height:'30vh'}}/>
        </div>
    );
};
