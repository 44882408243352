import React, { useEffect, useState } from "react";
import "./AddNewUser.css";
import * as Icon from "react-bootstrap-icons";
import Select, { InputActionMeta } from "react-select";
import { invalidChars } from "../../constants";
import toast, { Toaster } from "react-hot-toast";
import { fetchPartnerRoleList, inviteNewUser } from "../Services/UserServices";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaHandshake, FaAddressCard } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { CountryList } from "../../CountryList/CountryList";
import DOMPurify from "dompurify";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const AddNewUser = () => {
  localStorage.removeItem('selectedtemplate');
  localStorage.setItem("showbasicfilter", false)
  const [partnerRoleListData, setPartnerRoleListData] = useState({});
  const [partnerList, setPartnerList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isSubmitClicked, toggleSubmitClicked] = useState(false);
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const[selectedCountry, setSelectedCountry] = useState(null);
  const [isMailNotValidated,toggleMailNotValidated] = useState(false);
  const [isUserIdNotValidated,toggleUserIdNotValidated] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [showErrorBox,toggleShowErrorBox] = useState(false);
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const location = useLocation();
  const sanitizeInput = (input) => {
    return input.replaceAll(/[&/\\#,+()$~%.^'":*?<>{}]/g, "");
  }
  const user = JSON.parse(localStorage.getItem("userDetails"));
  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, []);
  useEffect(() => {
    fetchPartnerRoleList()
      .then((data) => {
        setPartnerRoleListData(data);
        let partnerListData = Object.keys(data).map((key) => ({
          value: key,
          label: key,
        }));
        // console.log("partnerListData", partnerListData);
        setPartnerList(partnerListData);

        // console.log("data", data);
      })
      .catch((error) => {
        toast.error(sanitizeInput("There was an error!"));
      });
      setCountryList(CountryList);
  }, []);

  const blockSpecialChar = (event, field) => {
    //console.log("field", field);
    const invalidCharList = new Set(invalidChars);

    if (field === "email") {
      invalidCharList.delete("@");
      invalidCharList.delete(".");
    }
    if (field === "name") {
      invalidCharList.add("1");
      invalidCharList.add("2");
      invalidCharList.add("3");
      invalidCharList.add("4");
      invalidCharList.add("5");
      invalidCharList.add("6");
      invalidCharList.add("7");
      invalidCharList.add("8");
      invalidCharList.add("9");
      invalidCharList.add("0");
      invalidCharList.delete(" ");
    }

   // console.log("invalidCharList", invalidCharList);
    if (invalidCharList.has(event.key)) {
      event.preventDefault();
    }
  };
  const handleEmailOnBlur = (e) => {
    if (!re.test(selectedEmail)) {
      toggleMailNotValidated(true);
      toast.error(sanitizeInput("Please enter valid email address"));
    }
    else{
      toggleMailNotValidated(false);
    }
    
  };
  const handleUserID = (e) => {
    setSelectedUserID(e.target.value);
  };
  const handleUserIDOnBlur = (e) => {

    if (!selectedUserID.startsWith("a0")) {
      toggleUserIdNotValidated(true);
      toast.error(sanitizeInput("EIMS User ID must start with 'a0'"));
    }
    else{
      toggleUserIdNotValidated(false);
    }
  }
  const handlePartnerChange = (e) => {
    setSelectedPartner(e);
    setSelectedRole(null);
    let roleListData = partnerRoleListData[e.value].map((role) => ({
      value: role,
      label: role,
    }));
    setRoleList(roleListData);
  };
  const handleRoleChange = (event, action) => {
    //console.log("action", action);
    setSelectedRole(event);
    if (action.action === "select-option") {
      event.forEach((item) => {
        if (
          item.value === "Stage 2 Approver Quality" ||
          item.value === "Stage 2 Approver non-Quality"
        ) {
          let roleListDataUpdated = roleList.filter(
            (role) =>
              role.value !== "Stage 2 Approver Quality" &&
              role.value !== "Stage 2 Approver non-Quality"
          );
          //console.log("roleListData", roleListDataUpdated);
          setRoleList(roleListDataUpdated);
        }
      });
    }
    if (action.action === "remove-value") {
      if (
        action.removedValue.value === "Stage 2 Approver Quality" ||
        action.removedValue.value === "Stage 2 Approver non-Quality"
      ) {
        let roleListDataUpdated = partnerRoleListData[
          selectedPartner.value
        ].map((role) => ({ value: role, label: role }));
        setRoleList(roleListDataUpdated);
      }
    }
  };

  const handleReset = () => {
    setSelectedFirstName("");
    setSelectedLastName("");
    setSelectedEmail("");
    setSelectedUserID("");
    setSelectedPartner(null);
    setSelectedRole(null);
    setSelectedCountry(null);
    toggleSubmitClicked(false);
    toggleMailNotValidated(false);
    toggleUserIdNotValidated(false);
    setRoleList([])
  };
  const handleSubmit = (e) => {
    toggleSubmitClicked(true);
    e.preventDefault();
    if (
      !selectedFirstName ||
      !selectedLastName ||
      !selectedEmail ||
      !selectedUserID ||
      !selectedPartner ||
      !selectedRole
      // || !selectedCountry
    ) {
      toast.error(sanitizeInput("Please fill all the mandatory fields"));
      return;
    }
    if(selectedEmail.includes('@amgen'))
    {
      toast.error(("The current user registration page is intended for onboarding only external users. Kindly use ISM Service Catalogue for onboarding users with Amgen IDs"));
      return;
    }
    if (!re.test(selectedEmail)) {
      toast.error(sanitizeInput("Please enter valid email address"));
      return;
    }
    if (!selectedUserID.startsWith("a0")) {
      toast.error(sanitizeInput("User ID should start with 'a0'"));
      return;
    }
    
    const invitedUserObj = {
      first_name: selectedFirstName.trim(),
      last_name: selectedLastName.trim(),
      email: selectedEmail.trim(),
      user_id: selectedUserID.trim(),
      partner_name: selectedPartner?.value,
      role: selectedRole.map((role) => role?.value),
      created_by:user.email,
      country:selectedCountry?.value
    };
    
    inviteNewUser(invitedUserObj)
      .then((data) => {
        if (data?.status === "success") {
          handleReset();

          toast.success(sanitizeInput(data?.result));
        } else {
          
          setApiErrorMessage(data?.result);
            toggleShowErrorBox(true);
          setTimeout(() => {
            toggleShowErrorBox(false);
          }, 15000);
          // toast.error(System.web.HttpUtility.HtmlEncode(data?.result))
        }
      })
      .catch((error) => {
        toast.error(sanitizeInput(
          "There was an error in updating the user details. Please try again!"
        ));
      });
  };

  return (
    <React.Fragment>
      <form
        className=""
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10 col-sm-12 mt-3">
            <div className="row">
              <div className="col-6">
                <label>
                  First Name<span className={`mandetoryfield`}>*</span>
                </label>
                <br />
                <div className="row">
                  <div className="input-group-text ml-3">
                    <Icon.PersonFill color="white" />
                  </div>
                  <div className="col-10 pl-0">
                    <input
                      name="firstName"
                      type="text"
                      className={`col-12 form-control ${
                        isSubmitClicked && !selectedFirstName 
                          ? "border border-danger"
                          : ""
                      }`}
                      value={selectedFirstName}
                      onChange={(e) => setSelectedFirstName(e.target.value)}
                      onKeyDown={(e) => blockSpecialChar(e, "name")}
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <label>
                  Last Name<span className={`mandetoryfield`}>*</span>
                </label>
                <br />
                <div className="row">
                  <div className="input-group-text ml-3">
                    <Icon.PersonFill color="white" />
                  </div>
                  <div className="col-10 px-0">
                    <input
                      name="lastName"
                      type="text"
                      className={`col-12 form-control ${
                        isSubmitClicked && !selectedLastName
                          ? "border border-danger"
                          : ""
                      }`}
                      value={selectedLastName}
                      onChange={(e) => setSelectedLastName(e.target.value)}
                      onKeyDown={(e) => blockSpecialChar(e, "name")}
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6  mt-2">
                <label>
                  Email<span className={`mandetoryfield`}>*</span>
                </label>
                <br />
                <div className="row">
                  <div className="input-group-text ml-3">
                    <IoIosMail color="white" />
                  </div>
                  <div className="col-10 px-0">
                    <input
                      name="email"
                      type="email"
                      className={`col-12 form-control ${
                        isSubmitClicked? (!selectedEmail 
                          ? "border border-danger"
                          : "")
                          : selectedEmail.length>0 && isMailNotValidated ? "border border-danger" : ""
                      }`}
                      value={selectedEmail}
                      onChange={(e) => setSelectedEmail(e.target.value)}
                      onKeyDown={(e) => blockSpecialChar(e, "email")}
                      placeholder="Enter Email"
                      onBlur={handleEmailOnBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6  mt-2">
                <label>
                  User ID<span className={`mandetoryfield`}>*</span>
                </label>
                <br />
                <div className="row">
                  <div className="input-group-text ml-3">
                    <Icon.PersonFill color="white" />
                  </div>
                  <div className="col-10 px-0">
                    <input
                      name="userID"
                      type="text"
                      className={`col-12 form-control ${
                        isSubmitClicked? (!selectedUserID 
                          ? "border border-danger"
                          : "")
                          : selectedUserID.length>0 && isUserIdNotValidated ? "border border-danger" : ""
                      }`}
                      value={selectedUserID.toLowerCase()}
                      onChange={handleUserID}
                      onKeyDown={blockSpecialChar}
                      placeholder="Enter User ID"
                      onBlur={handleUserIDOnBlur}
                    />
                  </div>
                </div>
              
              </div>
            </div>
            
            <div className="col-12 px-0 mt-2">
              <label>
                Partner<span className={`mandetoryfield`}>*</span>
              </label>
              <br />
              <div className="row">
                <div className="input-group-text ml-3">
                  <FaHandshake color="white" />
                </div>
                <div className="col-11 px-0">
                  <Select
                    options={partnerList}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "0em 0.25em   0.25em 0em",
                        borderColor:
                          isSubmitClicked && !selectedPartner
                            ? "red"
                            : "#ced4da",
                        fontSize: "0.9rem",
                      }),

                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => ({
                        ...styles,
                        cursor: "pointer",
                        display: "block",
                        fontSize: "0.9rem",
                        width: "100%",
                        padding: "5px 12px",
                        boxSizing: "border-box",
                      }),
                    }}
                    //  className={` form-control  `}
                    aria-label="Default select example"
                    classNamePrefix="selectDropdown"
                    name="partner"
                    value={selectedPartner || ""}
                    onChange={handlePartnerChange}
                    placeholder="Select Partner"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6  mt-2">
                <label>
                  Role<span className={`mandetoryfield`}>*</span>
                </label>
                <br />
                <div className="row">
                  <div className="input-group-text ml-3">
                    <FaAddressCard color="white" />
                  </div>
                  <div className="col-10 px-0">
                    <Select
                      options={roleList}
                      // onInputChange={onInputChange}
                      value={selectedRole || ""}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderRadius: "0em 0.25em   0.25em 0em",
                          borderColor:
                            isSubmitClicked && !selectedRole ? "red" : "#ced4da",
                          fontSize: "0.9rem",
                        }),

                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => ({
                          ...styles,
                          cursor: "pointer",
                          display: "block",
                          fontSize: "0.9rem",
                          width: "100%",
                          padding: "5px 12px",
                          boxSizing: "border-box",
                        }),
                      }}
                      //  className={` form-control  `}
                      aria-label="Default select example"
                      name="role"
                      classNamePrefix="selectDropdown"
                      isMulti
                      onChange={handleRoleChange}
                      placeholder="Select Role(s)"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 mt-2">
                <label>
                  Country
                </label>
                <br />
                <div className="row">
                  <div className="input-group-text ml-3">
                    <FaLocationDot color="white" />
                  </div>
                  <div className="col-10 px-0">
                  <Select
                    options={countryList}
                    className={` select-template  `}
                    isSearchable={true}
                    // menuIsOpen={true}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "0em 0.25em   0.25em 0em",
                        borderColor:
                        "#ced4da",
                        fontSize: "0.9rem",
                      }),

                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => ({
                        ...styles,
                        cursor: "pointer",
                        display: "block",
                        fontSize: "0.9rem",
                        width: "100%",
                        padding: "5px 12px",
                        boxSizing: "border-box",
                        borderBottom: data?.className ? "1px solid #8e8e8e" : "",
                      }),
                    }}
                    classNamePrefix="selectCountryDropdown"
                    aria-label="Default select example"
                    name="country"
                    // onChange={handleSelectTemplate}
                    value={selectedCountry || ""}
                    // placeholder="Select Template Name"
                    onChange={(e) => setSelectedCountry(e)}
                    placeholder="Select Country"
                  />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-lg-7 col-md-10 col-sm-12 row">
            <div className="col-3 pl-0">
              <a
                href="https://amgen.okta.com/app/amgeninc_eims_1/exkldg2rdhbVZs0UR0x7/sso/saml"
                className="emsLink"
                target="_blank"
                rel="noreferrer"
              >
                EIMS Link to Invite User
              </a>
            </div>
            <div className="text-right offset-6 col-3 pr-0">
            <button type="submit" className="btn btn-primary ">
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-secondary ml-3"
                onClick={handleReset}
              >
                Reset
              </button>
              
              <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                  className: "",
                  

                  error: {
                    duration: 5000,
                    icon: "❗",
                  },
                }}
              />
            </div>
          </div>
        </div>
        {showErrorBox && <div className="errorBox">❗ {apiErrorMessage}</div>}
      </form>
    </React.Fragment>
  );
};

export default AddNewUser;
