import React, { useState, useContext } from "react";
import "./DisclaimerSection.css";
import Modal from "react-modal";
import { PdfImg } from "../../SvgFiles/SvgFile";
import { downloadResourcesTemplate, setSOPFLag } from "../Services/UserServices";
import toast, { Toaster } from "react-hot-toast";
import userContext from "../../user-context";
import DOMPurify from 'dompurify';
export default function DisclaimerSection(props) {
  const { showDisclaimer, toggleDisclaimer } = props;
  const { userID, fetchUserData, setLoading,esignURL,fetchUserRolesAPI,user } = useContext(userContext);
  const [showPopup, setPopup] = useState(showDisclaimer);
  const [hasChecked, toggleHasChecked] = useState(false);
  
  const sanitizeInput = (input) => {
    // This will remove any < and > characters to prevent HTML tag injection
    return input.replace(/[<>]/g, "");
  }
  const handleDisclaimerPopup = () => {
    setLoading(true);
    // setSOPFLag(userID);
    // var url = 'https://dpc-dev.amgen.com/api/link&prompt=login';
    var url=esignURL
    var win = window.open(url, "thePopUp", "width=600,height=600");
    win.openedFromDisclaimer=true;
    // win.localStorage.removeItem("hashToken");
    window.localStorage.setItem("eSign", 'processing');
    
    var pollTimer = window.setInterval(function() {
      if (win.closed !== false) {
        window.clearInterval(pollTimer);
        console.log("closed");
        popupClosed();
      }
    }, 200);
    // fetchUserData(userID);

  };
  const popupClosed = () => {
    console.log("popup closed");
    if (localStorage.getItem("eSign") === "done") {
      fetchUserData(userID);
      // toggleDisclaimer(false);
      setPopup(false);
      toast.success('E-Sign completed successfully');
      // setLoading(false);
      localStorage.removeItem("eSign");
    } else {
      setLoading(false);
      toggleDisclaimer(true)
      alert('E-Sign failed. Please try again');
    }
  };
  const handleDownloadPDF = (pdfName) => {
    downloadResourcesTemplate(pdfName.trim())
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        //link.href = url;
        // if (/^https?:\/\//.test(url)) {
          link.href = DOMPurify.sanitize(url);
        // } else {
          // console.log('Invalid URL:', url);
          // Handle the invalid URL appropriately
          // For example, you might set link.href to a default safe URL or leave it unchanged
        // }
        // set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = sanitizeInput(pdfName) + ".pdf"; // the file name you want
        // this part will append the anchor tag and remove it after automatic click
        document.body.appendChild((link));
        link.click();
        document.body.removeChild(link);
        toast.success("PDF will be downloaded shortly");
      })
      .catch((error) => {
        toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
        
      });
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0%",
      width: "50%",
      // height: "50%",
      overflow: "hidden !important",
      // display: "flex  !important",
      // flexDirection: "column  !important",
      // webkitBoxOrient: "vertical!important",
      // webkitBoxDirection: "normal!important",
      // msFlexDirection: "column!important",
      // -webkit-box-orient: vertical!important;
      // -webkit-box-direction: normal!important;
      // -ms-flex-direction: column!important;
      // flex-direction: column!important;
    },
    overlay: { zIndex: 1000, backgroundColor: "rgba(0,0,0,0.8)" },
  };
  return (
    <React.Fragment>
      {/* <div className="disclaimerPopup "> */}
      <Modal isOpen={showPopup} style={customStyles} ariaHideApp={false} >

        <div className="  mt-3">
          <div className="disclaimerBody text-left px-3 ">
            You are not trained on the latest version of SOP. Please click on
            the below image and download the SOP Document to complete your
            training.
          </div>
          <div className="text-center px-3">
            <div
              className="ml-2 mt-5 mousePointer"
              onClick={() => handleDownloadPDF("SOP-423634")}
            >
              <PdfImg baseHeight="60" />
            </div>
          </div>

          <div className="mb-2 pt-2 px-3 modalFooter">
            <div className="row px-3 justify-content-left ">
              <div className=" align-items-center text-right   px-0">
                <input type="checkbox" id="checkboxESign" onChange={() => toggleHasChecked((hasChecked) => !hasChecked)} />
                <label htmlFor="checkboxESign" className=" px-1 declarationText">By checking the box and e-signing I am confirming that I have completed the SOP Training</label>
              </div>
              {/* <div className="col-2 px-0"> */}
              <button type="button" className="btn btn-sm btn-primary col-1 px-0"
                disabled={!hasChecked}
                onClick={handleDisclaimerPopup}
              >
                e-Sign
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      </Modal>
      {/* </div> */}
      
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",

          error: {
            duration: 5000,
            icon: "❗",
          },
        }}
      />
    </React.Fragment>
  );
}
