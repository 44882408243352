import React from "react";

const Loading = () => {
  return (
    <div className="flex items-center justify-center loader" style={{position:'fixed',inset:0}}>
      <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin loaderInside">
      
      </div>
    </div>
    // <div id="cover-spin"></div>
  );
};

export default Loading;
