import React, { useState, useRef, useMemo, useEffect, useCallback, useContext } from 'react';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';;
import 'react-tabs/style/react-tabs.css';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import Accountstyle from '../Accounts/Account.module.css';
import Accounttemplatestyle from '../Template/Addtemplate.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowDQMdetails from './ShowDQMdetails';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { CheckLg, X } from 'react-bootstrap-icons';
import { fetchTemplateAction, templateStatusUpdate, downloadtemplare } from "../Services/UserServices";
import userContext from "../../user-context"
import DOMPurify from 'dompurify';
const Action = (props) => {

    const gridRef = useRef();
    const [rowData, setRowData] = useState([])
    const [errormessage, setErrormessage] = useState("");
    const [successmessage, setSuccessmessage] = useState("");
    const [selectedata, setSeleteddata] = useState({});
    const [selecterowclickdata, setSeletedrowclickdata] = useState([]);
    //const [rejectreason, setRejectreason] = useState([]);
    const [showDQM, setshowDQM] = useState(false);
    //const [iseditable, setIseditable] = useState(false);
    const userdetailsokta = useContext(userContext);
    const [checkboxselect, setCheckboxselect] = useState(false);
    const [errorstate, setErrorcheck] = useState(false);
    const [modalAppralOpen, setModalAppralOpen] = useState(false)
    const [show, setShowApprovalModal] = useState(false)
    const [approvalStatus, setApprovalStatus] = useState("")

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    const onGridReady = useCallback((params) => {
        fetchTemplateActionData()
    }, []);

    const fetchTemplateActionData = () => {

        fetchTemplateAction(userdetailsokta.userID).then((datas) => {

            var templateactiondata = datas;
            setRowData(templateactiondata);
            //console.log(templateactiondata)
        })
            .catch(error => {
                //console.log(error)
                setErrormessage(error.toString())
                //console.error('There was an error!', error);

            });
    }

    function NameValueGetter(params) {
        return params.data.requesterName + "(" + params.data.requestRaisedBy + ")"
    }

    const [columnDefs] = useState([
        // {headerName: "Filename", field: "filename",sortable: true,unSortIcon: true,sort: 'asc' }requestId,
        // {headerName: "100% Template verification", field: "verification", checkboxSelection:true,width: 100, maxWidth:140,wrapText: true,autoHeight: true  },
        // {headerName: "Approve", field: "approve", checkboxSelection:true,width: 100, maxWidth:110,wrapText: true,autoHeight: true  },
        // {headerName: "Reject", field: "reject", checkboxSelection:true,width: 100, maxWidth:110,wrapText: true,autoHeight: true  },
        { headerName: "Template Name", field: "templateName", checkboxSelection: true, tooltipField: 'templateName' },
        {
            headerName: "Request No", field: "requestId", width: 130, maxWidth: 150, tooltipField: 'requestId',
            valueFormatter: (params) => {

                return 'TEMP-' + params.value;
            },
        },
        { headerName: "Version", field: "templateFileVersion", width: 100, maxWidth: 90, tooltipField: 'templateFileVersion' },
        {
            headerName: 'Owner', field: 'requestRaisedBy', width: 180, maxWidth: 280, tooltipField: 'requesterName',
            valueGetter: NameValueGetter,
        },
        {
            headerName: 'Status', field: 'currentStatus', width: 230, maxWidth: 210, tooltipField: 'currentStatus', wrapText: true, autoHeight: true,
            valueFormatter: (params) => {

                if (params.value === 'SUBMITTED' || params.value === 'Submitted') {
                    return 'SUBMITTED'
                }
                //return '£' + params.value;
            },
        },
        { headerName: 'Updated On (UTC)', field: 'recordCreateDT', width: 180, maxWidth: 180, tooltipField: 'recordCreateDT' },
        //{ headerName:'Approver',field: 'approver',sortable: true,unSortIcon: true,width: 100, maxWidth:150 ,wrapText: true,autoHeight: true  },
        //{ headerName:'Comments',field: 'comments',sortable: true,unSortIcon: true ,width: 100, maxWidth:150 ,wrapText: true,autoHeight: true,},
        {
            headerName: "Action", field: "action", width: 120, maxWidth: 120,
            cellRenderer: function (params) {
                //console.log(params)
                return <><span style={{ cursor: 'pointer' }} onClick={() => viewDQM(params)} data-toggle="tooltip" data-action-type="view" data-placement="top" title="View DQM" ><FontAwesomeIcon icon={faEye} size="lg" data-action-type="view" /></span>
                    <span className='pl-2' style={{ cursor: 'pointer' }} onClick={() => downloadFile(params)} data-toggle="tooltip" data-placement="top" title="Download"><FontAwesomeIcon icon={faDownload} size="lg" data-action-type="download" /></span>

                </>;
            }
        }


    ])

    // function ReasonRejectComponent(props)
    // {
    //     console.log(props.template_name)
    //     //return <><span style={{color:'#858585',fontStyle:'italic',fontWeight:'300'}}>Comments</span></>
    // 	//console.log(iseditable)
    // 	// if(iseditable)
    // 	// {
    // 		return <><textarea name="reason"  className='textarea' rows="1" cols="14"  placeholder='Reason for Rejection'></textarea></>
    // 	//}


    // }


    const onSelectionChanged = useCallback((event) => {
        //var selectedRows = gridRef.current.api.getSelectedRows();
        //console.log(event)
        var selectedRows = gridOptions.api.getSelectedRows();
        //console.log(gridOptions.api.getColumnDefs())
        var getcoldef = gridOptions.api.getColumnDefs();
        //console.log(getcoldef)
        var selectedRowsString = '';
        var maxToShow = 5;
        var selectedname = []
        selectedRows.forEach(function (selectedRow, index) {
            if (index >= maxToShow) {
                return;
            }
            if (index > 0) {
                selectedRowsString += ', ';
            }
            selectedRowsString += selectedRow['requestId'];
            selectedname.push(selectedRow)
        });
        setSeletedrowclickdata(selectedname)
        // console.log((selectedRows))
        // gridOptions.columnApi.getColumn('Comments').getColDef().editable = true;
        // console.log(selectedname)
        // console.log(selectedname.includes((selectedRows[0]['filename'])))
        // if(selectedRows.length>0 &&  selectedname.includes((selectedRows[0]['filename'])))
        // {
        // 	console.log(selectedRows[0]['filename'])
        // 	getcoldef[6]['editable'] =true
        // }
        // else
        // {
        // 	getcoldef[6]['editable'] =false
        // }
        //console.log(getcoldef);

        //********************************* */
        gridOptions.api.setColumnDefs(getcoldef)
        // if (selectedRows.length > maxToShow) {
        //   var othersCount = selectedRows.length - maxToShow;
        //   selectedRowsString +=
        // 	' and ' + othersCount + ' other' + (othersCount !== 1 ? 's' : '');
        // }
        // document.querySelector('#selectedRows').innerHTML = selectedRowsString;
    }, []);


    function IconComponent(props) {
        //console.log(props);
        return <><span style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="View data and supplementary data"><FontAwesomeIcon icon={faEye} size="sm" /></span><span className='ps-2' style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Download" onClick={downloadFile}><FontAwesomeIcon icon={faDownload} size="sm" /></span></>;
    }

    function downloadFile(data) {

        // console.log(data['data'])
        downloadtemplare(data['data']['templateName']).then((datas) => {
            // console.log(datas)
            // console.log(data['data'])
            const url = URL.createObjectURL(new Blob([data.templateName]));
            const link = document.createElement('a');
            //link.href = datas.url;
            const url1 = DOMPurify.sanitize(datas.url);

            // A simple validation to check if the URL starts with http:// or https://
            if (/^https?:\/\//.test(url1)) {
                link.href = url1;
            } else {
                console.log('Invalid URL:');
                // Handle the invalid URL appropriately
                // For example, you might set link.href to a default safe URL or leave it unchanged
            }
            link.setAttribute('download', data['data']['templateName']);
            document.body.appendChild(DOMPurify.sanitize(link));
            link.click();


        })
            .catch(error => {
                setErrormessage(error.toString())
                //console.error('There was an error!', error);
            });

        //console.log(data.filename)
        // const url = URL.createObjectURL(new Blob([data['data']['templateName']]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', data['data']['templateName']);
        // document.body.appendChild(link);
        // link.click();


    }

    function viewDQM(data) {
        //console.log(data)
        // var requestid = data.requestId
        //console.log(requestid)
        setSeleteddata(data['data'])
        setshowDQM(showDQM => !showDQM)
        //return (<ShowDQMdetails dqmdata = {selectedata} showtem={showDQM} />);
    }


    // const cellClickedListener = useCallback( event => {
    // 	//console.log('cellClicked', event);
    // 	//console.log(event.event.path[1].getAttribute("data-icon"))
    // 	//console.log(event.column.getColId())
    // 	let actionType = event.event.target.getAttribute("data-icon");
    // 	//console.log(event.event.target.type)

    // 	setSeleteddata(event.data)
    // 	if(actionType === 'eye' || event.event.path[1].getAttribute("data-icon") === 'eye')
    // 	{
    // 		viewDQM(event.data)
    // 	}
    // 	if(actionType === 'download' || event.event.path[1].getAttribute("data-icon") === 'download')
    // 	{
    // 		downloadFile(event.data)
    // 	}
    // 	if(event.event.target.type === 'textarea')
    // 	{
    // 		console.log(event.event.target.value)
    // 	}

    //   }, []);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            //minWidth: 100,
            //resizable: true,
            //width:170,
            //sortable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            suppressMovable: true,
        };
    }, []);

    const gridOptions = {
        columnDefs: columnDefs,
        defaultColDef: defaultColDef,
        rowData: rowData,
        cacheQuickFilter: true,

    };

    const onFilterTextBoxChanged = useCallback(() => {
        // gridRef.current.api.setQuickFilter(
        //   document.getElementById('filter-text-box').value
        // );
        gridOptions.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    function errorClass(error) {
        return (error ? 'is-invalid' : '');
    }

    let selectedTemplate = (type) => {
        setApprovalStatus('APPROVED')
        //console.log(selecterowclickdata)
        //console.log(document.getElementsByClassName('comments_textarea')[0].value)
        //console.log(checkboxselect)
        if (selecterowclickdata.length === 0) {
            setErrormessage("Please select at least one checkbox")
            return false
        }
        else {
            setErrormessage("")
            let status = ''
            if (type === 'approve') {
                status = 'APPROVED'
                // console.log(document.getElementsByClassName('check_lock')[0].value)
                if (checkboxselect === false) {
                    setErrorcheck(true)
                    return false

                }
                else {
                    setErrorcheck(false)
                    setModalAppralOpen(true)
                    setShowApprovalModal(true)
                }

            }
            else {
                status = 'REJECTED'

                var actiontemplate = []

                for (let row = 0; row < selecterowclickdata.length; row++) {
                    actiontemplate.push({
                        'requestID': selecterowclickdata[row]['requestId'], 'currentStatus': status,
                        'comments': document.getElementsByClassName('comments_textarea')[0].value,
                        'stageOneApprovedBy': (localStorage.getItem('role') === 'Stage 1 Template Approver') ? userdetailsokta.userID : selecterowclickdata[row]['stage_One_Approver'],
                        'adminPortalApprovedBy': (localStorage.getItem('role') === 'Stage 1 Template Approver') ? '' : userdetailsokta.userID
                    })
                }
                // console.log(actiontemplate)
                props.parentTabCallback(0)

                templateStatusUpdate(actiontemplate).then((datas) => {
                    //console.log(datas)
                    if (datas === true) {
                        fetchTemplateActionData()
                        props.parentTabCallback(0)
                        document.getElementsByClassName('comments_textarea')[0].value = '';
                        setErrormessage('')
                        setErrorcheck(false)
                        document.getElementsByClassName("check_lock")[0].checked = false
                        setSuccessmessage("The request has been " + status)
                    }


                })
                    .catch(error => {
                        setSuccessmessage("")
                        setErrormessage(error.toString())
                        setErrorcheck(false)
                        setModalAppralOpen(false)
                        document.getElementsByClassName("check_lock")[0].checked = false
                        //console.error('There was an error!', error);

                    });

            }



        }

    }

    let handleCloseApproval = () => {
        setShowApprovalModal(false)
        // setShow(false);
    };
    let handleShowApproval = () => {
        setShowApprovalModal(true)
        var actiontemplate = []


        for (let row = 0; row < selecterowclickdata.length; row++) {
            actiontemplate.push({
                'requestID': selecterowclickdata[row]['requestId'], 'currentStatus': approvalStatus,
                'comments': document.getElementsByClassName('comments_textarea')[0].value,
                'stageOneApprovedBy': (localStorage.getItem('role') === 'Stage 1 Template Approver') ? userdetailsokta.userID : selecterowclickdata[row]['stage_One_Approver'],
                'adminPortalApprovedBy': (localStorage.getItem('role') === 'Stage 1 Template Approver') ? '' : userdetailsokta.userID
            })
        }
        //console.log(actiontemplate)
        props.parentTabCallback(0)
        setModalAppralOpen(false)
        setShowApprovalModal(false)

        templateStatusUpdate(actiontemplate).then((datas) => {
            //console.log(datas)
            if (datas === true) {
                fetchTemplateActionData()
                props.parentTabCallback(0)
                document.getElementsByClassName('comments_textarea')[0].value = '';
                setErrormessage('')
                setErrorcheck(false)
                document.getElementsByClassName("check_lock")[0].checked = false
                setSuccessmessage("The request has been " + approvalStatus)
            }


        })
            .catch(error => {
                setSuccessmessage("")
                setErrormessage(error.toString())
                setErrorcheck(false)
                setModalAppralOpen(false)
                setShowApprovalModal(false)
                document.getElementsByClassName("check_lock")[0].checked = false
                //console.error('There was an error!', error);

            });



    };

    // const getRowId = useMemo(() => {
    //     return (params) => params.data.template_name+'_'+params.data.fileversion;
    //   }, []);


    return (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">

                        <span className={` ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</span>
                        <span className="text-success mb-3 globaltextformat ">{successmessage}</span>
                    </div>
                </div>

                <div className="col-sm-6" style={{ 'textAlign': 'right' }}>
                    <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Filter..."
                        onInput={onFilterTextBoxChanged}
                        style={{ 'width': '35%' }}
                    />
                </div>
            </div>
            {showDQM && <ShowDQMdetails dqmdata={selectedata} showtem={showDQM} setshowDQM={setshowDQM} />}

            <div style={{ height: '100%', width: '100%', 'paddingTop': '5px' }} className="templateaction">
                <div
                    className="ag-theme-alpine datatable"
                    style={{
                        height: '332px',
                        width: 'auto',
                    }}
                >
                    <AgGridReact
                        gridOptions={gridOptions}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                        ref={gridRef}
                        //cacheQuickFilter={true}
                        pagination={true}
                        //paginationPageSize={3}
                        paginationAutoPageSize={true}
                        frameworkComponents={{
                            //iconComponent: IconComponent,
                            //reasonRejectComponent:ReasonRejectComponent
                        }}
                        //onCellClicked={cellClickedListener}
                        onSelectionChanged={onSelectionChanged}
                        rowSelection={'multiple'}
                        //getRowId={getRowId}
                        cacheQuickFilter={true}
                        suppressRowClickSelection={true}
                        onGridReady={onGridReady}
                    >
                    </AgGridReact>
                </div>


                {/* <div className="mb-0 pt-3"> */}
                {/* <div className="form-group text-center pt-3 ">
                    <button type="reset" onClick={resetForm}  className="btn btn-secondary pull-right text-wrap fw-normal " id="submit-reset" style={{margin: '0 10px'}}>
                        <ArrowClockwise /> Clear Selection
                    </button>

                    <button type="button" onClick={rejectRows}  className="btn btn-secondary pull-right text-wrap fw-normal " id="submit-reset" style={{margin: '0 10px'}}>
                        <X size="20"/>Reject
                    </button>
                    
                    <button type="submit" className={`btn btn-primary text-wrap fw-normal ${Accountstyle.submit_btn}`}>
                    <CheckLg /> Approve
                    </button>
                    
                    </div> */}
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group pt-1 ">

                            <textarea className="form-control comments_textarea" rows="2" cols="30" placeholder="Comments"></textarea>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-check">
                            <input onClick={(event) => { setCheckboxselect(event.target.checked); console.log(event.target.checked) }} id="check_lock" className={`form-check-input check_lock ${errorClass(errorstate)}`} type="checkbox" name="check_lock" value="lock" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                <i>Reviewed and verified that the template(s) are locked</i>
                            </label>
                        </div>
                    </div>

                    <div className="col-sm-5">
                        <div className="form-group text-center pt-3 ">

                            <button type="button" onClick={() => selectedTemplate('reject')} className="btn  text-wrap fw-normal submit-reset" id="submit-reset" style={{ margin: '0 10px', backgroundColor: '#ba6868', color: 'white' }}>
                                <X size="20" />Reject
                            </button>

                            <button type="button" onClick={() => selectedTemplate('approve')} className={`btn btn-primary text-wrap fw-normal ${Accountstyle.submit_btn}`}>
                                <CheckLg /> Approve
                            </button>

                        </div>
                    </div>
                </div>

                {/* </div> */}
            </div>





            {modalAppralOpen &&

                <div className={"modal fade" + (show ? " show d-block" : " d-none")} tabIndex="-1" role="dialog" style={{ 'position': 'fixed', 'top': '0', 'bottom': '0', 'left': '0', 'right': '0', 'background': 'rgb(182 184 202)', 'opacity': '1.0', 'paddingTop': '12%' }}  >
                    <div className="modal-dialog " role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseApproval}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                UserId : {userdetailsokta.userID}
                                <p>Do you want to approve this template?</p>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={handleShowApproval}>Ok</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseApproval}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            }


        </>
    )
}

export default Action;