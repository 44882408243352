import React  from "react";
import { useState,useEffect } from "react";


const ApprovalMessage = ()=>{
  
    const [message, setmessage] = useState(false);
    useEffect(() => {
        if(localStorage.getItem("role") === 'Stage 1 Approver' || localStorage.getItem("role") === 'Stage 2 Approver non-Quality'|| 
        localStorage.getItem("role") === 'Stage 2 Approver Quality')
        {
            setmessage(true)
        }
        else{
            setmessage(false)
        }
        
    })

    return(
       
       

        message ?
        <>
        {/* <div style={{  color: '#f50202',fontSize:'12px',textAlign:'center',fontWeight:700}} className="py-1">
        Approver should approve their respective Partner files only</div> */}
        <div className="alert alert-danger mb-0 mt-1 autoSizeDiv" role="alert" style={{  color: '#f50202',fontSize:'12px',textAlign:'center',fontWeight:700}}>
            <i className="fa fa-exclamation-circle mr-2" style={{color:'red',fontSize:'12px'}}></i>
            Approver should approve their respective Partner files only
        </div>
        </>
        :
        <div style={{  color: 'white',fontSize:'12px',textAlign:'center',fontWeight:700}} className="py-1">{' .'}</div>
          
    )


}

export default ApprovalMessage;