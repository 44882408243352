import React from  "react";
import {Link } from "react-router-dom";
import Accountstyle from './Account.module.css';
import logo from '../assets/amgen_logo.png';
class Footer extends React.Component {
    render() {
        return(
            // <footer className={`text-center text-lg-start text-muted ${Accountstyle.footerbackground}`}>
            <footer className={`text-center text-lg-start  ${Accountstyle.footerbackground}`}>
                <section className="justify-content-center justify-content-lg-between p-2  border-bottom">
                    <div className="row">
                        <div className="col-sm-12 col-lg-8 col-md-6 text-left">
                            <div className="me-2  d-lg-block">
                                <img src={logo} alt=""  height="24" />
                               
                                <span className="footerVertcalBorder pl-2 mt-2"></span>
                                {/* <span className={`ps-3 ${Accountstyle.textformat}`}>Please contact <a  className={Accountstyle.textformat} href="mailto:TCSDPCTeam@amgen.com">Administrator</a> for any issue or questions</span> */}
                                <span className={`pl-2  ${Accountstyle.textformat}`}>Please create or ask your Amgen Sponsor to create a ticket to assignment group App-Digital-Collab for any issue or questions.</span>
                            </div>
                           
                        </div>
                        <div className="col-sm-12 col-lg-4 col-md-6">
                            <div className={`pe-5 pt-2  text-right mob-text-center justify-content-lg-between ${Accountstyle.footerrighttextformat}  ${Accountstyle.textformat}` }><span className="text-reset ">© {(new Date().getFullYear())} Amgen Inc. All rights reserved</span></div>

                        </div>
                    </div>
                    {/* <div className="me-2 d-none d-lg-block">
                        <img src={logo} alt=""  height="24" />
                        <span className={`ps-3 ${Accountstyle.textformat}`}>Please contact <a  className={Accountstyle.textformat} href="mailto:TCSDPCTeam@amgen.com">TCSDPCTeam@amgen.com</a> for any issue or questions</span>
                    </div>

                    <div className={`pe-5 pt-2 text-center d-flex  justify-content-lg-between ${Accountstyle.footerrighttextformat}  ${Accountstyle.textformat}` }><span className="text-reset ">© {(new Date().getFullYear())} Amgen Inc. All rights reserved</span></div> */}

                </section>
            
            
            </footer>

        );

    }
  }

export default Footer;