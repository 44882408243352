import jwt_decode from "jwt-decode";

async function verifyToken(token) {
    try { 
        const payload = jwt_decode(token);
        return { verified: true, payload: payload };
    } catch(err) {
        console.log("TOKEN ERROR")
        //console.log(err)
        return { verified: false, payload: null };
    }
}

const methods = { verifyToken};

export default methods;