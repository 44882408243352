import React from "react";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
const Tablefilterdata = (props) => {
  //console.log(props)
  const gridRef = useRef();

  //const [rowData, setRowData] = useState([]);
  //const [columnDefs, setColumnDefs] = useState([]);
  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
       minWidth: 180,
       initialWidth: 220,
      // wrapHeaderText: true,
      // autoHeaderHeight: true,
      width: 280,
      // resizable: true,
      // wrapText: true,
      // // autoHeight: true,
      // cacheQuickFilter: true,
      cacheQuickFilter: true,
      flex: 1,

      sortable: true,
      color: "red",

      wrapHeaderText: true,
      suppressMovable: true,
      autoHeaderHeight: true,
      //     wrapText: true,
      //    autoHeight: true,
      unSortIcon: true,
    };
  }, []);
  const [dynamicHeaders, setdynamicHeaders] = useState([]);
  const [dynamicTable, setdynamicTable] = useState([]);
  //     const onGridReady = useCallback((params) => {
  //         console.log(props)
  //         const columnDefs = props['initialheaders'].map((header) => {
  // //sortable: true,unSortIcon: true,
  //             return { headerName: header.label, field: header.value, tooltipField: header.value, };
  //           });
  //           console.log(columnDefs)
  //           setdynamicHeaders(columnDefs);
  //       }, [props]);

  useEffect(() => {
    //console.log('klklklklklkl')
    const columnDefs = props['initialheaderstable'].map((header) => {
      //sortable: true,unSortIcon: true,
      return {
        headerName: header.label, field: header.key, tooltipField: header.key,
        valueFormatter: (params) => {
          if (params.value === 'None') {
            return '';
          }
        }
      };
    });
    //console.log(columnDefs)
    setdynamicHeaders(columnDefs);
    const sortedArray = props?.filterLineChartData.sort((a, b) => a.cmo_lot_number - b.cmo_lot_number);
    setdynamicTable(sortedArray)
    
  }, [props]);

  const onFilterTextBoxChanged = useCallback(() => {
    // gridOptions.api.setQuickFilter(
    //   document.getElementById('filter-text-box1').value
    // );
    gridRef.current.api.setQuickFilter(
      document.getElementById('filter-text-boxdata').value
    );
  }, []);
  return (
    <div className='row'>
      <div className="col-12 text-right">
        <span className="text-warning pull-right report-warning-info">
          <i className="ace-icon fa fa-exclamation-triangle"></i>
          The x-axis values are plotted using a non-linear scale (i.e. spaced equally regardless of values)
        </span>
      </div>

      <div className="col-sm-6">

        <div className="form-group">


          {/* <span className={` ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</span>
                <span className="text-success mb-3 globaltextformat ">{successmessage}</span> */}
        </div>
      </div>

      <div className="col-sm-6 pt-1" style={{ 'textAlign': 'right' }}>
        <input
          type="text"
          id="filter-text-boxdata"
          placeholder="Search..."
          onInput={onFilterTextBoxChanged}
          style={{ 'width': '45%' }}
        />
      </div>

      <div className='col-12 pt-1'>
        <div className='ag-theme-alpine datatable' style={{ height: 400, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            //onGridReady={onGridReady}
            //rowData={props?.filterLineChartData}
            rowData={dynamicTable}
            //columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            columnDefs={dynamicHeaders}
            pagination={true}
            paginationAutoPageSize={true}
          />
        </div>
      </div>
    </div>
  )

}
export default Tablefilterdata;