import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Accountstyle from "./Account.module.css";
//import logo from '../../logo.svg';
//import * as Icon from 'react-bootstrap-icons';

import roleContext from "../../role-context";
//import Addtemplate from '../Template/Addtemplate.jsx'
import UserProfile from "../../UserProfile";
import data_mapping from "../assets/View Data Mapping.svg";
import template_logo from "../assets/Add_Edit Templete.svg";
import approval_logo from "../assets/Approval.svg";
import partner_logo from "../assets/Add_Edit Partner.svg";
import resources from "../assets/Resource.svg";
import file_upload from "../assets/File_Upload.svg";
import approval_file from "../assets/Approval_File.svg";
import invite_user from "../assets/Invite_User.svg";
import approval_queue from "../assets/File Approval Queue.svg";
import visualisation from "../assets/Visualization.svg";
import upload1 from "../assets/images/upload.png";
import upload from "../assets/File_Upload.svg";
import dpc_bg from "../assets/images/A2.png";
import "./Landing.css";
import userContext from "../../user-context";
import ApprovalMessage from "../ApprovalMessage/ApprovalMessage";
const Landing = (props) => {
  const { blockData } = useContext(userContext);
  localStorage.removeItem("selectedtemplate");
  localStorage.setItem("showbasicfilter", false);
  // localStorage.removeItem('showbasicfilter');
  localStorage.removeItem("type");
  localStorage.removeItem("startdate");
  localStorage.removeItem("enddate");
  // localStorage.removeItem("eSignApproval");
  // localStorage.removeItem("isapprovalpage");
  //localStorage.removeItem('selectedtemplate');
  localStorage.removeItem("restorebasicfilter");
  localStorage.removeItem("basicfiltertype");
  localStorage.removeItem("lastselectedchart");
  localStorage.removeItem("lastselectedheader");
  localStorage.removeItem("lastselectedheaderdata");
  localStorage.removeItem("restoreadvancefilter");
  localStorage.removeItem("xaxis");
  localStorage.removeItem("yaxis");
  localStorage.removeItem("shape");
  localStorage.removeItem("color");
  localStorage.removeItem("selectedcheckdpartner");
  localStorage.removeItem("selectedcheckdworkflow");
  localStorage.removeItem("selectedcheckdstudy");
  localStorage.removeItem("selectedcheckdmaterial");
  localStorage.removeItem("selectedcheckdfile");
  localStorage.removeItem("selectedcheckddataset");
  localStorage.removeItem("selectedcheckdstatus");
  localStorage.removeItem("partnerlistsession");
  localStorage.removeItem("workflowlistsession");
  localStorage.removeItem("studylistsession");
  localStorage.removeItem("materiallistsession");
  localStorage.removeItem("filelistsession");
  localStorage.removeItem("datasetlistsession");
  localStorage.removeItem("statuslistsession");
  // console.log("blockData", blockData);

  // console.log('selected role')
  const defineSrc = (icon) => {
    switch (icon) {
      case "data_mapping":
        return data_mapping;
      case "template_logo":
        return template_logo;
      case "approval_logo":
        return approval_logo;
      case "partner_logo":
        return partner_logo;
      case "upload":
        return file_upload;
      case "check-square-o":
        return approval_file;
      case "question-circle":
        return resources;
      case "user-plus":
        return invite_user;
      case "list":
        return approval_queue;
      case "line-chart":
        return visualisation;
      default:
        return "";
    }
  };
  return (
    <React.Fragment>
      <div className={`container-fluid pt-3 pb-3 landingupbackground`}>
        <div className="row p-0  w-75">
          <div className="ml-3 col-10 text-left  ">
            <div className="landingheader1 pb-1">
              <p className="pb-1 mt-4">
                Welcome to the{" "}
                <span className="highlightDPC">
                  Digital Partner Collaboration{" "}
                </span>
                Portal
              </p>
            </div>
            <div className="detailsDPC">
              <p className="detailsPara ml-1">
                The Digital Partner Collaboration (DPC) system enables Amgen to receive data from partners.  
                {/* The Digital Partner Collaboration (DPC) system enables Amgen to
                receive data from partners through automating processes and data
                flow. */}
                {/* A partner organization will maintain their original
                record, and transcribe a copy of their data into the DPC
                template, which is then uploaded into the DPC system. This
                uploaded data is viewable to the approvers of the partner
                organization. Once the data is fully approved, data verified,
                and e-signed by the partner organization, it is available to
                Amgen. For detailed insights, please click on the tabs below. */}
              </p>
            </div>
            {/* <div className="row detailsDPC mb-2">
            <div className="col-4 rightBorder">
              RESPECTED
              <div className="subDetail mt-2">
                Lores ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
            </div>
            <div className="col-4 rightBorder">
              HEARD
              <div className="subDetail mt-2">
                Lores ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
            </div>
            <div className="col-4">
              VALUED
              <div className="subDetail mt-2">
                Lores ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      {/* <ApprovalMessage /> */}
      <div className={`container-fluid pt-2 landingbackgroundbodylandingdown`}>
        <div className="row justify-content-center">

          {/* <div class="card newlandingbox  landboxbody" style={{width:'300px'}}>
          <div className="card-body d-flex flex-column">
            
            <div class="card-img-overlay">
              <h5 class="card-title newlandingtitle pb-2">Apprisal</h5>
              <img class="card-img newlandingboximg1" src={upload} alt="Card image" style={{height:'27vh'}}/>
            </div>
            </div>
            <div className="cardBacknewlanding">
              <h5 class="card-title newlandingtitlehover pb-2">Apprisal</h5>
             
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              
            </div>
          </div> */}




          {blockData?.length > 0 &&
            blockData.map((block) => {
              return (
                <div
                  className="col-sm-12 px-4 pt-2 col-md-6 col-lg-3  mobilelandinggrid "
                  key={block.key}
                >
                  <div className="card landboxbody">
                    <div className="card-body d-flex flex-column">
                      <img
                        src={defineSrc(block.icon)}
                        className="card-img-top pb-2 imgheight"
                        alt="logo"
                      />
                      <div
                        className={`card-text pb-2 justify-content-lg-between  cardtextformat`}
                      >
                        {block.label}
                      </div>
                      <p className="{`card-text mediumtextformatlanding justify-content-lg-between`}">
                        {block.description
                          ? block.description.replace(/\\n/g, " ")
                          : ""}
                      </p>
                      <div className="text-center fixOpenButtonPosition mt-auto">
                        <Link to={block.key}>
                          <button className="btn borderbuttonlanding text-center text-wrap fw-normal ">
                            Open
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                 


                </div>
              );
            })}
          {/* <div className="card-body d-flex flex-column">
                      <img
                        src=""
                        className="card-img-top pb-2 imgheight"
                        alt="logo"
                      />
                      <div
                        className={`card-text pb-2 justify-content-lg-between  cardtextformat`}
                      >
                       Visualisation
                      </div>
                      <p className="{`card-text mediumtextformatlanding justify-content-lg-between`}">
                        visualisation
                      </p>
                      <div className="text-center fixOpenButtonPosition mt-auto">
                        <Link to="/visualisation">
                          <button className="btn borderbuttonlanding text-center text-wrap fw-normal ">
                            Open
                          </button>
                        </Link>
                      </div>
                    </div> */}
        </div>
      </div>

      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default Landing;
