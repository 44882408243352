import React, { useState,useContext } from "react";
import Modal from "react-modal";

import { BiLoaderAlt } from "react-icons/bi";

export default function VideoPopup(props) {
  const { isOpen, toggleIsVideoPopupOpen, videoWidth, videoHeight,videoURL } = props;

  const [videoLoading, setVideoLoading] = useState(true);

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };
  const onVidClose = () => {
    toggleIsVideoPopupOpen(false);
    setVideoLoading(true);
  };
  const modalStyle = {
    overlay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.8)",
      zIndex: 1000,
    },
    content: {
      position: "relative",
      top: "25px",
      left: "auto",
      right: "auto",
      bottom: "auto",
      transform: "none",
      background: "transparent",
      border: "none",
      padding: "o",
    },
  };

  const videoContainerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  const videoStyle = {
    width: videoWidth,
    height: videoHeight,
    borderTop: "30px solid black",
    boxSizing: "border-box",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: 5,
    cursor: "pointer",
    color: "white",
    background: "transparent",
    border: "none",
    fontSize: "16px",
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onVidClose}
      contentLabel="Video Popup"
      style={modalStyle}
      ariaHideApp={false}
    >
      <button onClick={onVidClose} style={closeButtonStyle}>
        Close
      </button>
    <div>
        {videoLoading ? (
            <div className="modal__spinner">
                <BiLoaderAlt className="modal__spinner-style" fadeIn="none" />
            </div>
        ) : null}
        <iframe
            title="Video Player"
            style={videoStyle}
            src={videoURL}
            onLoad={spinner}
            loading="lazy"
            frameborder="2"
            allow="autoplay; encrypted-media; picture-in-picture"
            allowfullscreen="true"
            webkitallowfullscreen="true" 
            mozallowfullscreen="true"
        ></iframe>
    </div>
      {/* <video controls autoPlay style={videoStyle}>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
    </Modal>
  );
}
