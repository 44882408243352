var UserProfile = (function() {
    var selectedrole = "";
  
    var getRole = function() {
      return selectedrole;    // Or pull this from cookie/localStorage
    };
  
    var setRole = function(role) {
        selectedrole = role;   
      // Also set this in cookie/localStorage
    };
  
    return {
      getRole: getRole,
      setRole: setRole
    }
  
  })();
  
  export default UserProfile;