import React, {
    useState,
    useContext,
    useEffect,
} from "react";

import '../Visualisation/Visualisation.css';
import { fetchStatusView, statusUpdate, downloadFile } from "../Services/UserServices";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLineChart, faListUl } from '@fortawesome/free-solid-svg-icons';

import Basicfilter from "./Basicfilter";
import Visualisation from "./Visualisation";
const CommonTab = (props) => {

    const [showbasicfilter, setshowbasicfilter] = useState('false');
    const [Advancefilterdata, setAdvancefilterdata] = useState([])
    const [tabIndex, setTabIndex] = useState(0);

    const childcallback = (showbasicfilter, data) => {
        // console.log('klklklklkl'+showbasicfilter)
        //console.log(data)
        //console.log('showbasicfilter' + showbasicfilter)
        if ('result' in data && data['status'] === 'success') {


            var filtervalues = data?.['result']['filterValues'];
            setshowbasicfilter(showbasicfilter)
            setAdvancefilterdata(data)
            setTabIndex(1)
            // if (Array.isArray(filtervalues)) {
            //     console.log('body is an array');
            //     // Initialize an empty object to hold the unique values for each key
            //     var uniqueFilterValues = {};

            //     // Iterate over each item in the filtervalues array
            //     filtervalues.forEach(item => {
            //         // For each key in the item
            //         Object.keys(item).forEach(key => {
            //             // If the key does not exist in the uniqueFilterValues object, initialize it with an empty Set
            //             if (!uniqueFilterValues[key]) {
            //                 uniqueFilterValues[key] = new Set();
            //             }
            //             // Add the value to the Set to ensure uniqueness
            //             uniqueFilterValues[key].add(item[key]);
            //         });
            //     });

            //     // Convert the Sets to Arrays and assign to filtervalues
            //     var filtervalues = {};
            //     Object.keys(uniqueFilterValues).forEach(key => {
            //         filtervalues[key] = Array.from(uniqueFilterValues[key]);
            //     });
            //     setshowbasicfilter(showbasicfilter)
            //     delete data['result']['filterValues']
            //     data['result']['filterValues'] = filtervalues
            //     setAdvancefilterdata(data)
            //     setTabIndex(1)
            // } else if (typeof filtervalues === 'object') {
            //     console.log('body is an object');
            //     setshowbasicfilter(showbasicfilter)
            //     setAdvancefilterdata(data)
            //     setTabIndex(1)
            // } 
            // else {
            //     console.log('body is neither an array nor an object');
            //     setshowbasicfilter(false)
            //     setTabIndex(0)
            // }

        }
        if ('result' in data && data['status'] === 'failed') {
            setshowbasicfilter(false)
            setTabIndex(0)
        }


    }
    let onSelect = (index, lastIndex, event) => {
        //console.log(index)
        // console.log(lastIndex)
        // console.log(event.target.innerText)
        setTabIndex(index)
        //setPartnerForm({...partnerform ,linktemplate:selectedList});

    }
    return (
        <div className='row '>
            <div className='col-12'>
                <div className={` mediumtextformat  p-2 `}>
                    <div className='insideVisialisation p-1'>

                        <Tabs selectedIndex={tabIndex} onSelect={onSelect}>
                            <TabList>
                                {/* disabled={showbasicfilter === 'false' ? true : null} */}
                                <Tab ><FontAwesomeIcon icon={faListUl} /> Basic Filters</Tab>
                                <Tab disabled={localStorage.getItem('showbasicfilter') === 'false' ? true : null}><FontAwesomeIcon icon={faLineChart} /> Visualization</Tab>
                            </TabList>
                            <TabPanel>
                                <Basicfilter parentCallback={childcallback} />
                            </TabPanel>
                            <TabPanel>
                                <Visualisation Advancefilterdata={Advancefilterdata} />
                            </TabPanel>
                        </Tabs>
                    </div>

                </div>
            </div>
        </div>

    )

}
export default CommonTab;