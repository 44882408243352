//import logo from './logo.svg';
import "./App.css";
//import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";

import Landing from "./Components/Accounts/Landing";
import Addtemplate from "./Components/Template/Addtemplate";
import Edittemplate from "./Components/Template/Edittemplate";
import Linktemplate from "./Components/Template/Linktemplate";
import Partners from "./Components/Partners/Partners";
import Approval from "./Components/Approval/Tabsection";
import { useEffect, useState } from "react";
import queryString from "query-string";
import auth from "./Components/auth.js";
import userContext from "./user-context";
import { REACT_APP_REDIRECT_URL_ENV, REACT_APP_URL_ENV } from "./Endpoint";
import NoPage from "./Components/Accounts/NoPage";
import Banner from "./Components/Banner/Banner.jsx";
import {
  fetchEsignURL,
  fetchUserDetails,
  fetchUserRoles,
  getUserDetails,
  setSOPFLag,
} from "./Components/Services/UserServices.js";
import Loader from "./Components/Loader.jsx";
import FileUpload from "./Components/FileUpload/FileUpload.jsx";
import AddNewUser from "./Components/AddNewUser/AddNewUser.jsx";
import FileApprovalStatus from "./Components/FileApproval/FileApprovalStatus.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import Resources from "./Components/Resources/Resources.jsx";
import FileApprovalAction from "./Components/FileApproval/FileApprovalAction.jsx";
import Tabcommon from "./Components/FileApproval/Tabcommon.jsx";
import CommonTab from "./Components/Visualisation/Commontab.jsx";
import DisclaimerSection from "./Components/Disclaimer/DisclaimerSection.jsx";
import Footer from "./Components/Accounts/Footer.jsx";
import ChatBot from "./Components/CommonComponents/ChatBot/ChatBot.jsx";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

//import { REACT_APP_REDIRECT_URL_ENV, REACT_APP_URL_ENV  } from './constants';
function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userID, setuserID] = useState("");
  const [blockData, setBlockData] = useState(null);
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  //console.log(userdetailsokta)
  const [userroles, setUserRoles] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [sopCompletionFlag, setSopCompletionFlag] = useState(false);
  const [showDisclaimer, toggleDisclaimer] = useState(false);
  const [IsUserValid, setIsUserValid] = useState(false);
  const [esignURL, setEsignURL] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  //const env = process.env.REACT_APP_ENV.trim();
  // let env = environment;
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_ENV);
  //let env = 'dev'
  let env = process.env.REACT_APP_ENV.trim();

  const LOCALSTORAGE_TOKEN_FIELD = "Dib_auth_token_new_2_" + env;

  // const REACT_APP_REDIRECT_URL = 'https://dpc-admin-dev.auth.us-west-2.amazoncognito.com/login?client_id=4rjnkh3nm68109cd52d421d4c&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.dpcadmin-dev.amgen.com%2F';

  // const REACT_APP_URL = 'http://www.dpcadmin-dev.amgen.com/';
  const REACT_APP_REDIRECT_URL = REACT_APP_REDIRECT_URL_ENV[env];

  const REACT_APP_URL = REACT_APP_URL_ENV[env];
  const fetchUserData = (userID) => {
    fetchUserDetails(userID)
      .then((dataObj) => {
        let data = {};
        // console.log(data)
        // var data  = data['data']
        // console.log("User data", data.toString());
        // localStorage.setItem("userDetails",  JSON.stringify(data));
        // localStorage.setItem("role", data?.roles[0]?.role_name);
        // setUser(data);
        // setUserRoles(data?.roles);
        // console.log("User data", data);
        if (dataObj?.data) {
          data = dataObj?.data;
        } else {
          data = dataObj;
        }
        if ("N" === data?.sop_completion_flag) {
          fetchEsignURL()
            .then((response) => {
              //console.log("response", response);
              setEsignURL(response);
            })
            .catch((error) => {
              console.error("There was an error!");
            });
          setLoading(false);
          setUser(data);
          toggleDisclaimer(true);
        } else {
          localStorage.setItem("userDetails", JSON.stringify(data));
          if(localStorage.getItem("role") === null || localStorage.getItem("role") === undefined)
          {
            setSelectedUserRole(data.roles[0].role_name);
            localStorage.setItem("role", data?.roles[0]?.role_name);
          }
          else{
           
            setSelectedUserRole(localStorage.getItem("role"));
          }
          //localStorage.setItem("role", data?.roles[0]?.role_name);
          setUser(data);
          setUserRoles(data?.roles);
          
          //setSelectedUserRole(data.roles[0].role_name);
          fetchUserRolesAPI(data);
        }
      })
      .catch((error) => {
        // toggleDisclaimer(true)
        setSelectedUserRole("");
        setLoading(false);
        console.error("There was an error!");
      });
  };
  const fetchUserRolesAPI = (data) =>
    fetchUserRoles({
      user_id: data.user_id,
      role_id: (localStorage.getItem("role") === null||localStorage.getItem("role") === undefined)?data.roles[0].role_id.toString():getRoleIdByRoleName(localStorage.getItem("role"),data.roles),
      partner_id: data.partner_id.toString(),
    })
      .then((data) => {
        // console.log("Block Data", data);
        setBlockData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was an error!");
      });

function getRoleIdByRoleName(roleName,roles) {
  const role = roles.find(r => r.role_name === roleName);
  return role ? role.role_id.toString() : null;
}
      
  useEffect(() => {
    if (window.opener && authenticated) {
      var approvalpage = window.localStorage.getItem("isapprovalpage");
      if(approvalpage === 'yes'){
        window.localStorage.setItem("eSignApproval", "done");
              window.close();
      }
      else
      {
        setSOPFLag(userID)
        .then((data) => {
          // fetchUserDetails(userID).then((dataObj) => {
          //   let data = {};
          //   if (dataObj?.data) {
          //     data = dataObj?.data;
          //   } else {
          //     data = dataObj;
          //   }
          //   if ("Y" === data?.sop_completion_flag) {
              window.localStorage.setItem("eSign", "done");
              window.close();
          //   }
          // });
        })
        .catch((error) => {
          // toggleDisclaimer(true)
          setSelectedUserRole("");
          setLoading(false);
          console.error("There was an error!");
        });

      }
      
    }
  }, [authenticated, userID]);
  useEffect(() => {
    if(window.localStorage.getItem('timeOut') && window.localStorage.getItem('loginTime')){
      const loginTime = new Date(window.localStorage.getItem('loginTime')*1000);
      const timeOut = new Date(window.localStorage.getItem('timeOut')*1000);
      const currentTime = new Date();
      if(currentTime > timeOut){
        window.localStorage.removeItem("hashToken");
        window.localStorage.removeItem("loginTime");
        window.localStorage.removeItem("timeOut");
        window.open(window.location.origin + "/api/link", "_self");
      }
    }
  });
  // useEffect(() => {
  //   window.onpopstate = (e) => {
  //      console.log('back pressed',e)
  //      console.log('current-page',e.currentTarget.location.pathname)
  //     // if ("/" === e.currentTarget.location.pathname) {
  //     //   window.location.reload();
  //     // }
  //     if(window.localStorage.getItem('id_token')){
  //       window.open(window.location.origin + "/api/link", "_self");
  //     }
  //   };
  //   })
  useEffect(() => {
    const initSSO = async () => {
      console.log("initSSO: start");
      if (env === "Local") {
        //for running code in local env
        const user_ID = "chalde01"; //set your userID
        setuserID(user_ID);
        setAuthenticated(true);
      } else {
        console.log("initSSO: NOT development");
        const token = window.localStorage.getItem("hashToken");
        //console.log("initSSO: do we have a token?", token);
        //console.log("token where?");
        if (token) {
          console.log("initSSO: checking token ");
          const decoded = await auth.verifyToken(token);
          console.log("initSSO: Token decoded:", decoded);
          if (decoded.verified) {
            //console.log("initSSO: Profile is good", decoded.verified);
            const user_ID = decoded.payload.preferred_username.split("@")[0];
            // const user_ID = decoded.payload.email.trim();
            setuserID(user_ID);
            setAuthenticated(true);
            return;
          } else {
            console.log("initSSO: Token invalid - let's try again");
            window.localStorage.removeItem("hashToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
        } else {
          // console.log("window.location.href", window.location.href);
          // console.log("window.location.hash", window.location.hash);
          const params = queryString.parse(window.location.href);
          const parsedHash = queryString.parse(window.location.hash);
          //console.log("parsedHash", parsedHash);
          const idToken = parsedHash["id_token"];
          //console.log("id_token:", idToken);
          if (parsedHash["error"] === "access_denied") {
            console.log("initSSO: Access denied");
            setLoading(false);
            return;
          }
          //console.log("params", params);
          //console.log("params.id_token", params.id_token);
          if (idToken) {
            console.log("initSSO: we have a token parameter");
            window.localStorage.setItem("hashToken", idToken);
            const decoded = await auth.verifyToken(idToken);
            //console.log("initSSO: Access_token decoded:", decoded);
            if (decoded?.verified) {
              console.log("initSSO: Profile is good...");
              const user_ID = decoded.payload.preferred_username.split("@")[0];
              // const user_ID = decoded.payload.email.trim();
              window.localStorage.setItem('loginTime',decoded.payload.iat)
              window.localStorage.setItem('timeOut',decoded.payload.exp)
              setAuthenticated(true);
              setuserID(user_ID);
              // if(window?.openedFromDisclaimer===true){
              //   window.close();
              //   return
              // }
              const targetUrl = REACT_APP_URL;
              const currentOrigin = window.location.origin;
              const targetOrigin = new URL(targetUrl).origin;

              if (currentOrigin === targetOrigin) {
                window.history.replaceState({}, "", targetUrl);
              } else {
                console.error(
                  `Cannot set URL '${targetUrl}' with a different origin '${targetOrigin}' from the current origin '${currentOrigin}'.`
                );
              }
              return;
            }
            console.log("initSSO: getting profile again");
          } else {
            window.open(window.location.origin + "/api/link", "_self");
          }
          console.log("initSSO: are we authenticated?" + authenticated);
        }
      }
    };
    initSSO();
    if (userID) {
      fetchUserData(userID);
    }
  }, [authenticated]);
  useEffect(() => {
    const currentPath = localStorage.getItem("currentPath");
    if (currentPath && currentPath !== "/") {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (location.pathname === "/login/callback") {
      navigate("/");
    }
  }, [location.pathname]);

  // console.log("authenticated", authenticated);
  // console.log("userID", userID);
  // console.log('newwwwww')
  if (authenticated) {
    return (
      <userContext.Provider
        value={{
          userID: userID,
          blockData,
          setBlockData,
          user,
          userroles,
          selectedUserRole,
          setSelectedUserRole,
          loading,
          setLoading,
          fetchUserData,
          esignURL,
          fetchUserRolesAPI,
        }}
      >
        {/* <BrowserRouter> */}
        {loading && <Loader />}

        <Banner />
        {showDisclaimer && (
          <DisclaimerSection
            showDisclaimer={showDisclaimer}
            toggleDisclaimer={(e) => toggleDisclaimer(e)}
            // userID={userID}
          />
        )}
        <div className="screenBody" id="style-3">
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/landing" element={<Landing />} />
            <Route exact path="/login/callback" element={<Landing />} />
            {/* <Route exact path="/addtemplate" element={<Addtemplate />}/>
          <Route path="/edittemplate/:name" element={<Edittemplate />} />
          <Route exact path="/partners" element={<Partners />} />
          <Route exact path="/approvalStatus" element={<Approval />} />
          <Route exact path="/mapping" element={<Linktemplate />} /> */}
            <Route exact path="/upload" element={<FileUpload />} />
            <Route exact path="/newUser" element={<AddNewUser />} />
            <Route exact path="/status" element={<Tabcommon />} />
            <Route exact path="/queue" element={<Tabcommon />} />
            <Route path="/resource" element={<Resources />} />
            <Route exact path="/viz" element={<CommonTab />} />
            <Route path="*" element={<Landing />} />
            <Route exact path="/action" element={<FileApprovalAction />} />
          </Routes>
          {/* <ChatBot/> */}
        </div>
        <Footer />
        {/* </BrowserRouter> */}
      </userContext.Provider>
    );
  } else {
    return (
      <userContext.Provider
        value={{
          userID: userID,
          blockData,
          setBlockData,
          user,
          userroles,
          selectedUserRole,
          setSelectedUserRole,
          loading,
          setLoading,
          fetchUserData,
        }}
      >
        {loading && <Loader />}
        <div>
          <h1>Access Denied</h1>
          <p>
            You do not have access to this application. Please contact the
            administrator for assistance.
          </p>
        </div>
      </userContext.Provider>
    );
  }
}

export default App;
