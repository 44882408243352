import React from "react";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";


import Accounttemplatestyle from '../Template/Addtemplate.module.css';


const ShowfileDetails = ({ showfile, setviewFileDetailsModal, dqmdata }) => {

//console.log(dqmdata)
  //const [show, showDQMtype] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(showfile);
  }, [showfile])


  const handleClose = (e) => {
    //setShow(false);
    //console.log(showfile)
    setviewFileDetailsModal(prev => !prev)

  };

  

 


 


  return (
    <>

      {showfile ? (
        <div
          className={"modal fade bd-example-modal-lg" + (show ? " show d-block" : " d-none")}
          // tabIndex="-1"
          role="dialog"
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            background: "rgb(182 184 202/0.5)",
            //opacity: "0.5",
            paddingTop: "12%",
          }}
        >
          <div className="modal-dialog modal-lg " role="document" style={{maxWidth:'1000px'}}>
            <div className="modal-content" style={{ opacity: "1" }}>
              <div className="modal-header">
               
                <h5 className="modal-title" style={{ fontSize: '14px',wordBreak:'break-word' }}>{dqmdata?.['fileName']}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ">
                

                {/* {errormessage &&
                  <span className={` ${Accounttemplatestyle.mandetoryfield}`}>{errormessage}</span>
                } */}
               	 {errormessage && (
                      <div class="alert alert-danger" role="alert">
                        <i className="fa fa-exclamation-circle mr-2" style={{color:'red',fontSize:'19px'}}></i>{errormessage}
                      </div>
                  )}
                
                <div className="row insideApproval p-3">
                 
                    <div className="col-6 linkdec mediumtextformat">Template Name</div>
                    <div className="col-6  mediumtextformatlanding text-left">{dqmdata?.['templateName']}</div>
                  
                </div>
                <div className="row insideApproval p-3">
                 
                    <div className="col-6 linkdec mediumtextformat">Workflow Type</div>
                    <div className="col-6  mediumtextformatlanding text-left">{dqmdata?.['workflowType']}</div>
                  
                </div>
                <div className="row insideApproval p-3">
                 
                    <div className="col-6 linkdec mediumtextformat">Program ID</div>
                    <div className="col-6  mediumtextformatlanding text-left" style={{wordBreak:'break-word'}}>{dqmdata?.['programId']}</div>
                  
                </div>
                <div className="row insideApproval p-3">
                 
                    <div className="col-6 linkdec mediumtextformat">Batch ID / Study ID</div>
                    <div className="col-6  mediumtextformatlanding text-left">{dqmdata?.['studyId']}</div>
                  
                </div>
                <div className="row insideApproval p-3">
                 
                    <div className="col-6 linkdec mediumtextformat">Dataset</div>
                    <div className="col-6  mediumtextformatlanding text-left">{dqmdata?.['dataset']}</div>
                  
                </div>
                <div className="row insideApproval p-3">
                 
                    <div className="col-6 linkdec mediumtextformat">Comments</div>
                    <div className="col-6  mediumtextformatlanding text-left">{dqmdata?.['comments']}</div>
                  
                </div>

              </div>

            </div>
          </div>
        </div>


      ) : null}





    </>
  )


}

export default ShowfileDetails;