import React from  "react";

import Accountstyle from '../Accounts/Account.module.css';
import Accounttemplatestyle from './Addtemplate.module.css';
import * as Icon from 'react-bootstrap-icons';
//import Multiselect from 'multiselect-react-dropdown';
//import {getWorkflows,getPartners,submitTemplate } from "../Services/UserServices";
import {submitTemplate,checkTemplateApproval } from "../Services/UserServices";
import userContext from "../../user-context"
import OpenExcelmodal from './OpenExcelmodal';
// import {Link } from "react-router-dom";

class Forms extends React.Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
          options:[],
        //   selectedValues:[this.props.partners
            
        //     // {
        //     //     cat: 'Group 1',
        //     //     key: 'Option 1'
        //     // },
        //     // {
        //     //     cat: 'Group 1',
        //     //     key: 'Option 2'
        //     // }
              
        //   ],
          selectedValues:[],
          setFormErrorMsg:false,
          setFormIsSuccess:true,
          formErrors: {template_name: '',partners:'', workflow_type: '',temp_acm:'',servererrorMessage:'',check_lock:''},
          template_name:'',
          workflow_type:'',
          partners:[],
          templatevalid:true,
          partnersvalid:true,
          workflowvalid:true,
          check_lockvalid:true,
          dqm:this.props.xlsxheader,
          //tablejson:[],
          //temp_acm:'',
          temp_acm:this.props.isacm,
          acmvalid:true,
          workflowdata:[{'cat':'1','val':'1 Stage non-Quality'},{'cat':'2','val':'2 Stage non-Quality'},
          {'cat':'3','val':'2 Stage Quality'}],
          showdisplayexcel:false,
          templateresponsedataupdate:{},
          isNewTemplate:'',
          fileversion:'',
          isInputChange:this.props.isInputChange,
          userid:{},
          check_lock:'',
          showfinalsubmit:false,
          show:false
        };
        // preserve the initial state in a new object
        this.baseState = this.state 
        
        
        //this.setState({tablejson: this.props.xlsxheader})
    }
    

    componentDidMount()
    {
        let userdetailsokta =  this.context;
        this.setState({userid:userdetailsokta.userID})
        //console.log(userdetailsokta)
       
        // getWorkflows().then((data) => 
        // {
        //     var workflowresponse = {data:[{'cat':'1','val':'Stage 1 Quality'},{'cat':'2','val':'Stage 1 Non Quality'},
        //                          {'cat':'3','val':'Stage 2 Quality'}]}
            
        //     this.setState({workflowdata:workflowresponse.data})
        //     //con'sole.log(this.state.workflowdata)
        // })
        // .catch(error => {
        //     let formErrors = this.state.formErrors;
        //     formErrors.servererrorMessage = error.toString();
        //     this.setState({formErrors:formErrors})
        //     //this.setState({ errorMessage: error.toString() });
        //     console.error('There was an error!', error);
        // });
        // getPartners().then((data) => 
        // {
        //     // var partnerresponse = {data:[{'cat':'AMGEN','val':'AMGEN'},
        //     // {'cat':'PATHEON GRONINGEN','val':'PATHEON GRONINGEN'},
        //     // {'cat':'PATHEON MONZA','val':'PATHEON MONZA'},
        //     // {'cat':'BAXTER','val':'BAXTER'},
        //     // {'cat':'PPD','val':'PPD'},
        //     // {'cat':'FUJI','val':'FUJI'},
        //     // {'cat':'BIOOUTSOURCE','val':'BIOOUTSOURCE'},
        //     // {'cat':'ANTIBODY ANALYTICS','val':'ANTIBODY ANALYTICS'},
        //     // {'cat':'ALCAMI','val':'ALCAMI'},
        //     // {'cat':'SENN','val':'SENN'},
        //     // {'cat':'HELSINN','val':'HELSINN'},
        //     // {'cat':'IDT BIOLOGIKA','val':'IDT BIOLOGIKA'},
        //     // {'cat':'IBI','val':'IBI'},
        //     // {'cat':'FIS','val':'FIS'},
        //     // {'cat':'EUROFINS LANCASTER LAB','val':'EUROFINS LANCASTER LAB'},
        //     // {'cat':'PATHEON TORONTO','val':'PATHEON TORONTO'},
        //     // {'cat':'BOEHRINGER INGELHEIM','val':'BOEHRINGER INGELHEIM'},
        //     // {'cat':'ELION','val':'ELION'},
        //     // {'cat':'SAFC','val':'SAFC'},
        //     // {'cat':'PATHEON NORTH CAROLINA','val':'PATHEON NORTH CAROLINA'},
        //     // {'cat':'BIORELIANCE','val':'BIORELIANCE'},
        //     // {'cat':'LONZA','val':'LONZA'},
        //     // {'cat':'ABR LLI','val':'ABR LLI'},
        //     // {'cat':'ABR CLR','val':'ABR CLR'},
        //     // {'cat':'ABR SGS','val':'ABR SGS'},
        //     // {'cat':'BACHEM','val':'BACHEM'},
        //     // {'cat':'BIP','val':'BIP'},
        //     // {'cat':'COVANCE','val':'COVANCE'},
        //     // {'cat':'DSM','val':'DSM'},
        //     // {'cat':'MICROSAFE','val':'MICROSAFE'},
        //     // {'cat':'MOREDUN','val':'MOREDUN'},
        //     // {'cat':'SERVIER','val':'SERVIER'},
        //     // {'cat':'SUMITOMO','val':'SUMITOMO'},
        //     // {'cat':'WEST','val':'WEST'},
        //     // {'cat':'FEDBATCH','val':'FEDBATCH'},
        //     // {'cat':'INSULET','val':'INSULET'},
        //     // {'cat':'SHL','val':'SHL'},
        //     // {'cat':'BUSHU JAPAN','val':'BUSHU JAPAN'},
        //     // {'cat':'PATHEON WHITBY','val':'PATHEON WHITBY'},
        //     // {'cat':'OMPI','val':'OMPI'},
        //     // {'cat':'AMPAC','val':'AMPAC'},
        //     // {'cat':'SUVEN','val':'SUVEN'},
        //     // {'cat':'BD','val':'BD'},
        //     // {'cat':'SAFC VERONA','val':'SAFC VERONA'},
        //     // {'cat':'PATHEON SC','val':'PATHEON SC'},
        //     // {'cat':'PATHEON_TRO','val':'PATHEON_TRO'},
        //     // {'cat':'FLEXTRONICS INTERNATIONAL USA INC','val':'FLEXTRONICS INTERNATIONAL USA INC'},
        //     // {'cat':'LONZA_VISP','val':'LONZA_VISP'}]}
            
        //     var partnerresponse = data;
        //     this.setState({options:partnerresponse})
        //     //console.log(this.state.options)
        // })
        // .catch(error => {
        //     console.log(error)
        //     let formErrors = this.state.formErrors;
        //     formErrors.servererrorMessage = error.toString();
        //     //this.setState({ errorMessage: error.toString() });
        //     this.setState({formErrors:formErrors})
        //     console.error('There was an error!', error);
        // });
           
    }

    // componentDidUpdate()
    // {

    //     console.log('componentDidUpdate()')
    //     let formErrors = this.state.formErrors;
    //     formErrors.servererrorMessage = ''
    //     this.setState({workflow_type:''})
    //     this.setState({formErrors:formErrors})
    // }

    handleUserInput = (e)=>{
    let name = e.target.name;
    let value = e.target.value;
    //this.setState({[name]: value},
    //               () => { this.validateFormField() });
    this.setState({isInputChange:true})
    if(e.target.name === "check_lock")
    {
        value = e.target.checked
    }
    this.setState({[name]: value});
    }

    onSelect(selectedList,selectedItem)
    {
        //console.log(selectedList)
        //this.state.partners.push(selectedList)
        this.setState({partners:selectedList})
        //this.state.partners = selectedList
       //console.log(this.state.partners)
    }
    onRemove(selectedList, removedItem) {
        
        //console.log(selectedList)
        //this.state.partners.push(selectedList)
        // if(this.state.partners.length>0)
        // {
        //     this.state.partners =[]
        //     this.state.partners = selectedList
        // }
        this.setState({partners:selectedList})
        //this.state.partners = selectedList
        //console.log(this.state.partners)
    }

    // validateFormField(fieldName, value) {


    // }

    addTemplate = (e)=> {
        //console.log(e)
        this.validateFormField()
        if(this.state.formErrors['workflow_type'] === '' && this.state.formErrors['check_lock']  === '')
        {
           let filename =  this.TemplateName(this.props.templatename?this.props.templatename:this.props.templateresponsedata['template_name'])
            checkTemplateApproval(filename).then((datas) => 
            {
                if(datas === true)
                {

                    let formErrors = this.state.formErrors;
                    formErrors.servererrorMessage = "Request cannot be submitted as another request for the same template is already under approval";
                    this.setState({formErrors:formErrors})
                }
                else
                {
                    // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
                    // this.setState({showdisplayexcel:true})
                    // this.setState({show:true})
                    this.submitTemplateApi();
                    // if(this.state.showfinalsubmit)
                    // {
                    //     console.log(this.state.showfinalsubmit)
                    //     //this.submitTemplateApi();
                    // }
                    
                }
            
           
            })
            .catch(error => {
                let formErrors = this.state.formErrors;
                formErrors.servererrorMessage = error.toString();
                this.setState({formErrors:formErrors})
               // console.error('There was an error!', error);

            });
            
        }
        
        // if(this.state.setFormIsSuccess)
        // {
        //     this.submitTemplateApi();
        // }
       
        //console.log(this.state.setFormErrorMsg)
        e.preventDefault()
    }

    submitTemplateApi()
    {
     //console.log(this.state)
        //console.log(this.props)
       const formData = new FormData();
       formData.append("file", this.props.formtemplate);
       
       
       let payload = {
        partners:this.state.partners,
        //acmvalid:this.state.acmvalid,
        isACM:this.state.temp_acm,
        request_raised_by: this.state.userid,
        template_id:this.state.isNewTemplate?"":this.props.templateId,
        template_name:this.TemplateName(this.props.templatename?this.props.templatename:this.props.templateresponsedata['template_name']),
        workflow_type:this.state.workflow_type?this.state.workflow_type:this.props.templateresponsedata['workflow_type'],
        //dqm:this.state.dqm,
        //isNewTemplate:this.state.isNewTemplate ,
        //fileVersion:this.state.fileversion
        isNewTemplate:this.state.isNewTemplate? 'YES':'NO',
        fileVersion:this.state.fileversion?this.state.fileversion:this.props.fileversion,
        dqm:this.state.dqm?this.state.dqm:this.props.templateresponsedata['dqm'],
        //templatename:this.props.templatename,
        //fulltemplate:this.props.fulltemplate,
        //template_name:this.props.templatename?this.props.templatename:this.props.templateresponsedata['template_name'],
        templatename_file:this.props.fulltemplate?this.props.fulltemplate:this.props.templateresponsedata['templatename'],
        fileName:this.props.templatename?this.props.templatename:this.props.templateresponsedata['template_name'],
       }
       formData.append('inputs',JSON.stringify( payload));
       
       //console.log(payload)
       //return false
    //    //console.log(formData.get('file'))
        submitTemplate(formData,payload).then((data) => 
        {
          //console.log(data)
          
          this.successMassage(data.response)
          this.setState({show:false})
            
        })
        .catch(error => {
            this.setState({show:false})
            let formErrors = this.state.formErrors;
            formErrors.servererrorMessage = error.toString();
            this.setState({formErrors:formErrors})
            //this.setState({ errorMessage: error.toString() });
            //console.error('There was an error!', error);
        });
    }

    TemplateName =(name)=>
    {
       // console.log(name)
        if(name !== undefined)
        {
            //console.log(name)
            return name.split('.xlsx')[0]
        }
    }

    validateFormField =() =>{

        let formErrors = this.state.formErrors;
        // if(this.state.template_name === '')
        // {
        //     formErrors.template_name = "Template name required"
        //     this.setState({setFormErrorMsg:true})
        //     this.setState({templatevalid:false})
        //     ////this.state.templatevalid = false;
        // }
        // else{
        //     formErrors.template_name = ""
        //     this.setState({setFormErrorMsg:false})
        //     this.setState({templatevalid:true})
        //     //this.state.templatevalid = true;
        // }



        
        // if(this.state.partners.length === 0)
        // {
        //     formErrors.partners = "Partners required"
        //     this.setState({setFormErrorMsg:true})
        //     this.setState({partnersvalid:false})
        //    // this.state.partnersvalid = false;
        // }
        // else{
        //     formErrors.partners = ""
        //     this.setState({setFormErrorMsg:false})
        //     this.setState({partnersvalid:true})
        //     //this.state.partnersvalid = true;
        // }
        if(this.props.isNewTemplate==='true')
        {
            if(this.state.workflow_type === '')
            {
                formErrors.workflow_type = "Workflow Type  required"
                this.setState({setFormErrorMsg:true})
                this.setState({workflowvalid:false})
                //this.state.workflowvalid = false;
            }
            else{
                formErrors.workflow_type = ""
                this.setState({setFormErrorMsg:false})
                this.setState({workflowvalid:true})
                //this.state.workflowvalid = true;
            }
            if(this.state.check_lock === false || this.state.check_lock === "")
            {
                formErrors.check_lock = "Please select checkbox";
                this.setState({setFormErrorMsg:true})
                this.setState({check_lockvalid:false})
            }
            else
            {
                formErrors.check_lock = "";
                this.setState({setFormErrorMsg:false})
                this.setState({check_lockvalid:true})
            }

        }
        else
        {
            if(Object.keys(this.props.templateresponsedata).length>0)
            {
                if(this.props.templateresponsedata['workflow_type']!==''||this.state.workflow_type!=='')
                {
                    formErrors.workflow_type = ""
                    this.setState({setFormErrorMsg:false})
                    this.setState({workflowvalid:true})
                    
                }
                else
                {
                    formErrors.workflow_type = "Workflow Type  required"
                    this.setState({setFormErrorMsg:true})
                    this.setState({workflowvalid:false})
                    
                }
                if(this.state.check_lock === false || this.state.check_lock === "")
                {
                    formErrors.check_lock = "Please select checkbox";
                    this.setState({setFormErrorMsg:true})
                    this.setState({check_lockvalid:false})
                }
                else
                {
                    formErrors.check_lock = "";
                    this.setState({setFormErrorMsg:false})
                    this.setState({check_lockvalid:true})
                }
            }

        }
        
        if(this.state.temp_acm === '')
        {
            formErrors.temp_acm = "Template ACM  required"
            this.setState({setFormErrorMsg:true})
            this.setState({acmvalid:false})
            //this.state.acmvalid = false;
        }
        else
        {
            formErrors.temp_acm = ""
            this.setState({setFormErrorMsg:false})
            this.setState({acmvalid:true})
            //this.state.acmvalid = true;
        }
        this.setState({formErrors:formErrors})
        //this.state.setFormErrorMsg = !this.state.workflowvalid || !this.state.partnersvalid ||  !this.state.templatevalid ? true :false;
        this.state.setFormErrorMsg = !this.state.workflowvalid  ||  !this.state.templatevalid ? true :false;
        
        // this.state.setFormIsSuccess = this.state.workflowvalid && this.state.templatevalid
        // console.log(this.state)

    }

    
    

    errorClass(error) {
        return(error  ? '' : 'is-invalid');
    }


    componentWillReceiveProps(nextprops)
    {
        //console.log(nextprops)
        let formErrors = this.state.formErrors;
        formErrors.servererrorMessage = '';
        console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
        this.setState({dqm: nextprops.xlsxheader,temp_acm:nextprops.isacm,fileversion:(nextprops.fileversion==='')?'1':nextprops.fileversion,
            isNewTemplate:(nextprops.existing_template===true)?"NO":"YES",isInputChange:false

           // templateresponsedataupdate:nextprops.templateresponsedata
           
        })
        if(nextprops.templateresponsedata && nextprops.isNewTemplate==='false' )
        {
            this.setState({ workflow_type:nextprops.templateresponsedata['workflow_type'],
            temp_acm:nextprops.templateresponsedata['isACM'],
            template_name:nextprops.templateresponsedata['template_name'],
            selectedValues:nextprops.templateresponsedata['partners'],
            dqm:nextprops.templateresponsedata['dqm'],
            templateresponsedataupdate:nextprops.templateresponsedata,
           // partners:nextprops.templateresponsedata['partners']
        })
         
        }
    }
    onChangeInput(e,colname,index)
    {
        
        const { name, value } = e.target
        
        let data;
        //  console.log(this.props)
        //  console.log(index)
        //  console.log(e.target.name)
        //  console.log(e.target.value)
        if(this.props.xlsxheader)
        {
             data = this.props.xlsxheader
        }
        
        if(this.props.templateresponsedata)
        {
            if(Object.keys(this.props.templateresponsedata).length>0)
            {
                data =this.props.templateresponsedata['dqm']
                
            }


        }
        //console.log(data)
        data[index][e.target.name] = e.target.value;
        if(e.target.name === 'datatype')
        {
            if(e.target.value === 'String')
            {
                data[index]['datatypecheck'] = 'false'
                document.getElementById(index+'_'+colname).disabled = true;
            }
            else
            {
                data[index]['datatypecheck'] = 'true'
                document.getElementById(index+'_'+colname).disabled = true;
            }
        }
        //console.log(data[index][e.target.name])
        //this.setState({data[i][colname]: value});
        this.setState({dqm: this.props.xlsxheader});
        data.forEach((item,i)=>{
          // console.log(i)
            if(i === index)
            {
                ////console.log(i)
                //console.log(item[e.target.name])
               item[e.target.name] = e.target.value
            }
        })

        this.setState({dqm: data})
        this.setState({isInputChange:true})
    }

    
 

    resetForm=()=>{
        
        if(this.props.isNewTemplate==='false')
        {
            document.getElementsByClassName("editblock")[0].style.display = "none";
            document.getElementsByClassName("templateupload")[0].style.display = "block";
            document.getElementsByClassName("hiddentext")[0].style.display = "inline-flex";
            document.getElementsByClassName("hiddentextnote")[0].style.display = "block";
            const file =document.querySelector('.file');
            file.value = '';
        }
        this.setState(this.baseState)
        this.setState({setFormIsSuccess:false,dqm:[],templateresponsedataupdate:{}})
        document.getElementsByClassName("hiddentext")[0].style.display = "inline-flex";
        document.getElementsByClassName("hiddentextnote")[0].style.display = "block";
        this.props.parentCallback('reset');
        //console.log(this.state)
    }
    successMassage=(data)=>{
        // console.log(this.props)
        // console.log(data)
        if(this.props.isNewTemplate==='false')
        {
            console.log('edittttttt')
            document.getElementsByClassName("editblock")[0].style.display = "none";
            document.getElementsByClassName("templateupload")[0].style.display = "block";
            document.getElementsByClassName("hiddentext")[0].style.display = "inline-flex";
            document.getElementsByClassName("hiddentextnote")[0].style.display = "block";
            
            //this.props.parentCallback(data);
            const file =document.querySelector('.file');
            file.value = '';
            document.getElementsByClassName("editsuccesstext")[0].style.display = "block";
            document.getElementsByClassName("textedi")[0].innerHTML="Template has been submitted for approval. Request number is "+data
            
            
            //this.props.parentCallback(data);
        }
        document.getElementsByClassName("hiddentext")[0].style.display = "inline-flex";
        document.getElementsByClassName("hiddentextnote")[0].style.display = "block";
        this.setState(this.baseState)
        this.setState({setFormIsSuccess:true,dqm:[],templateresponsedataupdate:{}})
        this.props.parentCallback(data);
      
        //console.log(this.state)
    }

    resetModalCallback=(data)=>{
        //console.log(data)
        if(data)
        {
            this.setState({showfinalsubmit:data})
        }
        else{
            this.setState({showdisplayexcel:data})
        }
        

    }

    handleClose = () => {
        this.setState({show:false})
       // setShow(false);
    };
    handleShow = () =>{
        this.setState({show:true})
        this.submitTemplateApi();
        //setShow(true);
       
    } ;
      

    

    render() {
        // console.log('props')
        // console.log(this.props)
        // console.log('state')
        // console.log(this.state)
        // console.log(Object.keys(this.props.templateresponsedata).length)
        //console.log(this.state.formErrors)
        //console.log(Object.keys(this.state.templateresponsedataupdate).length)
        //  let showexcelModal;
        // if (this.state.showdisplayexcel)
        // {
        //     showexcelModal = <OpenExcelmodal parentModalCallback={this.resetModalCallback} statevalue={this.state} propsvalue={this.props}/> 
        // }
        return(
           <>
           {this.state.showdisplayexcel &&

                <div className={"modal fade" + (this.state.show ? " show d-block" : " d-none")} tabIndex="-1" role="dialog" style={{'paddingTop':'12%'}} >
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Data Type String allows all characters. Selected by default.(e.g. abc123&)</p>
                        <p>Data Type  Int allows only Integer ( e.g. 1,2,etc)</p>
                        <p>Data Type  Double allows decimal value with 2 precision ( e.g. 1.98)</p>
                        <p>Data Type  Double allows decimal value with 2 precision ( e.g. 1.98)</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.handleShow}>Ok</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"  onClick={this.handleClose}>Close</button>
                    </div>
                    </div>
                </div>
                </div>

           }
           {/* {showexcelModal} */}
            <form onSubmit={this.addTemplate} encType="multipart/form-data" >
               
                {/* <div className="col-sm-3  text-start mobileview"><label htmlFor="exampleFormControlInput1" className="form-label  text-right pe-4 globaltextformat"><span className={Accounttemplatestyle.mandetoryfield}>*</span>Template Name</label></div>
                <div className={`input-group ${this.state.templatevalid?'mb-3':'mb-2'}`}>
                    <label htmlFor="exampleFormControlInput1" className="col-form-label col-sm-3 desktopview text-right pe-4 globaltextformat"><span className={Accounttemplatestyle.mandetoryfield}>*</span>Template Name</label>
                    <span className="input-group-text" id="basic-addon1"><Icon.FileEarmarkCodeFill color="white"/></span>
                    <input type="text" className={` form-control  ${this.errorClass(this.state.templatevalid)}`} placeholder="Template Name" aria-label="Amgen" aria-describedby="basic-addon1" disabled name="template_name" value={this.state.template_name}  onChange={this.handleUserInput}/>
                </div>
                
                {this.state.formErrors["template_name"] && (<p className={`mb-2 globaltextformat text-right  ${Accounttemplatestyle.mandetoryfield}`}>{this.state.formErrors["template_name"]}</p>)} */}

                
              
                {/* <div className={`input-group ${this.state.templatevalid?'mb-3':'mb-2'}`}>
                    <label htmlFor="exampleFormControlInput1" className="col-form-label col-sm-3 desktopview text-right pe-4 globaltextformat"><span className={Accounttemplatestyle.mandetoryfield}>*</span>Template Name</label>
                    <div className="form-control">{this.props.templatename?this.props.templatename:this.props.templateresponsedata['template_name']}</div>
                    
                </div> */}
                <div className="row">
                 <div className = "col-sm-4 pr-0">
                    <div className="col-sm-3   text-start mobileview"><label htmlFor="exampleFormControlInput1" className="form-label  text-right pe-4 globaltextformat"><span className={Accounttemplatestyle.mandetoryfield}>*</span>Template Name</label></div>
                    <div className={`input-group  ${this.state.templatevalid?'mb-1':'mb-2'}`}>
                        <label htmlFor="exampleFormControlInput1" className="col-form-label col-sm-3 desktopview text-right pe-4 px-2 globaltextformat" style={{fontSize:'12px'}}><span className={Accounttemplatestyle.mandetoryfield+' mr-1'}>*</span>Template Name</label>
                        <span className="input-group-text" id="basic-addon1"><Icon.FileEarmarkCodeFill color="white"/></span>
                        <input type="text" className=" form-control" placeholder="Template Name" aria-label="Amgen" aria-describedby="basic-addon1" disabled
                        name="template_name" value={this.props.templatename?this.props.templatename:this.props.templateresponsedata['template_name']}  onChange={this.handleUserInput}/>
                    </div>
                    {this.state.formErrors["template_name"] && (<p className={`mb-2 globaltextformat text-right  ${Accounttemplatestyle.mandetoryfield}`}>{this.state.formErrors["template_name"]}</p>)} 

                 </div>
                 
                
                <div className = "col-sm-4 px-0">
                   <div className="col-sm-3  text-start mobileview"><label htmlFor="exampleFormControlInput1" className="form-label text-right pe-4 globaltextformat "><span className={Accounttemplatestyle.mandetoryfield}>*</span>Workflow Type</label></div>
                   <div className={`input-group   ${this.state.templatevalid?'mb-1':'mb-2'}`} style={{zIndex:1}}>
                       <label htmlFor="exampleFormControlInput1" className="col-form-label col-sm-3 text-right pe-4 px-2 globaltextformat desktopview"  style={{fontSize:'12px'}}><span className={Accounttemplatestyle.mandetoryfield+' mr-1'}>*</span>Workflow Type</label>
                       <span className="input-group-text" id="basic-addon1"><Icon.FilePlusFill color="white"/></span>
                       <select className={` form-control  ${this.errorClass(this.state.workflowvalid)}`}  aria-label="Select Workflow Type" name="workflow_type" value={this.state.workflow_type?this.state.workflow_type:this.props.templateresponsedata['workflow_type']}  onChange={this.handleUserInput}>
                           {this.state.workflowdata.length>0 && <option value='' >Select Workflow Type</option>}
                           {this.state.workflowdata.length===0 && <option value='' >No option </option>}
                           
                           {this.state.workflowdata.length>0 &&
                           this.state.workflowdata.map(workflow => (
                                   
                                   <option value={workflow.val} key={workflow.cat}>{workflow.val}</option>
                               
                               ))
                           
                           
                           }
                           
                           {/* <option value="1">Stage 1 Quality</option>
                           <option value="2">Stage 1 Non Quality</option>
                           <option value="3">Stage 2 Quality</option> */}
                           
                       </select>
                   </div>
                  
                   {this.state.formErrors["workflow_type"] && (<p className={`mb-2 globaltextformat text-right  ${Accounttemplatestyle.mandetoryfield}`}>{this.state.formErrors["workflow_type"]}</p>)}

                </div> 
                
                <div className = "col-sm-4 pl-0">
                <div className="col-sm-3  text-start mobileview"><label htmlFor="exampleFormControlInput1" className="form-label text-right pe-4 globaltextformat"><span className={Accounttemplatestyle.mandetoryfield}>*</span>Is template ACM</label></div>
                <div className={`input-group   ${this.state.templatevalid?'mb-1':'mb-2'}`} style={{zIndex:1}}>
                    <label htmlFor="exampleFormControlInput1" className="col-form-label col-sm-3 text-right pl-0 pr-2 globaltextformat desktopview" style={{paddingRight:'10px',fontSize:'12px'}}><span className={Accounttemplatestyle.mandetoryfield+' mr-1'}>*</span>Is template ACM</label>
                    <span className="input-group-text" id="basic-addon1"><Icon.FilePlusFill color="white"/></span>
                    <select className={` form-control  ${this.errorClass(this.state.acmvalid)}`}  aria-label="Select Workflow Type" name="temp_acm" value={this.state.temp_acm} disabled  onChange={this.handleUserInput}>
                        {/* <option value="" >Is Template ACM?</option> */}
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                        
                    </select>
                </div>
                {this.state.formErrors["temp_acm"] && (<p className={`mb-2 globaltextformat text-right  ${Accounttemplatestyle.mandetoryfield}`}>{this.state.formErrors["temp_acm"]}</p>)}
            
                
                </div>

                </div>
            
                
                

                {/* <div className="col-sm-3  text-start mobileview"><label htmlFor="exampleFormControlInput1" className="form-label   text-right pe-4 globaltextformat"><span className={Accounttemplatestyle.mandetoryfield}>*</span> Partner(s)</label></div>
                <div className={`input-group ${this.state.templatevalid?'mb-3':'mb-2'}`}>
                    <label htmlFor="exampleFormControlInput1" className="col-form-label col-sm-3 desktopview text-right pe-4 globaltextformat">Partner(s)</label>
                    <span className="input-group-text" id="basic-addon1"><Icon.FilePersonFill color="white"/></span>
                    
                    
                    <div className={` col-sm-8  form-control  ${this.errorClass(this.state.partnersvalid)}`}>
                    <Multiselect emptyRecordMsg="No option to select"
                        displayValue="partner_name"
                        showArrow="true"
                        closeIcon="circle"
                        avoidHighlightFirstOption="true"
                        selectedValues = {this.state.selectedValues}
                        options={this.state.options}
                        showCheckbox
                        placeholder="Select Partner names"
                        className="mb-0 "
                        closeOnSelect="true"
                        //onSelect={this.onSelect}
                        onRemove={(selectedList, removedItem)=>this.onRemove(selectedList, removedItem)} // Function will trigger on remove event
                        onSelect={(selectedList, selectedItem)=>{this.onSelect(selectedList, selectedItem)}}
                        
                    />
                    </div>
                </div> */}
                {this.state.formErrors["partners"] && (<p className={`mb-2 globaltextformat text-right  ${Accounttemplatestyle.mandetoryfield}`}>{this.state.formErrors["partners"]}</p>)}
                
               
                
                {/* <div className={` mb-3 globaltextformat ${Accounttemplatestyle.mandetoryfield}`}>
                    <label htmlFor="exampleFormControlInput1" className="col-sm-3 text-right pe-4 "></label>
                    <span>Field marked with * are mandatory</span>
                </div> */}

                {/* <div className={`mb-3  globaltextformat ${Accounttemplatestyle.mandetoryfield}`}>
                    <label htmlFor="exampleFormControlInput1" className="col-sm-3 text-right pe-4 "></label>
                    
                    <FormErrors errors={this.state.formErrors} setFormErrorMsg={this.state.setFormErrorMsg}/>
                </div> */}
                {/* <div className="mb-3 pt-3">
                <div className="form-group text-center">
                <button type="button" style={{width:'auto' , maxWidth:'fit-content'}} onClick={()=>{this.setState({showdisplayexcel:true})}} className={`btn btn-primary text-wrap fw-normal ${Accountstyle.submit_btn}`}>
                 Display Excel </button>
                </div>
                </div> */}
               
                {this.props.filesuccess &&

                <div className={`mb-3  globaltextformat table-wrapper-scroll-y custom-scrollbar 
                
                `}>
                    <label htmlFor="exampleFormControlInput1" className="col-sm-3 text-right pe-4 "></label>
                    <table className="table table-bordered">
                    <thead style={{background: '#2262A5 0% 0% no-repeat padding-box',color: '#FFFF',fontSize:'12px'}}>
                        <tr className="text-center">
                        <th scope="col" className="text-center">Tab</th>
                        <th scope="col" className="text-center">Column Name</th>
                        <th scope="col" className="text-center">Datatype</th>
                        <th scope="col" className="text-center">Null Check</th>
                        <th scope="col" className="text-center">Datatype Check</th>
                        <th scope="col">Include in visualization</th>
                        <th scope="col">Include X-axis</th>
                        <th scope="col">Include Y-axis</th>
                        <th scope="col">Include in colour by option</th>
                        <th scope="col">Include in shape by option</th>
                        {/* <th scope="col" className="text-center">Display Order</th> */}
                        </tr>
                    </thead>
                    <tbody className="scrollbar" id="style-3">
                        
                        {this.state.dqm.map((item,i)=>
                            (<tr key={i} className="font-size-13">
                                <th scope="row" className="text-center">{item.tabname}</th>
                                <td className="text-center">{item.colname}</td>
                                {/* <td scope="row">{this.state.temp_acm?item.colname:<input className="form-control" name="colname" value={item.colname}  onChange={(e) => this.onChangeInput(e, item.colname,i)}/>}</td> */}
                                <td><select className="form-control"  name="datatype" value={item.datatype} disabled={this.state.temp_acm ? true : null}  onChange={(e) => this.onChangeInput(e, item.colname,i)}>
                                        <option value="String">String</option>
                                        <option value="Int">Int</option>
                                        <option value="Double">Double</option>
                                        <option value="Timestamp">Timestamp</option>
                                        
                                    </select>
                                </td>
                                <td><select className="form-control" name="nullcheck" value={item.nullcheck} disabled={this.state.temp_acm ? true : null}  onChange={(e) => this.onChangeInput(e,item.colname,i)}>
                                        <option value="false">No</option>
                                       <option value="true">Yes</option>
                                       
                                   </select></td>
                                   {/* disabled={ this.state.temp_acm || item.datatype!=='String' ? true : null} */}
                                <td><select className="form-control" id={i+'_'+item.colname} disabled   name="datatypecheck" value={item.datatypecheck}   onChange={(e) => this.onChangeInput(e, item.colname,i)}>
                                        <option value="false">No</option>
                                       <option value="true">Yes</option>
                                       
                                   </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm ? true : null}  onChange={(e) => this.onChangeInput(e, item.colname,i)} name="visualisation_filter" value={item.visualisation_filter}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm ? true : null}   onChange={(e) => this.onChangeInput(e, item.colname,i)} name="xaxis" value={item.xaxis}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm ? true : null}  onChange={(e) => this.onChangeInput(e, item.colname,i)}  name="yaxis" value={item.yaxis}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm ? true : null}  onChange={(e) => this.onChangeInput(e, item.colname,i)} name="colorbyoption" value={item.colorbyoption}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm ? true : null}  onChange={(e) => this.onChangeInput(e, item.colname,i)} name="shapebyoption" value={item.shapebyoption}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>
                                {/* <input type="text" className="form-control" id={i+'_'+item.colname}   onChange={(e) => this.onChangeInput(e, item.colname,i)} name="display_order" value={i+1}></input> */}

                                {/* <td>
                                    <input type="text" className="form-control" id={i+'_'+item.colname}   onChange={(e) => this.onChangeInput(e, item.colname,i)} name="display_order" value={i+1}></input>
                                </td> */}
                            </tr>)
                        

                        )}
                        
                    </tbody>
                    </table>
                </div>
                    /*this.props.xlsxheader.map((i)=><p key={i}>{i}</p>)*/
                }
                 {/* {this.props.isNewTemplate===false && */}
                 {/* Object.keys(this.state.templateresponsedataupdate).length>0 */}

                    {Object.keys(this.props.templateresponsedata).length>0 &&

                    
                      
                    <div className={`mb-3  globaltextformat table-wrapper-scroll-y custom-scrollbar 
                    
                    `}>
                    <label htmlFor="exampleFormControlInput1" className="col-sm-3 text-right pe-4 "></label>
                    {this.props.templateresponsedata['dqm'].length === 0 &&
                    <div style={{textAlign:'center',fontSize:'16px'}}>No DQM Available</div>
                    }
                    {this.props.templateresponsedata['dqm'].length > 0 &&
                    <table className="table table-bordered" >
                        <thead style={{background: '#2262A5 0% 0% no-repeat padding-box',color: '#FFFF',fontSize:'12px'}}>
                            <tr className="text-center">
                            <th scope="col text-center">Tab</th>
                            <th scope="col" className="text-center">Column Name</th>
                            <th scope="col" className="text-center">Datatype</th>
                            <th scope="col" className="text-center">Null Check</th>
                            <th scope="col" className="text-center">Datatype Check</th>
                            <th scope="col">Include in visualization</th>
                            <th scope="col">Include in X-axis</th>
                            <th scope="col">Include in Y-axis</th>
                            <th scope="col">Include in colour by option</th>
                            <th scope="col">Include in shape by option</th>
                            {/* <th scope="col" className="text-center">Display Order</th> */}
                            </tr>
                        </thead>
                        <tbody className="scrollbar" id="style-3">
                            {/* {this.state.dqm.map((item,i)=> */}
                                {/* {this.props.templateresponsedata['dqm'].map((item,i)=> */}
                                {this.props.templateresponsedata['dqm'].map((item,i)=>
                                <tr key={i} className="font-size-13">
                                <th scope="row" className="text-center">{item.tabname}</th>
                                <td className="text-center">{item.colname}</td>
                                
                                {/* <td scope="row">{this.state.temp_acm?item.colname:<input className="form-control" name="colname" value={item.colname}  onChange={(e) => this.onChangeInput(e, item.colname,i)}/>}</td> */}
                                
                                {/* {this.state.temp_acm  ||  item.isdisable &&
                                <td><input type="text" disabled className="form-control before-edit"  name="datatype"  value={item.datatype}  onChange={(e) => this.onChangeInput(e, item.colname,i)}></input></td>
                                } */}
                                {/* {  item.isdisable===false &&  */}
                                <td><select className="form-control"  name="datatype" disabled={this.state.temp_acm  ||  item.isdisable? true : null} value={item.datatype}  onChange={(e) => this.onChangeInput(e, item.colname,i)}>
                                        <option value="String">String</option>
                                        <option value="Int">Int</option>
                                        <option value="Double">Double</option>
                                        <option value="Timestamp">Timestamp</option>
                                        
                                    </select>
                                </td>
                                {/* } */}
                                <td><select className="form-control"  name="nullcheck" value={item.nullcheck} disabled={this.state.temp_acm }  onChange={(e) => this.onChangeInput(e,item.colname,i)}>
                                    
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select></td>
                                <td><select className="form-control" id={i+'_'+item.colname}  name="datatypecheck" disabled value={item.datatypecheck}   onChange={(e) => this.onChangeInput(e, item.colname,i)}>
                                    
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </select></td>
                                <td><select className="form-control" id={i+'_'+item.colname}   onChange={(e) => this.onChangeInput(e, item.colname,i)} disabled={this.state.temp_acm } name="visualisation_filter" value={item.visualisation_filter}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm }  onChange={(e) => this.onChangeInput(e, item.colname,i)} name="xaxis" value={item.xaxis}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm }  onChange={(e) => this.onChangeInput(e, item.colname,i)}  name="yaxis" value={item.yaxis}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname} disabled={this.state.temp_acm }  onChange={(e) => this.onChangeInput(e, item.colname,i)} name="colorbyoption" value={item.colorbyoption}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>

                                <td><select className="form-control" id={i+'_'+item.colname}  disabled={this.state.temp_acm } onChange={(e) => this.onChangeInput(e, item.colname,i)} name="shapebyoption" value={item.shapebyoption}>
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                    
                                </select></td>
                                {/* <td><input type="text" className="form-control" id={i+'_'+item.colname}   onChange={(e) => this.onChangeInput(e, item.colname,i)} name="display_order" value={i+1}></input></td> */}
                            </tr>
                            )}
                        </tbody>
                    </table>
                    }
                    </div>

                    


                    }

                <div className="mb-0 pt-0">
            
                {this.state.formErrors["servererrorMessage"] && (<p className={`mb-2 globaltextformat text-right  ${Accounttemplatestyle.mandetoryfield}`}>{this.state.formErrors["servererrorMessage"]}</p>)} 
   
                    <div className="row">
                        <div className="col-sm-4">
                            <div  className="form-check">
                            <input  onClick={this.handleUserInput} className={`form-check-input ${this.errorClass(this.state.check_lockvalid)}`} type="checkbox" name="check_lock" value="lock" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                            <i>Reviewed and verified that the template(s) are locked</i>
                            </label>
                            </div>
                        </div>
                        <div className="col-sm-5" style={{fontSize:'12px'}}>
                            <ul style={{fontStyle:'italic'}}>
                            <li><i><b>Data Type String</b></i> allows all characters. Selected by default (e.g. abc123&) and a recommended option as it accepts any value at any precision</li>
                            <li><i><b>Data Type Int</b></i> allows only Integer ( e.g. 1,2,etc)</li>
                            <li><i><b>Data Type Double</b></i> allows decimal value with 2 precision ( e.g. 1.98)</li>
                            <li><i><b>Data Type Timestamp</b></i> allow date and timestamp ( e.g. mm/dd/yyyy hh:mm:ss)</li>
                            </ul>
                       
                        </div>
                        
                        <div className="col-sm-3">
                            <div className="form-group text-right">
                            <button type="reset" onClick={this.resetForm} className="btn btn-secondary  text-wrap fw-normal " id="submit-reset" style={{margin: '0 10px',backgroundColor:'#6c757d',color:'white'}}>
                            <Icon.ArrowClockwise /> Reset
                            </button>
                            {/* <input type="submit" name="Login" className={`btn btn-primary btn-md ${Accountstyle.submit_btn}`} value="Submit" />  */}
                            <button type="submit" className={`btn btn-primary text-wrap fw-normal ${Accountstyle.submit_btn}`}  disabled={this.state.isInputChange ||this.state.temp_acm  ? null : true}>
                            <Icon.CheckLg /> Submit
                            </button>
                            
                        </div>
                        </div>
                    </div>
                    
                   
                </div>

                
                
            </form>
            </>
            
        );

    }
  }
  Forms.contextType = userContext;
export default Forms;