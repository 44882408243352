import React, { useEffect, useState, useContext } from "react";
import Accountstyle from "./Account.module.css";
import logo from "../assets/logo-light.png";
import { Link } from "react-router-dom";
import { getUserDetails } from "../Services/UserServices";
import userContext from "../../user-context";
import UserProfile from "../../UserProfile";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
//className={Accountstyle.Lable}
const Header = (props) => {
  //console.log(props)
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [roles, setRoles] = useState([]);
  const userdetailsokta = useContext(userContext);
  //console.log(userdetailsokta)
  const [userroles, setUserRoles] = useState([]);
  const [userselectedroles, setSelectedUserRoles] = useState("");
  const [usersessionselectedroles, setSessionSelectedUserRoles] = useState("");
  var isActive = true;
let userRoles=['Author','Stage 1 File Approver','Stage 2 File Approver Non-Quality','Stage 2 File Approver Quality','SUper USer','Viewer','Sponsor','Template Owner','Stage 1 Template Approver']
  const fetchUserData = (userdetailsokta) => {
    getUserDetails(userdetailsokta.userID)
      .then((data) => {
        setUser(data);
        setUserRoles(data.roles);
        setSelectedUserRoles(data.roles[0]);
        if (
          UserProfile.getRole() === "" &&
          localStorage.getItem("role") === null
        ) {
          localStorage.setItem("role", data.roles[0]);
          props.parentTabCallbackRole(data.roles[0]);
        }

        if (UserProfile.getRole()) {
          setSelectedUserRoles(UserProfile.getRole());
        }
      })
      .catch((error) => {
        console.error("There was an error!");
      });
  };

  let handleRole = (event, role) => {
    setSelectedUserRoles(role);
    setSessionSelectedUserRoles(role);
    UserProfile.setRole(role);
    props.parentTabCallbackRole(role);
    localStorage.setItem("role", role);
    navigate("/");
  };

  useEffect(() => {
    fetchUserData(userdetailsokta);
  }, []);

  return (
    <header className={`${Accountstyle.Header}`}>
      <nav
        className={`navbar navbar-expand-lg navbar-dark ${Accountstyle.headerbackground} fixed-top p-1`}
      >
        <div className="container-fluid row">
          <div className="col-10">
            <div className="row">
              <div className="col-5">
                <Link to="/" className="navbar-brand me-1">
                  <img src={logo} alt="" height="24" />
                </Link>
                <span className={`${Accountstyle.headernamesmall}  pe-2`}>
                  |
                </span>
                <span
                  className={` ${Accountstyle.headernamesmall} mediumtextformat`}
                >
                  Digital Partner Collaboration System
                </span>
              </div>
              <div
                className={`col-7 mediumtextformat ${Accountstyle.navbarmenu}`}
              >
                <ul id="myDiv" className="nav justify-content-end">
                  <Link
                    to="/"
                    id="btn"
                    className={`${
                      window.location.pathname === "/"
                        ? `${Accountstyle.activeTab}`
                        : ""
                    } nav-link ${Accountstyle.headermenufont}`}
                  >
                    Home
                  </Link>
                  {localStorage.getItem("role") !==
                    "Stage 1 Template Approver" && (
                    <Link
                      to="/addtemplate"
                      id="btn"
                      className={`${
                        window.location.pathname === "/addtemplate"
                          ? `${Accountstyle.activeTab}`
                          : ""
                      } nav-link ${Accountstyle.headermenufont}`}
                    >
                      Add/Edit Template
                    </Link>
                  )}
                  <Link
                    to="/approvalStatus"
                    id="btn"
                    className={`${
                      window.location.pathname === "/approvalStatus"
                        ? `${Accountstyle.activeTab}`
                        : ""
                    } nav-link ${Accountstyle.headermenufont}`}
                  >
                    Approval
                  </Link>
                  {localStorage.getItem("role") !==
                    "Stage 1 Template Approver" && (
                    <Link
                      to="/partners"
                      id="btn"
                      className={`${
                        window.location.pathname === "/partners"
                          ? `${Accountstyle.activeTab}`
                          : ""
                      } nav-link ${Accountstyle.headermenufont}`}
                    >
                      Add/Edit Partner
                    </Link>
                  )}
                  <Link
                    to="/mapping"
                    id="btn"
                    className={`${
                      window.location.pathname === "/mapping"
                        ? `${Accountstyle.activeTab}`
                        : ""
                    } nav-link ${Accountstyle.headermenufont}`}
                  >
                    View Data Mapping
                  </Link>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-1 col-2 ">
            <ul className="navbar-nav justify-content-end ml-auto">
              <li className="nav-item">
                <a className="nav-link menu-style name-txt pb-0">
                  {user.firstname ? user.firstname : ""}{" "}
                  {user.lastName ? user.lastName : ""}{" "}
                </a>
                <span
                  style={{
                    fontWeight: "400 !important",
                    fontSize: "11px !important",
                  }}
                  className={`${Accountstyle.righttext} nav-link menu-style name-txt pt-0`}
                >
                  {localStorage.getItem("role")}
                </span>
              </li>

              <li className="nav-item dropdown mobilrdrop">
                <a
                  className={`nav-link dropdown-toggle navbar-brand1 ${Accountstyle.righttext}`}
                  href="#"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className={` `}>
                    <FontAwesomeIcon icon={faUserCircle} size="3x" />
                  </span>
                </a>
                <ul
                  className="dropdown-menu "
                  aria-labelledby="navbarScrollingDropdown"
                  style={{ right: "0px", left: "auto" }}
                >
                  {userroles.length > 0 &&
                    userroles.map((role) => (
                      <li
                        key={role}
                        onClick={(event) => handleRole(event, role)}
                      >
                        <a className="dropdown-item" href="#">
                          {role}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
