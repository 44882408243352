import React, { useState, useEffect, useContext } from "react";
import "./Banner.css";
import { TakeTourIcon, ArrowDown } from "../assets/icon/icon";
import white_amgen from "../assets/images/white-amgen.png";
import { useNavigate, useLocation } from "react-router-dom";

import userContext from "../../user-context";
import { fetchUserRoles } from "../Services/UserServices";
import { IoMdLogOut } from "react-icons/io";
// import VideoPopup from "../VideoModal/VideoModal";
const Banner = (props) => {
  // const { userDetails } = props;
  const [isVideoPopupOpen, toggleIsVideoPopupOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  const [isHomeOpened, toggleHomeOpened] = useState(true);
  const navigate = useNavigate();
  const {
    blockData,
    setBlockData,
    user,
    userroles,
    selectedUserRole,
    setSelectedUserRole,
    loading, setLoading
  } = useContext(userContext);

  let location = useLocation();
  // console.log(location);
  // const openPopup = () => {
  //   toggleIsVideoPopupOpen(true);

  // };
  const handleRoleChange = (event, role,user) => {
    
    event.preventDefault();
    if(selectedUserRole !== role?.role_name){
      setLoading(true);
      navigate({
        pathname: "/",
        state: "landing",
      });
      localStorage.setItem("role", role?.role_name);
      setSelectedUserRole(role.role_name);
      const roleChange = fetchUserRoles({
        user_id: user.user_id,
      role_id: role.role_id.toString(),
      partner_id: user.partner_id.toString(),
      });
      roleChange
        .then(function (result) {
          if (result) {
            setBlockData(result);
            setLoading(false);
          }
        })
        .catch(() => {
          // console.log("error");
          setLoading(false);
        });
     
    }
   
  };
  useEffect(() => {
    if (location.pathname === "/") {
      toggleHomeOpened(true);
    } else {
      toggleHomeOpened(false);
      setSelectedSection(location.pathname.split("/")[1]);
    }
  });

  const homeClick = () => {
    navigate({
      pathname: "/",
      state: "landing",
    });
  };
  const updateSection = (selectedSection, e) => {
    navigate({
      pathname: selectedSection,
      state: selectedSection,
    });

    setSelectedSection(selectedSection);
  };

  const handleLogout = (url) => {
    localStorage.setItem("role", 'Author');
    window.location.href = url;

  }

  return (
    <header>
      <div className="row amgen-blue py-1">
        <div
          className="col-4 row pr-0 text-left cursor_pointer bimHeader"
          onClick={homeClick}
        >
          <img src={white_amgen} alt="amgen_logo" className="logo-height" />
          <div className="verticalBorder  mr-2"></div>
          <div className=" text-left headerFont  text-white roboto-font">
            Digital Partner Collaboration
          </div>
        </div>
        {!isHomeOpened && (
          <div className="selectTabs col-5 px-0 text-center pt-3 row text-white" style={{fontSize:'13px'}}>
            <div
              className={`col-sm-auto pl-4 tabElement cursor_pointer 
                `}
              onClick={(e) => updateSection("/", e)}
            >
              HOME
            </div>
            {blockData &&
              blockData?.map((block) => (
                <div
                  key={block.key}
                  className={`col-sm-auto  tabElement cursor_pointer ${
                    selectedSection === block.key ? "selectedActiveTab" : ""
                  }`}
                  onClick={(e) => updateSection("/" + block.key, e)}
                >
                  {block.label.toUpperCase()}
                </div>
              ))}
          </div>
        )}
        <div
          
          className={`takeATour text-white  ${
            isHomeOpened ? " offset-5 col-3" : "  col"
          }`}
        >
          
          <div className="row pr-4 text-white " style={{ float: "right", clear: "both" }}>
            <div className="dropdown show ml-2  mt-1 ">
              <div
                className=" dropdown-toggle row hasMouseEvent ml-auto"
                href="#"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-white text-18  ">
                  {" "}
                  <span className="text-left font-weight-bold">
                    
                    {user &&
                      ("Hello ")+
                      user?.first_name  }
                  </span>
                  <div className=" text-11 text-right ">{user?.partner_name} - {selectedUserRole}</div>
                </div>
                <div className="userbox ml-2 text-white font-weight-bold">
                  {user && user?.first_name ? user?.first_name.charAt(0) : ""}
                  {user && user?.last_name ? user?.last_name.charAt(0)  : ""}
                 
                </div>
                <span className="px-2 pt-2">
                    <ArrowDown />
                  </span>
              </div>
              
              {/* <div className=" text-11 text-center mt-1">{user?.partner_name} - {selectedUserRole}</div> */}
              <div className="dropdown-menu text-13 dropdownStyle" aria-labelledby="dropdownMenuLink">
              <div className="  text-left userNameInsideDrop pl-4 p-1">
                  {/* <div className="usercircle mr-2 text-white">
                  {user && user?.first_name ? user?.first_name.charAt(0) : ""}
                  {user && user?.last_name ? user?.last_name.charAt(0)  : ""}
                  </div> */}
                 
                  {user && user?.first_name ? user?.first_name.toUpperCase() : ""}{" "}
                    {user && user?.last_name ? user?.last_name.toUpperCase() : ""}
                  
                </div>
                <div className="dropdown-divider"></div>
                {
                  userroles &&
                  userroles.length > 0 &&
                  userroles.map((role) => (
                    <div
                      key={role.role_id}
                      onClick={(event) => {
                        handleRoleChange(event, role,user);
                      }}
                    >
                      <a className="dropdown-item" href="#">
                        {role.role_name}
                      </a>
                    </div>
                  ))}
                   <div className="dropdown-divider"></div>
                   <div><a className="dropdown-item"  href="#" onClick={() => handleLogout('https://myamgen.amgen.com/')}><span className="mt-1"><IoMdLogOut /></span> <span className='logoutText'>Log Out</span></a></div>
                  {/* <div><a className="dropdown-item" href="https://myamgen.amgen.com/"><span className="mt-1"><IoMdLogOut /></span> <span className='logoutText'>Log Out</span></a></div> */}
              </div>
            </div>
           
          </div>
          </div>
       
        {/* <VideoPopup
        isOpen={isVideoPopupOpen}
        toggleIsVideoPopupOpen={(e) => toggleIsVideoPopupOpen(e)}
        videoWidth={1000}
        videoHeight={600}
      /> */}
      </div>
    </header>
  );
};

export default Banner;
