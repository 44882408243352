import React from "react";

export const TakeTourIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_716_1804)">
        <path
          d="M10.6875 7.776C10.5164 7.67723 10.3224 7.62525 10.1248 7.62528C9.92728 7.62531 9.73323 7.67736 9.56219 7.77618C9.39114 7.87501 9.24914 8.01713 9.15045 8.18825C9.05176 8.35937 8.99987 8.55346 9 8.751V15.2505C8.99987 15.448 9.05176 15.6421 9.15045 15.8133C9.24914 15.9844 9.39114 16.1265 9.56219 16.2253C9.73323 16.3241 9.92728 16.3762 10.1248 16.3762C10.3224 16.3763 10.5164 16.3243 10.6875 16.2255L16.875 12.6495C16.989 12.5837 17.0837 12.489 17.1495 12.375C17.2153 12.261 17.25 12.1316 17.25 12C17.25 11.8684 17.2153 11.739 17.1495 11.625C17.0837 11.511 16.989 11.4163 16.875 11.3505L10.6875 7.776ZM0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12ZM12 1.5C10.6211 1.5 9.25574 1.77159 7.98182 2.29926C6.70791 2.82694 5.55039 3.60036 4.57538 4.57538C3.60036 5.55039 2.82694 6.70791 2.29926 7.98182C1.77159 9.25574 1.5 10.6211 1.5 12C1.5 13.3789 1.77159 14.7443 2.29926 16.0182C2.82694 17.2921 3.60036 18.4496 4.57538 19.4246C5.55039 20.3996 6.70791 21.1731 7.98182 21.7007C9.25574 22.2284 10.6211 22.5 12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_716_1804">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const QuickAccess = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 23.625 19.247"
    >
      <g id="noun-share-4449957" transform="translate(-4.472 -5.52)">
        <g
          id="Group_8081"
          data-name="Group 8081"
          transform="translate(8.39 5.519)"
        >
          <path
            id="Path_22"
            data-name="Path 22"
            d="M251.829,319.813V316.4l6.922,6.345-6.922,6.345V325.67h-4.281a7.237,7.237,0,0,0-6.9,5.058v-3.239a7.676,7.676,0,0,1,7.676-7.676Z"
            transform="translate(-240.647 -316.396)"
            fill="#2262a5"
            fillRule="evenodd"
          />
        </g>
        <path
          id="Path_24"
          data-name="Path 24"
          d="M3.531,16.046v6.512H26.156V16.046"
          transform="translate(1.44 1.708)"
          fill="none"
          stroke="#2262a5"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export const Project = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 14.324 16.879"
    >
      <path
        id="Path_25"
        data-name="Path 25"
        d="M4.912,13.227a10.563,10.563,0,0,1-.267-2.368A9.725,9.725,0,0,1,10.3,1.818a9.725,9.725,0,0,1,5.66,9.041,10.564,10.564,0,0,1-.267,2.368l1.633,1.452a.4.4,0,0,1,.078.51L15.422,18.5a.4.4,0,0,1-.633.078l-1.823-1.823a.809.809,0,0,0-.572-.237H8.214a.809.809,0,0,0-.572.237L5.819,18.578a.4.4,0,0,1-.633-.078L3.2,15.189a.4.4,0,0,1,.078-.51ZM10.3,10.859A1.617,1.617,0,1,0,8.687,9.242,1.617,1.617,0,0,0,10.3,10.859Z"
        transform="translate(-3.143 -1.817)"
        fill="#134289"
      />
    </svg>
  );
};

export const File = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 24.229 22.552"
    >
      <path
        id="apk_install_FILL0_wght300_GRAD0_opsz24"
        d="M102.147-837.447a2.071,2.071,0,0,1-1.523-.623,2.071,2.071,0,0,1-.623-1.523v-18.261a2.071,2.071,0,0,1,.623-1.522,2.071,2.071,0,0,1,1.523-.623h9.427l6.231,6.231v5.513h-1.78v-4.622h-5.341v-5.341h-8.537a.349.349,0,0,0-.251.114.349.349,0,0,0-.114.251v18.261a.349.349,0,0,0,.114.251.349.349,0,0,0,.251.114h12.121v1.781Zm-.365-1.781v0Zm1.758-1.643a5.222,5.222,0,0,1,.8-2.414,5.155,5.155,0,0,1,1.83-1.74l-1.025-1.824q0-.018.107-.411a.47.47,0,0,1,.248-.048.216.216,0,0,1,.181.137l1.043,1.872a6.719,6.719,0,0,1,1.073-.331,5.182,5.182,0,0,1,1.1-.116,5.262,5.262,0,0,1,1.108.116,6.317,6.317,0,0,1,1.067.331l1.043-1.872q.011-.012.4-.107a.284.284,0,0,1,.161.191.309.309,0,0,1-.024.239l-1.013,1.824a5.107,5.107,0,0,1,1.824,1.74,5.258,5.258,0,0,1,.8,2.414Zm2.956-1.609a.5.5,0,0,0,.37-.161.524.524,0,0,0,.155-.375.524.524,0,0,0-.155-.375.5.5,0,0,0-.37-.161.515.515,0,0,0-.375.161.515.515,0,0,0-.161.375.515.515,0,0,0,.161.375A.515.515,0,0,0,106.5-842.48Zm4.828,0a.515.515,0,0,0,.375-.161.515.515,0,0,0,.161-.375.515.515,0,0,0-.161-.375.515.515,0,0,0-.375-.161.514.514,0,0,0-.375.161.515.515,0,0,0-.161.375.515.515,0,0,0,.161.375A.514.514,0,0,0,111.323-842.48Zm8.583,5.033-4.326-4.337,1.239-1.269,2.2,2.178v-5.348h1.78v5.348l2.2-2.166,1.239,1.258Z"
        transform="translate(-100.001 859.999)"
        fill="#2262a5"
      />
    </svg>
  );
};

export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.105"
      height="7.493"
      viewBox="0 0 13.105 7.493"
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M10.68,12.732l4.955-4.959a.933.933,0,0,1,1.323,0,.944.944,0,0,1,0,1.327l-5.615,5.619a.935.935,0,0,1-1.292.027L4.4,9.1A.937.937,0,0,1,5.721,7.777Z"
        transform="translate(-4.125 -7.498)"
        fill="white"
      />
    </svg>
  );
};

export const QuickAccessDisabled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 23.625 19.247"
    >
      <g id="noun-share-4449957" transform="translate(-4.472 -5.52)">
        <g
          id="Group_8081"
          data-name="Group 8081"
          transform="translate(8.39 5.519)"
        >
          <path
            id="Path_22"
            data-name="Path 22"
            d="M251.829,319.813V316.4l6.922,6.345-6.922,6.345V325.67h-4.281a7.237,7.237,0,0,0-6.9,5.058v-3.239a7.676,7.676,0,0,1,7.676-7.676Z"
            transform="translate(-240.647 -316.396)"
            fill="#8e8e8e"
            fillRule="evenodd"
          />
        </g>
        <path
          id="Path_24"
          data-name="Path 24"
          d="M3.531,16.046v6.512H26.156V16.046"
          transform="translate(1.44 1.708)"
          fill="none"
          stroke="#2262a5"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
