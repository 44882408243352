import React from "react";
import Header from "../Accounts/Header";
import Footer from "../Accounts/Footer";
import Accounttemplatestyle from "./Addtemplate.module.css";
import * as Icon from "react-bootstrap-icons";
//import Multiselect from 'multiselect-react-dropdown';
import FormErrors from "./FormErrors";
import Forms from "./Forms";
import * as XLSX from "xlsx";
import Openmodal from "./Openmodal";
import {
  getTemplatestatus,
  checkTemplateApproval,
} from "../Services/UserServices";
import * as Constants from "../../constants";

class Addtemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          cat: "Group 1",
          key: "Option 1",
        },
        {
          cat: "Group 1",
          key: "Option 2",
        },
        {
          cat: "Group 1",
          key: "Option 3",
        },
        {
          cat: "Group 2",
          key: "Option 4",
        },
        {
          cat: "Group 1",
          key: "Option 6",
        },
        {
          cat: "Group 2",
          key: "Option 45",
        },
      ],
      selectedValues: [
        // {
        //     cat: 'Group 1',
        //     key: 'Option 1'
        // },
        // {
        //     cat: 'Group 1',
        //     key: 'Option 2'
        // }
      ],
      headerjson: [],
      setSelectedFile: "",
      setErrorMsg: false,
      setIsFileSuccess: false,
      fileErrors: {
        extension: "",
        name: "",
        specialcharactercheck: "",
        servererrorMessage: "",
        taberror: "",
        approvalexisting: "",
      },
      showFileModal: false,
      tablearr: [],
      //tabledatabackend :[],
      staticACMHeader: [
        "CMO Lot #",
        "Unit Op Lot #",
        "Unit Op / Step",
        "Parameter",
        "Unit of Measure",
        "Result (numeric)",
        "Result (text string)",
        "CMO Deviation (if applicable)",
        "Comments",
      ],
      is_acm: false,
      existing_template: false,
      successMassage: "",
      fileversion: "",
      templateresponsedata: {},
      openModal: false,
      templateversionnofromxlsx: "",
      supdata: [],
      isInputChange: false,
    };
    this.baseState = this.state;
  }

  handleFileChange(event) {
    this.setState({ successMassage: "" });
    //console.log("j11111111111111111111");
    this.setState({ isInputChange: false });
    document.getElementsByClassName("editsuccesstext")[0].style.display =
      "none";
    let file = event.target.files[0];
    //let reader = new FileReader();
    //console.log(file);
    this.setState({ openModal: false });
    this.setState({ setSelectedFile: file }, () => {
      this.validateFile(file);
     // console.log(this.state);
    });
    //console.log(this.state)
  }

  validateFile(file) {
    //console.log(file)
    //var alltableheader=[];
    var fileExtension = ["xlsx"];
    var match_keyword = "DPC";
    let fileerrorslist = this.state.fileErrors;
    fileerrorslist.servererrorMessage = "";
    this.setState({ existing_template: false, showFileModal: false });
    //console.log(fileExtension.indexOf(file['name'].split('.').pop().toLowerCase()));
    if (file === undefined) {
      fileerrorslist.extension = "";
      fileerrorslist.name = "";
      fileerrorslist.taberror = "";
      fileerrorslist.approvalexisting = "";
      fileerrorslist.specialcharactercheck = "";
      fileerrorslist.servererrorMessage = "";
      this.setState({ setErrorMsg: false });
      this.setState({ setIsFileSuccess: false });
      this.setState({ headerjson: [] });
      this.setState({ tablearr: [] });
      this.setState({ existing_template: false, showFileModal: false });
      return false;
    }
    if (
      fileExtension.indexOf(
        file["name"].split(".").pop().toLowerCase(),
        fileExtension
      ) === -1
    ) {
      fileerrorslist.extension =
        "File extension is not valid , please use xlsx format";
      fileerrorslist.name = "";
      fileerrorslist.taberror = "";
      fileerrorslist.approvalexisting = "";
      fileerrorslist.specialcharactercheck = "";
      fileerrorslist.servererrorMessage = "";
      //this.state.setErrorMsg=true;
      this.setState({ setErrorMsg: true });
      this.setState({ setIsFileSuccess: false });
      this.setState({ headerjson: [] });
      this.setState({ tablearr: [] });
    }
    // else
    // {
    //     fileerrorslist.extension = '';
    //     this.state.setErrorMsg=false;
    //     this.setState({setErrorMsg:false})
    // }
    else if (!file["name"].startsWith(match_keyword)) {
      fileerrorslist.name = "File name is not valid , please start with DPC";
      fileerrorslist.extension = "";
      fileerrorslist.specialcharactercheck = "";
      fileerrorslist.servererrorMessage = "";
      fileerrorslist.taberror = "";
      fileerrorslist.approvalexisting = "";
      this.setState({ setErrorMsg: true });
      this.setState({ setIsFileSuccess: false });
      this.setState({ headerjson: [] });
      this.setState({ tablearr: [] });
      //this.state.setErrorMsg=true;
    }
    // else if(file['name'].indexOf(' ') >= 0)
    // {
    //     fileerrorslist.name = 'File name contains SPACE, please remove space for uploading';
    //     fileerrorslist.extension = '';
    //     fileerrorslist.specialcharactercheck = '';
    //     fileerrorslist.servererrorMessage = '';
    //     fileerrorslist.taberror = '';
    //     fileerrorslist.approvalexisting = '';
    //     this.setState({setErrorMsg:true})
    //     this.setState({setIsFileSuccess:false})
    //     this.setState({headerjson:[]})
    //     this.setState({tablearr:[]})

    // }
    //console.log(/([a-z0-9A-Z])#-_![/]/.test('AMGEN#-_!/')
    else if (/^[\w_.]*$/.test(file["name"]) === false) {
      fileerrorslist.name =
        "Please remove any blank spaces and/or any special characters from the file name";
      fileerrorslist.extension = "";
      fileerrorslist.specialcharactercheck = "";
      fileerrorslist.servererrorMessage = "";
      fileerrorslist.taberror = "";
      fileerrorslist.approvalexisting = "";
      this.setState({ setErrorMsg: true });
      this.setState({ setIsFileSuccess: false });
      this.setState({ headerjson: [] });
      this.setState({ tablearr: [] });
    } else {
      fileerrorslist.extension = "";
      fileerrorslist.name = "";
      fileerrorslist.servererrorMessage = "";
      fileerrorslist.taberror = "";
      fileerrorslist.approvalexisting = "";
      //fileerrorslist.specialcharactercheck = '';
      this.setState({ headerjson: [] });
      this.setState({ tablearr: [] });
      this.setState({ is_acm: false });
      //this.setState({fileErrors:fileerrorslist})
      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        /* Sheetname validation */

        let tabcheck = this.checkTabXLSX(wb.SheetNames);
        //console.log(tabcheck);
        if (tabcheck !== false) {
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          //console.log(data[0]);
          let checkacm = this.fileIsACM(data[0]);
          //console.log(checkacm);
          //alltableheader.push(data[0])
          let vl = this.fileSpecialCharacterCheck(data[0]);
         // console.log(vl);
          this.createTabledataJson(data[0], wsname, checkacm);

          /* Get second worksheet */
          //console.log(this.fileSpecialCharacterCheck(data))
          let seconddataarr = [];
          if (
            wb.SheetNames.length > 0 &&
            this.fileSpecialCharacterCheck(data[0]) !== false
          ) {
            const secondname = wb.SheetNames[1];
            const sec = wb.Sheets[secondname];
            /* Convert array of arrays */
            const seconddata = XLSX.utils.sheet_to_json(sec, { header: 1 });
            /* Update state */
            //console.log(seconddata);
            /*Supplementary data check */
            //console.log(this.checkSupplementaryData(seconddata))
            let supval = this.checkSupplementaryData(seconddata);
            //console.log(supval);
            this.setState({ supdata: seconddata });
            if (supval !== false) {
              for (let i = 0; i < seconddata.length; i++) {
                //console.log(seconddata[i][0])
                seconddataarr.push(seconddata[i][0]);
              }
              //console.log(seconddataarr)
              if (this.fileSpecialCharacterCheck(data[0]) !== false) {
                let vl = this.fileSpecialCharacterCheck(seconddataarr);
                //console.log(vl);
              }
              //console.log(checkacm)

              //this.createTabledataJson(seconddataarr,secondname)
              if (this.fileSpecialCharacterCheck(seconddataarr) !== false) {
                seconddataarr.shift();
                // console.log('timeeeeeeeeeeeeeeeeeee')
                // var index = seconddataarr.indexOf('Time Zone');
                // console.log(index)
                // if (index !== -1) {
                //     seconddataarr.splice(index, 1);
                // }
                //console.log(seconddataarr);
                this.createTabledataJson(seconddataarr, secondname, checkacm);
              }
            }
          }
        }

        //this.setState({headerjson : data[0]})
      };
      reader.readAsBinaryString(file);
    }
    // else{
    //     fileerrorslist.name = '';
    //     this.setState({setErrorMsg:false})
    //     this.state.setErrorMsg=false;
    // }

    this.setState({ fileErrors: fileerrorslist });
  }

  fileSpecialCharacterCheck(data) {
    //console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkiiiiiiiiiiiiiiiiii')
    let fileerrorslist = this.state.fileErrors;
    //console.log(data)
    //let existspecialchar = false;
    for (let i = 0; i < data.length; i++) {
      //console.log(data[i])
        if (data[i] === undefined) {
          continue;
        }
      // for (let j = 0; j < data[i].length; j++) {
      //   console.log(data[i][j])
      //   if (data[i][j] === undefined) {
      //     continue;
      //   }

        /*----Alpha numeric character*/
        //console.log(/^[\w\s_]*$/.test('CMO12 Lot _'))
        //console.log(/([a-z0-9A-Z])#-_![/]/.test('AMGEN#-_!/'))
        //if(/^[\d\w\s@#:!,$%/\^&*)(+=._-]*$/.test(data[i][j]))
        //if(/^[{}\d\w\s@#:!,$%/\^&*)(+=._-]*$/.test(data[i][j]))

        //[><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*
        if(/^[><?@+'`^%&*[\]{}.!#\\"$';,:;=/(),\-\w\s\d+°µ≥·–]*$/.test(data[i]))
        {
          //if(/^[\w\s_]*$/.test(data[i][j]))
          //console.log(data[i][j])
          fileerrorslist.extension = "";
          fileerrorslist.name = "";
          fileerrorslist.taberror = "";
          fileerrorslist.approvalexisting = "";
          fileerrorslist.specialcharactercheck = "";
          this.setState({ setErrorMsg: false });
          this.setState({ setIsFileSuccess: true });
          //return true;
        } else {
          // console.log(data[i][j])
          // alert('please upload the file without special characters and SPACES');
          fileerrorslist.specialcharactercheck =
            "File contains special characters " + data[i];
          this.setState({ setErrorMsg: true });
          this.setState({ setIsFileSuccess: false });
          this.setState({ headerjson: [] });
          this.setState({ tablearr: [] });
          this.setState({ fileErrors: fileerrorslist });
          return false;
        }
        // if(data[i][j].search(/!/)>0)
        // {
        //     //console.log(data[i][j])
        //  //alert('please upload the file without special characters and SPACES');

        //     fileerrorslist.specialcharactercheck = 'File contains special characters';
        //     this.setState({setErrorMsg:true})
        //     this.setState({setIsFileSuccess:false})
        //     this.setState({headerjson:[]})
        //     this.setState({tablearr:[]})
        //     return false;
        // }
        // else{
        //     fileerrorslist.extension = '';
        //     fileerrorslist.name = '';
        //     fileerrorslist.specialcharactercheck = '';
        //     this.setState({setErrorMsg:false})
        //     this.setState({setIsFileSuccess:true})

        // }
      }
    //}
  }

  checkTabXLSX(SheetNames) {
    let fileerrorslist = this.state.fileErrors;
    if (SheetNames.length > 0) {
      if (SheetNames[0] === undefined) {
        fileerrorslist.taberror = "Data tab does not exists in this template ";
        fileerrorslist.extension = "";
        fileerrorslist.specialcharactercheck = "";
        this.setState({ setErrorMsg: true });
        this.setState({ setIsFileSuccess: false });
        this.setState({ headerjson: [] });
        this.setState({ tablearr: [] });
        return false;
      } else if (0 in SheetNames && SheetNames[0] !== "Data") {
        fileerrorslist.taberror =
          'Incorrect sheet name in the uploaded file. Please rename the "' +
          SheetNames[0] +
          '" sheet to "Data"';
        //fileerrorslist.taberror = "Please rename '"+SheetNames[0]+ "' tab as  'Data'";
        fileerrorslist.extension = "";
        fileerrorslist.specialcharactercheck = "";
        this.setState({ setErrorMsg: true });
        this.setState({ setIsFileSuccess: false });
        this.setState({ headerjson: [] });
        this.setState({ tablearr: [] });
        return false;
      } else if (SheetNames[1] === undefined) {
        fileerrorslist.taberror =
          "Supplementary Data tab not exists in template";
        fileerrorslist.extension = "";
        fileerrorslist.specialcharactercheck = "";
        this.setState({ setErrorMsg: true });
        this.setState({ setIsFileSuccess: false });
        this.setState({ headerjson: [] });
        this.setState({ tablearr: [] });
        return false;
      } else if (1 in SheetNames && SheetNames[1] !== "Supplementary Data") {
        //fileerrorslist.taberror = 'Please rename '+SheetNames[1]+ 'tab with Supplementary Data';
        fileerrorslist.taberror =
          'Incorrect sheet name in the uploaded file. Please rename the "' +
          SheetNames[1] +
          '" tab with Supplementary Data"';
        fileerrorslist.extension = "";
        fileerrorslist.specialcharactercheck = "";
        this.setState({ setErrorMsg: true });
        this.setState({ setIsFileSuccess: false });
        this.setState({ headerjson: [] });
        this.setState({ tablearr: [] });
        return false;
      } else {
        return true;
      }
    }
  }

  createTabledataJson(data, tab, checkacm) {
    //var tablearr = []
    // console.log(data)
    // console.log('datannnnnnnnnnnnnnnnnnnnn')
    if (checkacm === true) {
      this.state.tablearr = Constants.ISACMDQM;
    } else {
      for (let i = 0; i < data.length; i++) {
        // if(data[i] === 'Template Version Number')
        // {
        //     continue
        // }
        if (data[i] === "" || data[i] === undefined) {
          continue;
        }

        this.state.tablearr.push({
          tabname: tab,
          colname: data[i],
          datatype: "String",
          nullcheck: "false",
          datatypecheck: "false",
          visualisation_filter: "false",
          xaxis: "false",
          yaxis: "false",
          colorbyoption: "false",
          shapebyoption: "false",
          display_order: i + 1,
        });
      }
    }

    //console.log(this.state.tablearr);
    this.setState({ setErrorMsg: false });
    this.setState({ setIsFileSuccess: true });

    this.setState({ headerjson: this.state.tablearr });
  }

  fileIsACM(headerdata) {
    //console.log(headerdata)
    let acmval = false;
    for (let i = 0; i < headerdata[i].length; ++i) {
      if (headerdata[i].trim() === this.state.staticACMHeader[i].trim()) {
        //console.log(headerdata[i]);
        //console.log(this.state.staticACMHeader[i]);
        acmval = true;
        continue;
      } else {
        // console.log(headerdata[i]);
        // console.log(this.state.staticACMHeader[i]);
        acmval = false;
        break;
      }
    }
    if (acmval === true) {
      this.setState({ is_acm: true });
    }

    return acmval;
  }

  // componentWillUpdate() {

  //     this.state.setSelectedFile = ''
  // }

  componentDidUpdate(prevState, prevProps) {
    //console.log("componentDidUpdate()");

    let fileerrorslist = this.state.fileErrors;
    // console.log(fileerrorslist["specialcharactercheck"]);
    // console.log("ooooooooooooooooooooooooooooooooooo");
    //var templateresponse = {};

    if (
      this.state.setSelectedFile === undefined ||
      this.state.setSelectedFile === ""
    ) {
      return false;
    } else {
      //  console.log(this.state.setSelectedFile)
      //  console.log(this.props.setSelectedFile)
      // console.log(prevState.setSelectedFile)
      //&& fileerrorslist['extension']===''
      //fileerrorslist['name']==='' && fileerrorslist['specialcharactercheck']==='' && fileerrorslist['servererrorMessage']===''
      //console.log("o1111111111111");
      //document.getElementsByClassName("templateupload")[0].style.display = "block";
      if (prevProps.setSelectedFile !== this.state.setSelectedFile) {
      //  console.log("o22222222222222222222222222");
        let file = this.state.setSelectedFile["name"];
        //this.ApiFect(file);
        // if(this.state.supdata.length>0)
        // {
        //     this.ApiFect(file);
        // }
        let fileerrorslist = this.state.fileErrors;
        var filename = file.split(".xlsx")[0];

        checkTemplateApproval(filename)
          .then((datas) => {
           // console.log(datas);
            if (datas === true) {
             // console.log("approvalllllllllll");

              fileerrorslist.approvalexisting =
                "Request cannot be submitted as another request for the same template is already under approval";
              this.setState({ fileErrors: fileerrorslist });
              this.setState({ setErrorMsg: true });
              this.setState({ setIsFileSuccess: false });
              this.setState({ headerjson: [] });
              this.setState({ tablearr: [] });
            } else {
              console.log("approvalllllllllll nooooo");
              this.ApiFect(file);
            }
          })
          .catch((error) => {
            fileerrorslist.servererrorMessage = error.toString();
            this.setState({ fileErrors: fileerrorslist });
            //console.error("There was an error!", error);
          });
      }

      // console.log(this.state)
    }

    // console.log(templateresponse)
  }
  getSnapshotBeforeUpdate(prevProps, prevState) {
    // console.log(prevProps)
    //  console.log(prevState)
    if (this.props.existing_template !== prevProps.existing_template) {
      this.setState({ existing_template: false, showFileModal: false });
    }
    //this.setState({existing_template:true,showFileModal:true})
    return null;
  }

  checkSupplementaryData(supdata) {
    let fileerrorslist = this.state.fileErrors;
    console.log(supdata);
    console.log(this.state.existing_template);
    if (0 in supdata && supdata[0][0] !== "Template Version Number") {
      fileerrorslist.taberror =
        "Template Version Number does not exists in Supplementary Data";
      fileerrorslist.extension = "";
      fileerrorslist.specialcharactercheck = "";
      this.setState({ setErrorMsg: true });
      this.setState({ setIsFileSuccess: false });
      this.setState({ headerjson: [] });
      this.setState({ tablearr: [] });
      this.setState({ fileErrors: fileerrorslist });
      return false;
    } else {
      return true;
    }
  }

  ApiFect(file) {
    let fileerrorslist = this.state.fileErrors;
    var filename = file.split(".xlsx")[0];
    this.setState({ isInputChange: false });
    console.log("2222222222222222222222222");
    getTemplatestatus(filename)
      .then((data) => {
        //console.log(data);
        console.log("chahhhhhhhhhhhh");
        //console.log(this.state.supdata);
        var templatestatusrespone = data[0];
        //console.log(templatestatusrespone["templateVersionNumber"]);
        if (
          templatestatusrespone["templateVersionNumber"] !== null ||
          templatestatusrespone["templateName"] !== null
        ) {
          //console.log(typeof templatestatusrespone["templateVersionNumber"]);
          //document.getElementsByClassName("templateupload")[0].style.display = "none";
          let edittemplateversionno =
            Number(templatestatusrespone["templateVersionNumber"]) + 1;
          //this.setState({openModal:true,existing_template:true,showFileModal:true,fileversion:edittemplateversionno})

          //Actual
          if (this.state.supdata.length > 0) {
            console.log("edittttttversion");
            document.getElementsByClassName("templateupload")[0].style.display =
              "none";
            if (
              Number(edittemplateversionno) !== Number(this.state.supdata[0][1])
            ) {
              console.log("88888888888888888888888");
              fileerrorslist.name =
                "Please upload template with Version Number " +
                edittemplateversionno +
                ", old template version no is " +
                templatestatusrespone["templateVersionNumber"];
              fileerrorslist.extension = "";
              fileerrorslist.specialcharactercheck = "";
              this.setState({ setErrorMsg: true });
              this.setState({ setIsFileSuccess: false });
              this.setState({ headerjson: [] });
              this.setState({ tablearr: [] });
              this.setState({ fileErrors: fileerrorslist });
              document.getElementsByClassName(
                "templateupload"
              )[0].style.display = "block";
              // const file =document.querySelector('.file');
              // file.value = '';
            } else {
              this.setState({
                openModal: true,
                existing_template: true,
                showFileModal: true,
                fileversion: edittemplateversionno,
              });
            }
          }
        } else {
          if (
            this.state.supdata[0][1] === "1" ||
            this.state.supdata[0][1] === 1
          ) {
            console.log("checkkkkkkkkkkk success");
            this.setState({
              existing_template: false,
              showFileModal: false,
              fileversion: "",
            });
            document.getElementsByClassName("hiddentext")[0].style.display =
              "none";
            document.getElementsByClassName("hiddentextnote")[0].style.display =
              "none";
            return true;
          } else {
            console.log("mmmmmmmmmmmmmmmmm");
            fileerrorslist.name =
              "Template Version Number should be 1 for new template";
            fileerrorslist.extension = "";
            fileerrorslist.specialcharactercheck = "";
            this.setState({ setErrorMsg: true });
            this.setState({ setIsFileSuccess: false });
            this.setState({ headerjson: [] });
            this.setState({ tablearr: [] });
            this.setState({ fileErrors: fileerrorslist });
            return false;
          }
          //this.setState({existing_template:false,showFileModal:false,fileversion:''})
        }
      })
      .catch((error) => {
        //console.log(error);
        let fileerrorslist = this.state.formErrors;
        fileerrorslist.servererrorMessage = error.toString();
        this.setState({ formErrors: fileerrorslist });
        this.setState({ setErrorMsg: true });
        this.setState({ setIsFileSuccess: false });
        //this.setState({ errorMessage: error.toString() });
       // console.error("There was an error!", error);
      });
  }

  resetCallback = (childData) => {
    console.log(childData);
    //document.getElementsByClassName("templateupload")[0].style.display = "block";
    // Update the name in the component's state
    if (childData === "reset") {
      this.setState(this.baseState);
    } else {
      console.log(childData);
      this.setState(this.baseState);
      if (childData) {
        this.setState({
          successMassage:
            "Template is now under approval process and request number is " +
            childData,
        });
      }
    }
    const file = document.querySelector(".file");
    file.value = "";
    //this.props.history.push('/')
  };

  setTemplateName(name) {
    if (name !== undefined) {
      return name.split(".xlsx")[0];
    }
  }
  parentAddTemplateCallbackMainRole = (role) => {
    console.log(role);
    // UserProfile.setRole(role);
    //setTabIndex(index)
  };

  render() {
    console.log(this.state);
    // console.log(this.state.setIsFileSuccess)
    //console.log(this.state.setErrorMsg)
    let fileuploaderrordiv;
    let successfileupload;
    let showFileModal;
    if (this.state.setErrorMsg) {
      fileuploaderrordiv = <FormErrors errors={this.state.fileErrors} />;
    }

    if (
      this.state.setIsFileSuccess &&
      this.state.existing_template === false &&
      this.state.setSelectedFile
    ) {
      successfileupload = (
        <Forms
          isInputChange={this.state.isInputChange}
          parentCallback={this.resetCallback}
          fulltemplate={this.state.setSelectedFile["name"]}
          formtemplate={this.state.setSelectedFile}
          templatename={this.setTemplateName(
            this.state.setSelectedFile["name"]
          )}
          xlsxheader={this.state.headerjson}
          setErrorMsg={this.state.setErrorMsg}
          filesuccess={this.state.setIsFileSuccess}
          isacm={this.state.is_acm}
          fileversion={this.state.fileversion}
          isNewTemplate="true"
          templateresponsedata={this.state.templateresponsedata}
        />
      );
    }

    if (
      this.state.existing_template &&
      this.state.showFileModal &&
      this.state.setIsFileSuccess
    ) {
      showFileModal = (
        <Openmodal
          isInputChange={this.state.isInputChange}
          fileversion={this.state.fileversion}
          isNewTemplate="false"
          isacm={this.state.is_acm}
          showFileModal={this.state.showFileModal}
          filename={this.setTemplateName(this.state.setSelectedFile["name"])}
          formtemplate={this.state.setSelectedFile}
          fulltemplate={this.state.setSelectedFile["name"]}
          xlsxheader={this.state.headerjson}
        />
      );
    }

    return (
      <div>
        {/* <Header parentTabCallbackRole={this.parentAddTemplateCallbackMainRole}/> */}
        {showFileModal}
        <div
          className={`container-fluid ${Accounttemplatestyle.addtemplatebackgroundbody} templateupload body-content mobiletempadd`}
          style={{ paddingTop: "3%" }}
        >
          {/* <div className="row p-0 d-flex justify-content-center divcenterpage"> */}
          <div className=" p-0">
            <div className="row p-0  justify-content-center divcenterpage ">
              <div className="input-group mb-3 heading hiddentext">
                <span
                  style={{
                    width: "30%",
                    margin: "auto",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Add/Edit Template
                </span>
              </div>
              <div className="col-sm-3  text-start mobileview">
                <label
                  htmlFor="formFile"
                  className="form-label pe-4 globaltextformat"
                >
                  <span className={Accounttemplatestyle.mandetoryfield}>*</span>
                  File
                </label>
              </div>
              <div className="input-group mb-3">
                <label
                  htmlFor="formFile"
                  className="col-form-label col-sm-3 text-end pe-4 globaltextformat desktopview"
                >
                  <span className={Accounttemplatestyle.mandetoryfield}>*</span>
                  File
                </label>
                <span className="input-group-text" id="basic-addon1">
                  <Icon.FileArrowUpFill color="white" />
                </span>
                <input
                  className="form-control p-0"
                  type="file"
                  id="formFile"
                  onChange={(event) => this.handleFileChange(event)}
                />
              </div>
            </div>

            <div
              className={`  globaltextformat hiddentextnote`}
              style={{ textAlign: "center" }}
            >
              <label
                htmlFor="exampleFormControlInput1"
                className="col-sm-3 text-end pe-4 "
              ></label>
              <p className="heading">
                <i>
                  Templates with data that are later processed downstream by
                  Dynamic Mapping Engine (DME) should not use these special
                  characters: ≠, ≥ , and ≤
                </i>
              </p>
            </div>

            {successfileupload}

            <div
              className={`  globaltextformat ${Accounttemplatestyle.mandetoryfield}`}
              style={{ textAlign: "center" }}
            >
              <label
                htmlFor="exampleFormControlInput1"
                className="col-sm-3 text-end pe-4 "
              ></label>

              {/* <FormErrors fileErrors={this.state.fileErrors} /> */}
              {fileuploaderrordiv}
            </div>
            {this.state.successMassage && (
              <div
                className="text-success mb-3 globaltextformat fs-5"
                style={{ width: "79%", textAlign: "center" }}
              >
                <label
                  htmlFor="exampleFormControlInput1"
                  className="col-sm-3 text-end pe-4 "
                ></label>
                <span>{this.state.successMassage}</span>
              </div>
            )}
            <div
              className="text-success mb-3 globaltextformat fs-5 editsuccesstext"
              style={{ width: "79%", textAlign: "center", display: "none" }}
            >
              <label
                htmlFor="exampleFormControlInput1"
                className="col-sm-3 text-end pe-4 "
              ></label>
              <span className="textedi">
                Template is under approval process
              </span>
            </div>

            {/* <div className="mb-3">
                                <div className="form-group text-end">
                               
                                <button type="submit" className={`btn btn-primary text-wrap fw-normal ${Accountstyle.submit_btn}`}>
                                <Icon.CheckLg /> Submit
                                </button>
                                
                                </div>
                        </div> */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Addtemplate;
