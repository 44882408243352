export const REACT_APP_REDIRECT_URL_ENV = {
    Local: "https://dpc-admin-dev.auth.us-west-2.amazoncognito.com/login?client_id=4rjnkh3nm68109cd52d421d4c&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.dpcadmin-dev.amgen.com%2F",
    Dev: "https://dpc-admin-dev.auth.us-west-2.amazoncognito.com/login?client_id=4rjnkh3nm68109cd52d421d4c&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.dpcadmin-dev.amgen.com%2F",
    Ntest:"https://dpc-admin-ntest.auth.us-west-2.amazoncognito.com/login?client_id=39279hplg5sjv4bt64oele4hmq&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.dpcadmin-ntest.amgen.com%2F",
    Test: "https://dpc-admin-test.auth.us-west-2.amazoncognito.com/login?client_id=1chgdhh2b61f2fjt0ej140j9m&response_type=token&scope=openid&redirect_uri=https%3A%2F%2Fwww.dpcadmin-test.amgen.com%2F",
    Prod: "https://dpc-admin-prod.auth.us-west-2.amazoncognito.com/login?client_id=5u12qc20a9qds5jm7npdb3g6h9&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.dpcadmin.amgen.com%2F"
};

export const REACT_APP_URL_ENV = { 
    Local: "https://www.amgendpc-dev.amgen.com/",
    Dev: "https://www.amgendpc-dev.amgen.com/",
    Ntest: "https://www.amgendpc-ntest.amgen.com/",
    Test: "https://www.amgendpc-test.amgen.com/",
    Prod:"https://www.amgendpc.amgen.com/"
}